/*!
 * mLeasing, cookies component :: 24/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {Text} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import Container from '../components/container.js';
import {generateLink} from '../utils/generate-link.js';

const version = 'v: 1.0.0 :: r. 15/04/2021 @ fs';
const CookieBar = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	padding-top: ${px(Spacing.SPACE_24)};
	padding-bottom: ${px(Spacing.SPACE_24)};
	background-color: ${palette.solidWhite};
`;
const Inner = styled.div`
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
		flex-direction: column;
	}
`;
const ButtonWrapper = styled.div`
	max-width: ${px(120)};
	margin-left: ${px(Spacing.SPACE_24)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-left: ${px(Spacing.SPACE_0)};
		max-width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: ${px(Spacing.SPACE_24)};
	}
`;

class Cookies extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.setCookie = this.setCookie.bind(this);
		this.state = {
			open: false
		};
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('cookies');
		}

		// read cookie
		let cookies = document.cookie;
		let cookie = '';

		if (cookies) {
			cookies = cookies.split('; ');
			cookie = cookies.filter(c => c.match(/mleasing/));

			if (!cookie[0]) {
				this.setState({
					open: true
				});
			}

		} else {
			this.setState({
				open: true
			});
		}

	}

	setCookie() {
		document.cookie = 'mleasing=true';
		this.setState({
			open: false
		});
	}

	render() {
		return (
			<>
				{this.state.open ? (<CookieBar>
					<Container>
						<Inner>
							<Text as="small">Zależy nam, aby świadczyć usługi na najwyższym poziomie. Dlatego serwis informacyjny wykorzystuje pliki cookies, które zapisują się w pamięci przeglądarki. Pliki cookies (po polsku ciasteczka) to małe pliki tekstowe, które automatycznie tworzy przeglądarka internetowa, gdy odwiedzasz strony WWW. Informacje w danym pliku cookie może odczytać wyłącznie ta strona, z której ten plik pochodzi. Co to oznacza dla Ciebie? Strona internetowa nie może mieć dostępu do innych plików, które znajdują się na Twoim komputerze. Możesz skonfigurować tak swoją przeglądarkę, aby blokowała ona pliki cookies lub usuwała je. Więcej na ten temat przeczytasz w naszym materiale {generateLink('/polityka-prywatnosci/', 'POLITYKA COOKIES', '', '', false)}. Piszemy tam także o tym, dlaczego używamy pliki cookies. Pamiętaj, że jeśli zablokujesz pliki cookies, nie będziesz mógł, niestety, korzystać (całkowicie lub z części funkcji) z naszego serwisu internetowego. Jeśli będziesz dalej korzystać z naszego serwisu i nie zmienisz ustawień plików cookies, uznamy, że zapoznałeś się z tą wiadomością i akceptujesz nasze pliki cookies.</Text>
							<ButtonWrapper>
								<Button buttonType="secondary" onClick={this.setCookie}>OK</Button>
							</ButtonWrapper>
						</Inner>
					</Container>
				</CookieBar>) : ''}
			</>
		);
	}

}

export default Cookies;
