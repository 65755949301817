/*!
 * mLeasing, seo component :: 24/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import {Helmet} from 'react-helmet';
import {environment} from '../../environment.js';

const version = 'v: 1.0.0 :: r. 29/04/2021 @ fs';
const image = environment.siteUrl + '/images/ml_social_share.png';

class Seo extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('seo');
		}
	}

	render() {
		return (
			<Helmet
				title={this.props.title}
				meta={[
					{
						name: 'description',
						content: this.props.description
					},
				]}
			>
				<script type="application/ld+json">{
					`{
						"@context": "https://schema.org",
						"@type": "Organization",
						"title": ${this.props.title},
						"description": ${this.props.description},
						"url": ${this.props.url}
					}`
				}</script>
			</Helmet>
		);
	}

}

export default Seo;
