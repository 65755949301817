/*!
 * mLeasing, tile-set portlet :: 04/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Carousel} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import Container from '../../components/container.js';
import {mapLinks} from '../../utils/map-links.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 13/05/2021 @ fs';
const TileWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
`;
const CustomTile = styled.div`
	position: relative;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: calc(${props => props.flexBasis + '%'} - 24px);
	margin-right: ${px(Spacing.SPACE_24)};
	margin-bottom: ${props => {

		if (props.flexWrap) {

			if (props.lastRowTile) {
				return Spacing.SPACE_0;
			} else {
				return px(Spacing.SPACE_24);
			}

		} else {
			return Spacing.SPACE_0;
		}
	}};
	background-color: ${props => props.backgroundColor === 'dark' ? palette.solidWhite : palette.wildSandGray};
	box-shadow: ${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08);

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: ${px(Spacing.SPACE_0)};
	}
`;
const Inner = styled.div`
	padding: ${props => props.isIcon ? px(Spacing.SPACE_4) : px(Spacing.SPACE_24)} ${px(Spacing.SPACE_16)} ${px(Spacing.SPACE_24)} ${px(Spacing.SPACE_16)};
`;
const ButtonWrapper = styled(Box)`
	margin-top: ${px(Spacing.SPACE_32)};
	text-align: center;
`;
const InnerMobile = styled(TileWrapper)`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
		padding-left: ${props => props.mobilePaddingLeft || 0};
		padding-right: ${props => props.mobilePaddingRight || 0};
	}
`;
const InnerDesktop = styled(TileWrapper)`
	flex-wrap: ${props => props.flexWrap ? 'wrap' : 'nowrap'};
	max-width: ${props => props.flexWrap ? px(1114) : '100%'};
	margin: 0 auto;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const TextBox = styled.div`
	max-width: 755px;
	margin: 0 auto;
	padding-bottom: ${px(Spacing.SPACE_24)};
	text-align: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const BottomTextBox = styled.div`
	max-width: 755px;
	margin: 0 auto;
	padding-top: ${px(Spacing.SPACE_24)};
	text-align: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const BoxTable = styled.table`
	width: 100%;
	padding-top: ${px(Spacing.SPACE_8)};
`;
const BoxTableHead = styled.thead`
`;
const BoxTableBody = styled.tbody`
`;
const BoxTableTH = styled.th`
	text-align: left;
	font-size: 12px;
	font-weight: normal;
	font-family: Montserrat;
	line-height: 20px;
`;
const BoxTableTD = styled.td`
	font-size: ${props => props.fontSize}px;
	color: ${props => props.color};
	line-height: 16px;
`;
const BoxTableRow = styled.tr`
`;
const tileLinkStyles = `
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;
const bottomLinkStyles = `
	position: absolute;
	bottom: 16px;
`;
const buildState = (props, state) => {

	let title = '';
	let text = '';
	let subTitle = '';
	let background = '';
	let linkName = '';
	let link = '';
	let items = [];
	let mAutoTiles = '';
	let bottomText = '';
	let rows = 1;

	if (props.offline) {
		title = props.data.title;
		text = props.data.text;
		bottomText = props.data.bottomText;
		subTitle = props.data.subTitle;
		background = props.data.background;
		linkName = props.data.linkName;
		link = props.data.link;
		mAutoTiles = props.data.mAutoTiles;
		rows = props.data.rows ? props.data.rows : 1;

		// fill items only for cms content based portlet
		if (mAutoTiles !== 'afterlease' && mAutoTiles !== 'promoafterlease' && mAutoTiles !== 'volvoAfterLease') {
			items = props.data.items ? props.data.items.map(item => {
				return {
					title: item.title,
					text: item.text,
					image: item.image,
					altText: item.altText || '',
					isIcon: item.isIcon && item.isIcon === 'true',
					linkName: item.linkName,
					anchor: item.anchor,
					link: item.link ? mapLinks([{
						url: item.link,
						navText: item.linkName,
					}], props.offline)[0].url : ''
				};
			}) : [];
		}

	} else {

		try {

			const obj = props.data['Tile-setData']['Tile-set'];

			title = obj.Title;

			let textLinks = [];

			if (obj.Text && obj.Text.links) {

				if (Array.isArray(obj.Text.links.link)) {

					textLinks = obj.Text.links.link.map(link => {
						return {
							name: link.name,
							target: link.target || link.anchor
						};
					});

				} else if (obj.Text.links !== '') {

					textLinks = [{
						name: obj.Text.links.link.name,
						target: obj.Text.links.link.target || obj.Text.links.link.anchor
					}];

				}

			}

			text = obj.Text ? replaceCmsContentLinks(obj.Text.content, textLinks) : '';

			let bottomTextLinks = [];

			if (obj.BottomText && obj.BottomText.links) {

				if (Array.isArray(obj.BottomText.links.link)) {

					bottomTextLinks = obj.BottomText.links.link.map(link => {
						return {
							name: link.name,
							target: link.target || link.anchor
						};
					});

				} else if (obj.BottomText.links !== '') {

					bottomTextLinks = [{
						name: obj.BottomText.links.link.name,
						target: obj.BottomText.links.link.target || obj.BottomText.links.link.anchor
					}];

				}

			}

			bottomText = obj.BottomText ? replaceCmsContentLinks(obj.BottomText.content, bottomTextLinks) : '';
			subTitle = obj.SubTitle;
			background = obj.Background;
			linkName = obj.LinkName;
			link = obj.Link ? obj.Link.link.target.replace(environment.cmsExportPath, '') : '';
			mAutoTiles = obj.MAutoTiles;
			rows = props.data.Rows ? props.data.Rows : 1;

			// fill items only for cms content based portlet
			if (mAutoTiles !== 'afterlease' && mAutoTiles !== 'promoafterlease' && mAutoTiles !== 'volvoAfterLease') {
				items = obj.Item.map(item => {

					let itemTextLinks = [];

					if (item.Text) {

						if (Array.isArray(item.Text.links.link)) {

							itemTextLinks = item.Text.links.link.map(link => {
								return {
									name: link.name,
									target: link.target || link.anchor
								};
							});

						} else if (item.Text.links !== '') {

							itemTextLinks = [{
								name: item.Text.links.link.name,
								target: item.Text.links.link.target || item.Text.links.link.anchor
							}];

						}

					}

					return {
						title: item.Title,
						text: item.Text ? replaceCmsContentLinks(item.Text.content, itemTextLinks) : '',
						image: item.Image.link.target.replace(environment.cmsExportPath, ''),
						altText: item.AltText || '',
						isIcon: item.IsIcon,
						anchor: item.Anchor,
						linkName: item.LinkName,
						link: mapLinks([{
							url: item.Link ? item.Link.link.target.replace(environment.cmsExportPath, '') : '',
							navText: item.LinkName,
						}], props.offline)[0].url
					};
				});
			}
		} catch (err) {
			console.log(err);
		}

	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		text: text,
		bottomText: bottomText,
		subTitle: subTitle,
		background: background,
		linkName: linkName,
		link: link,
		mAutoTiles: mAutoTiles,
		rows: rows,
		items: state ? state.items : items
	};

};

class TileSet extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderBoxes = this.renderBoxes.bind(this);
		this.renderMAutoBoxes = this.renderMAutoBoxes.bind(this);
		this.renderBoxFooter = this.renderBoxFooter.bind(this);
		this.renderPortletHeader = this.renderPortletHeader.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('tile-set');
		}

		// fill items only for mAuto content based portlet
		if (this.state.mAutoTiles === 'afterlease' || this.state.mAutoTiles === 'promoafterlease' || this.state.mAutoTiles === 'volvoAfterLease') {

			let apiPath = '';

			if (this.state.offline) {
				apiPath = environment.offlineMAutoApiPath + this.state.mAutoTiles + '.json';
			} else {
				apiPath = environment.onlineMAutoApiPath + this.state.mAutoTiles + '.json';
			}

			fetch(apiPath)
				.then(res => res.json())
				.then(json => {
					this.setState({
						title: this.state.title,
						background: this.state.background,
						linkName: this.state.linkName,
						link: this.state.link,
						mAutoTiles: this.state.mAutoTiles,
						items: json.map(item => {
							return {
								make: item.make,
								model: item.model,
								category: item.category,
								gearboxType: item.gearboxType,
								mainPhotoUrl: (this.state.offline ? 'mleasing' : '') + item.mainPhotoUrl.replace(environment.cmsExportPath, ''),
								mautoUrl: item.mautoUrl,
								productionYear: item.productionYear,
								fuelType: item.fuelType,
								leasePriceNetto: item.leasePriceNetto,
								rentPriceNetto: item.rentPriceNetto,
								totalPrice: item.cashPriceBrutto,
								marketingLabels: item.marketingLabels
							};
						})
					});
				});

		} else {
		
			let timeout = setTimeout(() => {

				const hash = window.location.hash;
				let element = null;

				if (hash) {
					element = document.getElementById(hash.replace('#', ''));
					
					if (element) {
						window.scrollTo(0, element.getBoundingClientRect().y - 116);
					}

				}
				
				clearTimeout(timeout);
			}, 3000);

		}

	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	renderBoxFooter(item) {

		if (this.state.mAutoTiles === 'promoafterlease' || this.state.mAutoTiles === 'volvoAfterLease') {
			return (
				<BoxTable>
					<BoxTableHead>
						<BoxTableRow>
							<BoxTableTH>najem</BoxTableTH>
							<BoxTableTH>leasing</BoxTableTH>
							<BoxTableTH>gotówka</BoxTableTH>
						</BoxTableRow>
					</BoxTableHead>
					<BoxTableBody>
						<BoxTableRow>
							<BoxTableTD fontSize={16} color={palette.zeusBlackUI}>
								<Text as="h4">{item.rentPriceNetto} zł</Text>
							</BoxTableTD>
							<BoxTableTD fontSize={16} color={palette.zeusBlackUI}>
								<Text as="h4">{item.leasePriceNetto} zł</Text>
							</BoxTableTD>
							<BoxTableTD fontSize={16} color={palette.zeusBlackUI}>
								<Text as="h4">{item.totalPrice} zł</Text>
							</BoxTableTD>
						</BoxTableRow>
						<BoxTableRow>
							<BoxTableTD fontSize={12} color={palette.doveGrayUI}>netto mies.</BoxTableTD>
							<BoxTableTD fontSize={12} color={palette.doveGrayUI}>netto mies.</BoxTableTD>
							<BoxTableTD fontSize={12} color={palette.doveGrayUI}>brutto</BoxTableTD>
						</BoxTableRow>
					</BoxTableBody>
				</BoxTable>
			);
		} else {
			return <Text as="small" color="doveGrayUI">{item.category}</Text>;
		}

	}

	// <a href={item.mautoUrl} style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}></a>
	renderMAutoBoxes(items) {

		let flexBasis = 100 / items.length;

		return items.map((item, i) => {
			return (
				<CustomTile backgroundColor={this.state.background} key={'tile-' + i} flexBasis={flexBasis}>
					{generateLink(item.mautoUrl, '', tileLinkStyles, '', this.state.offline)}
					<Image
						src={(this.state.offline ? '/' : '') + item.mainPhotoUrl}
						title={item.make}
						alt={item.make}
						width="100%"
					/>
					<Inner>
						<Text as="h3" marginBottom={Spacing.SPACE_4}>{item.make} {item.model}</Text>
						<Box>
							<Text as="small" color="doveGrayUI">{item.productionYear} - {item.fuelType} - {item.gearboxType}</Text>
						</Box>
						<Box>{this.renderBoxFooter(item)}</Box>
					</Inner>
				</CustomTile>
			);
		});
	}

	renderBoxes(items) {

		let flexBasis = 100 / items.length;
		let flexWrap = false;

		if (this.state.rows >= 2) {
			flexBasis = 50;
			flexWrap = true;
		}

		return items.map((item, i) => {

			let lastRowTile = false;

			if (items.length >= 4) {

				if (i === items.length - 1) {
					lastRowTile = true;
				}

				if (i === items.length - 2 && !(items.length % 2)) {
					lastRowTile = true;
				}

			}

			// Tile component should be used, but it is impossible because it uses react-router-dom link
			return (
				<CustomTile
					id={item.anchor ? item.anchor.replace('#', '') : ''}
					backgroundColor={this.state.background}
					key={'tile-' + i}
					flexBasis={flexBasis}
					flexWrap={flexWrap}
					lastRowTile={lastRowTile}
				>
					{item.link ? generateLink(item.link, '', tileLinkStyles, '', this.state.offline) : ''}
					<Box
						paddingTop={item.isIcon ? 12 : 0}
						paddingRight={item.isIcon ? 16 : 0}
						paddingLeft={item.isIcon ? 16 : 0}
						style={{ lineHeight: 0 }}
					>
						<Image
							src={item.image}
							title={item.title}
							alt={item.altText}
							width={item.isIcon ? '48px' : '100%'}
							objectFit="initial"
						/>
					</Box>
					<Inner isIcon={item.isIcon}>
						<Text as="h3">{item.title}</Text>
						<Box
							paddingBottom={item.link && item.linkName ? Spacing.SPACE_28 : Spacing.SPACE_0}
							paddingTop={Spacing.SPACE_8}
						>
							{componentsFromHtml(item.text, this.state.offline)}
						</Box>
						{item.link ? generateLink(item.link, item.linkName, bottomLinkStyles, '', this.state.offline) : ''}
					</Inner>
				</CustomTile>
			);
		});
	}

	renderPortletHeader(data, type) {

		if (data && type === 'title') {
			return (<Text
				as="h2"
				align="center"
				marginRight={Spacing.SPACE_16}
				marginBottom={Spacing.SPACE_24}
				marginLeft={Spacing.SPACE_16}
			>
				{this.state.title}
			</Text>);
		} else if (data && type === 'text') {
			return (<TextBox>
				{componentsFromHtml(this.state.text, this.state.offline)}
			</TextBox>);
		} else if (data && type === 'subTitle') {
			return (<Text
				as="h3"
				align="center"
				marginRight={Spacing.SPACE_16}
				marginBottom={Spacing.SPACE_24}
				marginLeft={Spacing.SPACE_16}
			>
				{this.state.subTitle}
			</Text>);
		} else {
			return '';
		}

	}

	render() {

		let flexWrap = false;

		if (this.state.items && this.state.items.length >= 4) {

			if (!this.state.mAutoTiles || this.state.mAutoTiles === 'none') {
				flexWrap = true;
			}

		}

		return (
			<Container>
				{this.renderPortletHeader(this.state.title, 'title')}
				{this.renderPortletHeader(this.state.text, 'text')}
				{this.renderPortletHeader(this.state.subTitle, 'subTitle')}
				<InnerDesktop as="div" className="inner desktop" flexWrap={flexWrap}>
					{this.state.mAutoTiles && this.state.mAutoTiles !== 'none' ? this.renderMAutoBoxes(this.state.items) : this.renderBoxes(this.state.items)}
				</InnerDesktop>
				<InnerMobile
					as="div"
					className="inner mobile"
					mobilePaddingLeft={this.state.pageType === 'hp' ? 0 : px(Spacing.SPACE_16)}
					mobilePaddingRight={this.state.pageType === 'hp' ? 0 : px(Spacing.SPACE_16)}
				>
					<Carousel>
						{this.state.mAutoTiles && this.state.mAutoTiles !== 'none' ? this.renderMAutoBoxes(this.state.items) : this.renderBoxes(this.state.items)}
					</Carousel>
				</InnerMobile>
				{this.state.bottomText ? <BottomTextBox>{componentsFromHtml(this.state.bottomText, this.state.offline)}</BottomTextBox> : ''}
				<ButtonWrapper as="div">
					{this.state.link && generateLink(this.state.link, '', '', (
						<Button buttonType="primary">{this.state.linkName}</Button>
					), this.state.offline)}
				</ButtonWrapper>
			</Container>
		);
	}

}

export default TileSet;
