/*!
 * mLeasing, contact-boxset portlet :: 03/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {TextField} from '@mbank-design/design-system/components';
import {Checkbox} from '@mbank-design/design-system/components';
import {ValidationMessage} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {eventLogger} from '@mbank-design/design-system/utils';
import Container from '../../components/container.js';
import {mapLinks} from '../../utils/map-links.js';
import SuccessErrorScreen from '../../components/success-error-screen.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.2 :: r. 11/05/2021 @ fs';
const PhoneNumber = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	text-align: left;
	height: ${px(Spacing.SPACE_44)};
	font-size: ${props => props.fastCall ? px(Spacing.SPACE_16) : px(Spacing.SPACE_24)};
	font-weight: ${props => props.fastCall ? 600 : 500};
	color: ${palette.endeavourBlueUI};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: inline;
	}
`;
const FlexContainer = styled.div`
	display: flex;
	flex-wrap: ${props => props.flexWrap ? 'wrap' : 'nowrap'};
	flex-direction: ${props => props.direction || 'row'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;	
	}
`;
const FlexBox = styled.div`
	display: ${props => props.display || 'block'};
	flex-basis: calc(${props => props.flexBasis ? '33.33%' : '50%'} - ${px(Spacing.SPACE_24)});
	flex-grow: 1;
	flex-shrink: 0;
	margin-top: ${props => props.marginTop || Spacing.SPACE_0};
	margin-right: ${props => props.marginRight || Spacing.SPACE_0};
	margin-left: ${props => props.marginLeft || Spacing.SPACE_0};
	background: ${palette.solidWhite};
	padding: ${props => px(props.padding || 0)};
	box-shadow: ${props => props.boxShadow ? `${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08)` : 'none'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-basis: 100%;	
		flex-direction: ${props => props.direction || 'row'};
		margin-right: ${Spacing.SPACE_0};
		margin-left: ${Spacing.SPACE_0};
		margin-bottom: ${props => props.mobileMarginBottom || 0};
		text-align: center;
	}
`;
const LeftColumn = styled.div`
	flex-basis: 33.33%;
	flex-grow: 1;
	flex-shrink: 1;
`;
const RightColumn = styled.div`
	flex-basis: 66.66%;
	flex-grow: 1;
	flex-shrink: 0;
`;
const FastCallTextField = styled(TextField)`
	max-width: ${px(309)};
	padding-right: ${px(Spacing.SPACE_44)};
	margin-top: ${px(Spacing.SPACE_24)};
	margin-bottom: ${px(Spacing.SPACE_12)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-width: 100%;
	}
`;
const BottomTextBox = styled.div`
	margin-top: ${px(Spacing.SPACE_40)};

	& small {
		display: block;
		color: ${palette.doveGrayUI};
	}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const ApprovalContainer = styled.div`
	position: relative;
	display: flex;
`;
const ErrorMessage = styled.div`
	position: absolute;
	bottom: -18px;
	left: 0;
`;
const buildState = (props, state) => {

	let items = [];
	let fastCallBox = false;
	let fastCallImage = '';
	let fastCallLinkName = '';
	let title = '';
	let fastCallTitle = ''
	let fastCallText = '';
	let fastCallAltText = '';
	let fastCallNote = '';
	let bottomText = '';

	if (props.offline) {
		title = props.data.title | '';
		fastCallImage = props.data.fastCallImage || '';
		fastCallLinkName = props.data.fastCallLinkName || '';
		fastCallBox = props.data.fastCallBox === 'true' ? true : false;
		fastCallTitle = props.data.fastCallTitle || '';
		fastCallText = props.data.fastCallText || '';
		fastCallAltText = props.data.fastCallAltText || '';
		fastCallNote = props.data.fastCallNote || '';
		bottomText = props.data.bottomText || '';
		items = props.data && props.data.items.map(item => {
			return {
				title: item.title || '',
				text: item.text || '',
				bottmText: item.bottmText || '',
				image: item.image || '',
				altText: item.altText || '',
				tiles: item.tiles ? item.tiles.map(tile => {
					return {
						title: tile.title,
						text: tile.text,
						phoneNumber: tile.phoneNumber,
						linkName: tile.linkName,
						link: tile.link ? mapLinks([{
							url: tile.link,
							navText: tile.linkName,
						}], props.offline)[0].url : ''
					};
				}) : []
			};
		});
	} else {

		try {

			let obj = props.data['Contact-boxsetData']['Contact-boxset'];

			title = obj.Title || '';
			fastCallImage = obj.FastCallImage ? obj.FastCallImage.link.target.replace(environment.cmsExportPath, '') : '';
			fastCallLinkName = obj.FastCallLinkName || '';
			fastCallBox = obj.FastCallBox ? true : false;
			fastCallTitle = obj.FastCallTitle || '';
			fastCallText = obj.FastCallText || '';
			fastCallAltText = obj.FastCallAltText || '';

			let fastCallNoteLinks = [];

			if (obj.FastCallNote) {

				if (Array.isArray(obj.FastCallNote.links.link)) {

					fastCallNoteLinks = obj.FastCallNote.links.link.map(link => {
						return {
							name: link.name,
							target: link.target || link.anchor
						};
					});

				} else if (obj.FastCallNote.links !== '') {

					fastCallNoteLinks = [{
						name: obj.FastCallNote.links.link.name,
						target: obj.FastCallNote.links.link.target || obj.FastCallNote.links.link.anchor
					}];

				}

			}

			fastCallNote = obj.FastCallNote ? replaceCmsContentLinks(obj.FastCallNote.content, fastCallNoteLinks) : '';

			let bottomTextLinks = [];

			if (obj.BottomText) {

				if (Array.isArray(obj.BottomText.links.link)) {

					bottomTextLinks = obj.BottomText.links.link.map(link => {
						return {
							name: link.name,
							target: link.target || link.anchor
						};
					});

				} else if (obj.BottomText.links !== '') {

					bottomTextLinks = [{
						name: obj.BottomText.links.link.name,
						target: obj.BottomText.links.link.target || obj.BottomText.links.link.anchor
					}];

				}

			}

			bottomText = obj.BottomText ? replaceCmsContentLinks(obj.BottomText.content, bottomTextLinks) : '';
			items = obj.Item.map(item => {

				let textLinks = [];

				if (item.Text) {

					if (Array.isArray(item.Text.links.link)) {

						textLinks = item.Text.links.link.map(link => {
							return {
								name: link.name,
								target: link.target || link.anchor
							};
						});

					} else if (item.Text.links !== '') {

						textLinks = [{
							name: item.Text.links.link.name,
							target: item.Text.links.link.target || item.Text.links.link.anchor
						}];

					}

				}

				let bottomTextLinks = [];

				if (item.BottomText) {

					if (Array.isArray(item.BottomText.links.link)) {

						bottomTextLinks = item.BottomText.links.link.map(link => {
							return {
								name: link.name,
								target: link.target || link.anchor
							};
						});

					} else if (item.BottomText.links !== '') {

						bottomTextLinks = [{
							name: item.BottomText.links.link.name,
							target: item.BottomText.links.link.target || item.BottomText.links.link.anchor
						}];

					}

				}

				let tiles = [];
				let tileTextLinks = [];

				if (Array.isArray(item.Tile)) {
					tiles = item.Tile.map(tile => {

						if (Array.isArray(tile.Text.links.link)) {

							tileTextLinks = tile.Text.links.link.map(link => {
								return {
									name: link.name,
									target: link.target || link.anchor
								};
							});

						} else if (tile.Text.links !== '') {

							tileTextLinks = [{
								name: tile.Text.links.link.name,
								target: tile.Text.links.link.target || tile.links.link.anchor
							}];

						}

						return {
							title: tile.Title,
							text: replaceCmsContentLinks(tile.Text.content, tileTextLinks),
							phoneNumber: tile.PhoneNumber,
							linkName: tile.LinkName,
							link: mapLinks([{
								url: tile.Link ? tile.Link.link.target : '',
								navText: tile.LinkName,
							}], props.offline)[0].url
						};
					});
				} else {
				
					if (item.Tile) {

						if (Array.isArray(item.Tile.Text.links.link)) {

							tileTextLinks = item.Tile.Text.links.link.map(link => {
								return {
									name: link.name,
									target: link.target || link.anchor
								};
							});

						} else if (item.Tile.Text.links !== '') {

							tileTextLinks = [{
								name: item.Tile.Text.links.link.name,
								target: item.Tile.Text.links.link.target || item.Tile.Text.links.link.anchor
							}];

						}

						tiles = [{
							title: item.Tile.Title,
							text: item.Tile.Text ? replaceCmsContentLinks(item.Tile.Text.content, tileTextLinks) : '',
							phoneNumber: item.Tile.PhoneNumber,
							linkName: item.Tile.LinkName,
							link: mapLinks([{
								url: item.Tile.Link ? item.Tile.Link.link.target : '',
								navText: item.Tile.LinkName,
							}], props.offline)[0].url
						}];
					}

				}

				return {
					title: item.Title,
					text: item.Text ? replaceCmsContentLinks(item.Text.content, textLinks) : '',
					bottomText: item.BottomText ? replaceCmsContentLinks(item.BottomText.content, bottomTextLinks) : '',
					image: item.Image ? item.Image.link.target.replace(environment.cmsExportPath, '') : '',
					altText: item.AltText || '',
					tiles: tiles
				};
			});
		} catch (err) {
			console.log(err);
		}

	}

	return {
		offline: props.offline,
		title: title,
		fastCallBox: fastCallBox,
		fastCallImage: fastCallImage,
		fastCallLinkName: fastCallLinkName,
		fastCallTitle: fastCallTitle,
		fastCallText: fastCallText,
		fastCallAltText: fastCallAltText,
		fastCallNote: fastCallNote,
		bottomText: bottomText,
		showSuccessError: state ? state.showSuccessError : false,
		success: state ? state.success : null,
		error: state ? state.error : null,
		items: items,
		phoneNumber: state ? state.phoneNumber : '',
		approval: state ? state.approval : false,
		phoneNumberErrorMessage: state ? state.phoneNumberErrorMessage : '',
		approvalErrorMessage: state ? state.approvalErrorMessage : '',
		valid: state ? state.valid : false
	};

};

class ContactBoxSet extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderBoxes = this.renderBoxes.bind(this);
		this.renderTiles = this.renderTiles.bind(this);
		this.renderFastCallBox = this.renderFastCallBox.bind(this);
		this.sendCallback = this.sendCallback.bind(this);
		this.changePhoneNumber = this.changePhoneNumber.bind(this);
		this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
		this.confirmApproval = this.confirmApproval.bind(this);
		this.updateState = this.updateState.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.closeSuccessError = this.closeSuccessError.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('contact-boxset');
		}
	}
	
	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	updateState(key, value, msgKey, message) {
		this.setState({
			offline: this.state.offline,
			title: this.state.title,
			fastCallBox: this.state.fastCallBox,
			fastCallImage: this.state.fastCallImage,
			fastCallLinkName: this.state.fastCallLinkName,
			fastCallTitle: this.state.fastCallTitle,
			fastCallText: this.state.fastCallText,
			fastCallAltText: this.state.fastCallAltText,
			fastCallNote: this.state.fastCallNote,
			bottomText: this.state.bottomText,
			items: this.state.items,
			phoneNumber: key === 'phoneNumber' ? value : this.state.phoneNumber,
			phoneNumberErrorMessage: msgKey === 'phoneNumberErrorMessage' ? message : this.state.phoneNumberErrorMessage,
			approval: key === 'approval' ? value : this.state.approval,
			approvalErrorMessage: msgKey === 'approvalErrorMessage' ? message : this.state.approvalErrorMessage,
			showSuccessError: key === 'showSuccessError' ? value : this.state.showSuccessError,
			success: key === 'success' ? value : this.state.success,
			error: key === 'error' ? value : this.state.error,
			valid: key === 'valid' ? value : this.state.valid
		});
	}

	changePhoneNumber(e) {

		let value = e.target.value;
		let message = '';
		const matchDigits = value.match(/^\d*$/);
		const matchLength = value.length <= 9;

		if (value.length !== 0) {

			if (!matchLength) {
				message = 'wpisz maksymalnie 9 cyfr';
			}

			if (!matchDigits) {
				message = 'tylko cyfry';
			}

		}

		this.updateState('phoneNumber', value, 'phoneNumberErrorMessage', message);
	}

	validatePhoneNumber(e) {

		let value = e.target.value;
		let message = '';
		const matchDigits = value.match(/^\d*$/);
		const matchLength = value.length === 9;

		if (value.length !== 0) {

			if (!matchLength) {
				message = 'wpisz poprawny numer telefonu';
			}

			if (!matchDigits) {
				message = 'tylko cyfry';
			}

		} else {
			message = 'pole nie może być puste';
		}

		this.updateState('phoneNumber', value, 'phoneNumberErrorMessage', message);
	}

	confirmApproval(e) {

		let value = e.target.checked;
		let message = '';

		if (!value) {
			message = 'zgoda wymagana';
		}

		this.updateState('approval', value, 'approvalErrorMessage', message);
	}
	
	validateForm() {

		if (!this.state.phoneNumberErrorMessage && this.state.phoneNumber
			&& !this.state.approvalErrorMessage && this.state.approval) {
				
				let timeout = setTimeout(() => {
					this.updateState('valid', true, '', '');
					clearTimeout(timeout);
				}, 0);

		} else {
			Promise.resolve()
				.then(() => {


					if (!this.state.approval) {
						this.updateState('', '', 'approvalErrorMessage', 'zgoda wymagana');
					}

				})
				.then(() => {

					let value = this.state.phoneNumber;
					let message = '';
					const matchDigits = value.match(/^\d*$/);
					const matchLength = value.length === 9;

					if (value.length !== 0) {

						if (!matchLength) {
							message = 'wpisz poprawny numer telefonu';
							this.updateState('', '', 'phoneNumberErrorMessage', message);
						}

						if (!matchDigits) {
							message = 'tylko cyfry';
							this.updateState('', '', 'phoneNumberErrorMessage', message);
						}

					} else {
						message = 'pole nie może być puste';
						this.updateState('', '', 'phoneNumberErrorMessage', message);
					}
				});
		}

	}

	closeSuccessError() {
		Promise.resolve()
			.then(() => {
				this.updateState('approval', null, 'approvalErrorMessage', '');
			})
			.then(() => {
				this.updateState('phoneNumber', null, 'phoneNumberErrorMessage', '');
			})
			.then(() => {
				this.updateState('showSuccessError', false, '', '');
			})
			.then(() => {
				this.updateState('error', null, '', '');
			})
			.then(() => {
				this.updateState('success', null, '', '');
			})

	}

	sendCallback(e) {
		this.validateForm();

		let timeout = setTimeout(() => {

			if (this.state.valid) {
			
				const url = environment.fastCallUrl;
				let body = `phone=%2b48${this.state.phoneNumber}&agree1=${this.state.approval}`;
			
				fetch(url, {
					body: body,
					headers: {
					"Content-Type": "application/x-www-form-urlencoded"
					},
					method: "POST"
				})
					.then(res => res.json())
					.then(res => {

						if (res.errors) {
						
							if (res.errors.phone) {
								this.updateState('phoneNumber', this.state.phoneNumber, 'phoneNumberErrorMessage', res.errors.phone);

								let timeout = setTimeout(() => {
									this.updateState('error', res, '', '');
									clearTimeout(timeout);
								}, 500);

							}

						} else {
							this.updateState('success', res, '', '');
						}

						let timeout = setTimeout(() => {
							this.updateState('showSuccessError', true, '', '');
							clearTimeout(timeout);
						}, 500);

					}).catch(err => {
						this.updateState('error', err, '', '');

						let timeout = setTimeout(() => {
							this.updateState('showSuccessError', true, '', '');
							clearTimeout(timeout);
						}, 500);
					});

			}

			clearTimeout(timeout);
		}, 100);

	}

	renderTiles(tiles) {
		return tiles ? tiles.map((tile, i) => {

			let rightMargin = Spacing.SPACE_0;
			let leftMargin = Spacing.SPACE_0;

			if (!this.state.fastCallBox) {

				if (i === 0) {
					rightMargin = px(Spacing.SPACE_12);
				}

				if (i === 1) {
					leftMargin = px(Spacing.SPACE_12);
				}

			}

			return (
				<FlexBox
					key={'tile-' + i}
					marginRight={rightMargin}
					marginLeft={leftMargin}
					mobileMarginBottom={i === 0 ? px(Spacing.SPACE_24) : 0}
				>
					{tile.title ? <Text as="h3" marginBottom={Spacing.SPACE_16}>{tile.title}</Text> : ''}
					<Box>{componentsFromHtml(tile.text, this.state.offline)}</Box>
					{tile.link ? generateLink(this.props.prefix + tile.link, '', '', (<Box marginTop={Spacing.SPACE_16}>
						<Button buttonType='primary' onClick={eventLogger.click}>{tile.linkName}</Button>
					</Box>), this.state.offline) : ''}
					{tile.phoneNumber ? <PhoneNumber fastCall={this.state.fastCallBox}><a href={`tel:${tile.phoneNumber}`}>{tile.phoneNumber}</a></PhoneNumber> : ''}
				</FlexBox>
			);
		}) : [];
	}

	renderBoxes(items) {
		return items.map((item, i) => {

			let rightMargin = Spacing.SPACE_0;
			let leftMargin = Spacing.SPACE_0;

			if (i === 0) {
				rightMargin = px(Spacing.SPACE_12);
			}

			if (i === 1) {
				leftMargin = px(Spacing.SPACE_12);

				if (items.length === 3) {
					rightMargin = px(Spacing.SPACE_12);
				}
			}

			if (i === 2) {
				leftMargin = px(Spacing.SPACE_12);
			}

			return (
				<FlexBox
					key={'contact-boxset-item-' + i}
					flexBasis={this.state.fastCallBox}
					marginRight={rightMargin}
					marginLeft={leftMargin}
					boxShadow={this.state.fastCallBox}
					padding={Spacing.SPACE_32}
				>
					{item.image ? (<Box paddingBottom={px(Spacing.SPACE_16)}>
						<Image
							src={item.image}
							alt={item.altText}
							title={item.title}
							maxWidth={this.state.fastCallBox ? 48 : 240}
							maxHeight={this.state.fastCallBox ? 48 : 240}
						/>
					</Box>) : ''}
					{item.title ? <Text as="h2" marginBottom={Spacing.SPACE_16}>{item.title}</Text> : ''}
					{item.text ? <Box marginBottom={Spacing.SPACE_16}>{componentsFromHtml(item.text, this.state.offline)}</Box> : ''}
					{item.tiles ? <FlexContainer direction={this.state.fastCallBox ? 'column' : 'row'}>{this.renderTiles(item.tiles)}</FlexContainer> : ''}
					{item.bottomText ? <Box marginTop={Spacing.SPACE_16}>{componentsFromHtml(item.bottomText, this.state.offline)}</Box> : ''}
				</FlexBox>
			);
		});
	}

	renderFastCallBox() {
		return (<FlexBox
			marginTop={px(Spacing.SPACE_24)}
			padding={Spacing.SPACE_32}
			display="flex"
			direction="column"
		>
			<LeftColumn>
				{this.state.fastCallImage ? (<Box paddingBottom={px(Spacing.SPACE_16)}>
					<Image
						src={this.state.fastCallImage}
						alt={this.state.fastCallAltText}
						title={this.state.title}
						maxWidth={240}
						max-height={240}
					/>
				</Box>) : ''}
			</LeftColumn>
			{!this.state.showSuccessError ? <RightColumn>
				<Text as="h3" marginBottom={Spacing.SPACE_24}>{this.state.fastCallTitle}</Text>
				<Text as="p">{this.state.fastCallText}</Text>
				<FastCallTextField
					value={this.state.phoneNumber}
					placeholder="numer telefonu"
					height={Spacing.SPACE_24}
					onChange={this.changePhoneNumber}
					onBlur={this.validatePhoneNumber}
					errorMessage={this.state.phoneNumberErrorMessage}
				/>
				<Box
					as="div"
					marginBottom={px(Spacing.SPACE_16)}
					maxWidth={714}
				>
					<ApprovalContainer>
						<Box
							marginTop={Spacing.SPACE_4}
							marginRight={Spacing.SPACE_12}
						>
							<Checkbox 
								onChange={this.confirmApproval}
								name="approval"
								checked={this.state.approval}
								errorMessage={this.state.approvalErrorMesssage}
							/>
							{this.state.approvalErrorMessage ? (<ErrorMessage>
								<ValidationMessage>{this.state.approvalErrorMessage}</ValidationMessage>
							</ErrorMessage>) : ''}
						</Box>
						<Box>
							<Text as="small">{componentsFromHtml(this.state.fastCallNote, this.state.offline)}</Text>
						</Box>
					</ApprovalContainer>
				</Box>
				<Box>
					<Button
						buttonType="primary"
						onClick={this.sendCallback}
					>
						{this.state.fastCallLinkName}
					</Button>
				</Box>
			</RightColumn> : <RightColumn>
				<SuccessErrorScreen
					fastCall={true}
					show={this.state.showSuccessError}
					success={this.state.success}
					callback={this.closeSuccessError}
					error={this.state.error}
				/>
			</RightColumn>}
		</FlexBox>);
	}

	render() {
		return (
			<Container>
				{this.state.title ? <Box marginBottom={Spacing.SPACE_32}>
					<Text as="h2" align="center">{this.state.title}</Text>
				</Box> : ''}
				<FlexContainer flexWrap={this.state.fastCallBox}>
					{this.renderBoxes(this.state.items)}
					{this.state.fastCallBox ? this.renderFastCallBox() : ''}
				</FlexContainer>
				{this.state.bottomText ? <BottomTextBox>{componentsFromHtml(this.state.bottomText, this.state.offline)}</BottomTextBox> : ''}
			</Container>
		);
	}

}

export default ContactBoxSet;
