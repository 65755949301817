/*!
 * mLeasing, footer portlet :: 07/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {IconColor} from '@mbank-design/design-system/enums';
import {Icon} from '@mbank-design/design-system/components';
import {Paper, Download} from '@mbank-design/design-system/icons';
import {Disclosure} from '@mbank-design/design-system/components';
import {Spacing, LinkColor} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import Container from '../../components/container.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const BoxWrapper = styled.div`
	display: inherit;
	flex-direction: ${props => props.direction ? props.direction : 'row'};
	flex-wrap: nowrap;
	position: relative;
    flex-direction: column;
`;
const Row = styled.div`
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
`;
const RowEx = styled.div`
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`;
const RowLeft = styled.div`
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;
    margin-left: 12px;
`;
const CustomBox = styled.div`
    display: inline-flex;
	position: relative;
	flex-bais: calc(25% - 24px);
	max-width: calc(25% - 24px);
	flex-grow: 1;
	flex-shrink: 0;
    margin-top: ${px(Spacing.SPACE_12)};
	margin-right: ${px(Spacing.SPACE_24)};
	margin-bottom: ${props => props.count > 3 && props.count !== 4 && props.index < props.count - 3 ? px(Spacing.SPACE_24) : px(Spacing.SPACE_0)};
	padding: ${px(Spacing.SPACE_24)};
	background-color: ${props => props.background !== 'dark' ? palette.wildSandGray : palette.solidWhite};
	box-shadow: ${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08);

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-width: unset;
		margin-right: ${px(Spacing.SPACE_12)};
		margin-bottom: ${px(Spacing.SPACE_0)};
	}
`;
const Archive = styled.div`
	margin-top: 36px;
`;
const Inner = styled.div`
	margin: 0 auto;
    margin-top: ${px(Spacing.SPACE_24)};
	text-align: ${props => props.align ? props.align : 'left'};
	padding-bottom: ${props => props.paddingBottom || Spacing.SPACE_0};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${props => props.mobilePadding || 0};
		padding-left: ${props => props.mobilePadding || 0};
		text-align: center;
	}
`;
const InnerMobile = styled(BoxWrapper)`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const InnerDesktop = styled(BoxWrapper)`
	flex-wrap: ${props => props.count > 3 && !(props.count%3) || props.count > 4 ? 'wrap' : 'nowrap'};
	justify-content: center;
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const parseDownloads = downloads => {

	let arr = [];

	if (Array.isArray(downloads)) {
		arr = downloads.map(download => {
			return {
				title: download.Title,
				downloadText: download.downloadText,
				downloadFile: download.downloadFile.link.target.replace(environment.cmsExportPath, '')
			};
		});
	} else {
		arr = [{
			title: downloads.Title,
			downloadText: downloads.downloadText,
			downloadFile: downloads.downloadFile.link.target.replace(environment.cmsExportPath, '')
		}];
	}
	
	return arr;
};
const buildState = (props) => {

	let title = '';
	let background = '';
	let groups = [];
	let downloads = [];

	if (props.offline) {
		title = props.data.title;
		background = props.data.background;
		groups = props.data.groups ? props.data.groups.map(group => ({
			groupTitle: group.groupTitle,
			downloads: group.downloads
		})) : [];
	} else {

		try {

			let obj = props.data['DownloadboxData']['Downloadbox'];

			title = obj.Title;
			background = obj.Background;

			if (obj.Group) {
			
				if (Array.isArray(obj.Group)) {
					groups = obj.Group.map((group) => {
						downloads = parseDownloads(group.Downloads);
						return {
							groupTitle: group.GroupTitle,
							downloads: downloads
						}
					})
				} else {
					downloads = parseDownloads(obj.Group.Downloads);
					groups = [{
						groupTitle: obj.Group.GroupTitle,
						downloads: downloads
					}];
				}

			}

		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		background: background,
		title: title,
		groups: groups
	};

};

class DownloadBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('download-box');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

    renderBoxes(downloads) {
		return downloads.map((item, i) => {
			//Tile component should be used, but it is impossible because it uses react-router-dom link
			return (
				<CustomBox key={'downloadbox-' + i} count={item.length} index={i} background={this.state.background}>
					<BoxWrapper direction="row">
						<Row>
							<Icon iconComponent={Paper}/>
						</Row>
						<RowEx>
							{generateLink(item.downloadFile, item.downloadText, `color: ${LinkColor.BLACK};`, '', this.state.offline)}
							<RowLeft>
								<Icon iconComponent={Download} primaryColor={IconColor.BLUE} size={32}/>
							</RowLeft>
						</RowEx>
					</BoxWrapper>
				</CustomBox>
			);
		});
	}

    renderBoxesNewest(groups) {
        return groups.map((group, i) => {
            if (i <= 1) {
                return (
                    <Inner key={'inner-' + i}>
                        <Text as="h3">{group.groupTitle ? group.groupTitle : ""}</Text>
                        {this.renderBoxes(group.downloads)}
                    </Inner>
                );
            } 
        });
    }

	renderBoxesArchive(groups) {
		return groups.map((group, i) => {
            if (i > 1) {
                return (
                    <Inner key={'inner-' + i}>
                        <Text as="h3">{group.groupTitle ? group.groupTitle : ""}</Text>
                        {this.renderBoxes(group.downloads)}
                    </Inner>
                );
            }
		})
	}

	renderBoxesGroupArchive(groups) {
		if (groups.length > 1) {
			return (
				<Archive>
					<Disclosure 
						isDefaultExpanded={false}
						marginTop={Spacing.SPACE_24}
					>
					<Disclosure.Button>archiwum</Disclosure.Button>  
						<Disclosure.Content>
							{this.renderBoxesArchive(groups)}
						</Disclosure.Content>
					</Disclosure>
				</Archive>
			)
		}
	}

	render() {

		const title = this.state.title ? <Text as="h2" align="left" marginBottom={Spacing.SPACE_24}>{this.state.title}</Text> : '';

		return (
			<Container>
				<Inner mobilePadding={px(Spacing.SPACE_16)}>
					{title}
				</Inner>
                <InnerDesktop as="div" className="inner desktop" count={this.state.groups.length}>
					{this.renderBoxesNewest(this.state.groups)}
					{this.renderBoxesGroupArchive(this.state.groups)}
				</InnerDesktop>
                <InnerMobile as="div" className="inner mobile">
					{this.renderBoxesNewest(this.state.groups)}
					{this.renderBoxesGroupArchive(this.state.groups)}
				</InnerMobile>
			</Container>
		);
	}

}

export default DownloadBox;
