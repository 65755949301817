/*!
 * mLeasing, success and error screen component :: 25/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {Illustration} from '@mbank-design/design-system/components';
import {RetailHandThumbUp} from '@mbank-design/design-system/illustrations';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import {generateLink} from '../utils/generate-link.js';

const version = 'v: 1.0.0 :: r. 29/04/2021 @ fs';
const standardData = {
	success: {
		title: 'Gotowe!',
		subtitle: 'Twoje zapytanie zostało wysłane',
		text: 'Przygotowujemy dla Ciebie dedykowaną ofertę i odpowiemy tak szybko, jak będzie to możliwe.',
		linkName: 'Wróć do strony głównej',
		link: '/'
	},
	error: {
		title: 'Wystąpił nieoczekiwany błąd',
		subtitle: 'Coś poszło nie tak',
		text: 'Prosimy wypełnić formularz jeszcze raz lub skontaktować się telefonicznie',
		linkName: 'Wróć do strony głównej',
		link: '/'
	}
};
const fastCallData = {
	success: {
		title: 'Gotowe!',
		subtitle: 'Dziękujemy i do usłyszenia',
		text: 'Wkrótce do ciebie oddzwonimy - odbierz od nas telefon.',
		linkName: 'Wróć do formularza',
		link: '/'
	},
	error: {
		title: 'Wystąpił nieoczekiwany błąd',
		subtitle: 'Coś poszło nie tak',
		text: 'Prosimy wypełnić formularz jeszcze raz lub skontaktować się telefonicznie',
		linkName: 'Wróć do formularza',
		link: '/'
	}
};
const Tile = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: ${palette.solidWhite};
	width: 100%;
	max-width: ${px(868)};
	padding: ${px(Spacing.SPACE_24)};
`;
const Inner = styled.div`
	max-width: ${props => props.fastCall ? '100%' : px(641)};
	display: flex;
	justify-content: center;
`;
const LeftColumn = styled.div`
	flex-basis: 33.33%;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
`;
const RightColumn = styled.div`
	flex-basis: ${props => props.fastCall ? '100%' : '66.66%'};
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;

class SuccessErrorScreen extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		
		let title = '';
		let subtitle = '';
		let text = '';
		let link = '';
		let linkName = '';
		let data = {};

		if (props.fastCall) {
			data = fastCallData
		} else {
			data = standardData;
		}

		if (props.success) {
			title = data.success.title;
			subtitle = data.success.subtitle;
			text = data.success.text;
			link = data.success.link;
			linkName = data.success.linkName;
		} else {
			title = data.error.title;
			subtitle = data.error.subtitle;
			text = data.error.text;
			link = data.error.link;
			linkName = data.error.linkName;
		}

		this.state = {
			show: props.show,
			title: title,
			subtitle: subtitle,
			text: text,
			link: link,
			linkName: linkName,
			fastCall: props.fastCall
		};
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('success error screen');
		}
	}

	static getDerivedStateFromProps(props, state) {

		let title = '';
		let subtitle = '';
		let text = '';
		let link = '';
		let linkName = '';
		let data = {};

		if (props.fastCall) {
			data = fastCallData;
		} else {
			data = standardData;
		}

		if (props.success) {
			title = data.success.title;
			subtitle = data.success.subtitle;
			text = data.success.text;
			link = data.success.link;
			linkName = data.success.linkName;
		} else {
			title = data.error.title;
			subtitle = data.error.subtitle;
			text = data.error.text;
			link = data.error.link;
			linkName = data.error.linkName;
		}

		return {
			show: props.show,
			title: title,
			subtitle: subtitle,
			text: text,
			link: link,
			linkName: linkName,
			fastCall: state.fastCall
		};
	}

	render() {
		return (
			this.state.show ? <Tile>
				<Box marginBottom={Spacing.SPACE_16}>
					<Text as="h2">{this.state.title}</Text>
				</Box>
				<Inner fastCall={this.state.fastCall}>
					{!this.state.fastCall ? <LeftColumn>
						<Illustration illustrationComponent={RetailHandThumbUp} size={198} />
					</LeftColumn> : ''}
					<RightColumn fastCall={this.state.fastCall}>
						<Box>
							<Text as="p" style={{textAlign: this.state.fastCall ? 'center' : 'left'}}>{this.state.subtitle}</Text>
						</Box>
						<Box>
							<Text as="p" style={{textAlign: this.state.fastCall ? 'center' : 'left'}}>{this.state.text}</Text>
						</Box>
						{this.state.fastCall ? <Box style={{textAlign: 'center', marginTop: '24px'}}>
							<Button
								buttonType="primary"
								onClick={this.props.callback ? this.props.callback : () => false}
							>
								{this.state.linkName}
							</Button>
						</Box> : <Box>
							{generateLink(this.state.link, null, '', (
								<Button buttonType="primary">{this.state.linkName}</Button>
							))}
						</Box>}
					</RightColumn>
				</Inner>
			</Tile> : ''
		);
	}

}

export default SuccessErrorScreen;
