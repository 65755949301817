/*!
 * mLeasing, box-set portlet :: 20/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Carousel} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import Container from '../../components/container.js';
import {mapLinks} from '../../utils/map-links.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
// styling
const Inner = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-top: ${px(Spacing.SPACE_40)};
		padding-bottom: ${px(Spacing.SPACE_40)};
	}
`;
const InnerMobile = styled(Inner)`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
		padding-left: ${props => props.mobilePaddingLeft || 0};
		padding-right: ${props => props.mobilePaddingRight || 0};
		padding-top: ${px(Spacing.SPACE_0)};
		padding-bottom: ${px(Spacing.SPACE_0)};
	}
`;
const InnerDesktop = styled(Inner)`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const Column = styled.div`
	flex-basis: 20%;
	padding: ${px(Spacing.SPACE_12)};
	box-sizing: border-box;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding: ${Spacing.SPACE_0}
	}
`;
const InnerBox = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: ${px(242)};
	padding: ${px(Spacing.SPACE_24)};
	box-shadow: ${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08);
	background-color: ${palette.solidWhite};
	text-align: center;
`;
const buildState = (props) => {

	let title = '';
	let isCarousel = '';
	let items = [];

	if (props.offline) {
		title = props.data.title;
		isCarousel = props.data.isCarousel;
		items = props.data && props.data.items.map(item => {
			return {
				linkName: item.linkName,
				link: mapLinks([{
					url: item.link,
					navText: item.linkName,
				}], props.offline)[0].url,
				icon: item.icon,
				altText: item.altText || '',
				text: item.text
			};
		});
	} else {

		try {

			let obj = props.data['Box-setData']['Box-set'];

			title = obj.Title;
			isCarousel = obj.IsCarousel;
			items = obj.Item.map(item => {
				return {
					linkName: item.LinkName,
					link: mapLinks([{
						url: item.Link.link.target,
						navText: item.LinkName,
					}], props.offline)[0].url,
					icon: item.Icon.link.target.replace(environment.cmsExportPath, ''),
					altText: item.AltText || '',
					text: item.Text
				};
			});
		} catch (err) {
			console.log(err);
		}

	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		isCarousel: isCarousel,
		items: items
	};

}

class BoxSet extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderBoxes = this.renderBoxes.bind(this);
		this.renderBoxSet = this.renderBoxSet.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('box-set');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	renderBoxes(items) {
		return items.map((item, i) => {
			return (
				<Column key={'box' + i} className="column">
					<InnerBox className="box">
						{item.link && generateLink(this.props.prefix + item.link, '', 'position: absolute; top: 0; right: 0; bottom: 0; left: 0;', '', this.state.offline)}
						{item.icon && <Image
							src={item.icon}
							alt={item.altText}
							title={item.text}
							maxWidth={px(Spacing.SPACE_48)}
							maxHeight={px(Spacing.SPACE_48)}
						/>}
						{item.text && <Box
							as="div"
							marginTop={px(Spacing.SPACE_12)}
							marginBottom={px(Spacing.SPACE_16)}
						>
							<Text as="h3">{item.text}</Text>
						</Box>}
						{item.link && generateLink(this.props.prefix + item.link, item.linkName, `margin-bottom: ${px(Spacing.SPACE_4)};`, '', this.state.offline)}
					</InnerBox>
				</Column>
			);
		});
	}

	renderBoxSet(isCarousel) {

		if (isCarousel) {
			const { length } = this.state.items
			if (length == 5) {
				return  (
					<Carousel isSwipeableOnDesktop={false} visibleSlides={5} step={1}>
						{this.renderBoxes(this.state.items)}
					</Carousel>
				);
			} else {
				return  (
					<Carousel visibleSlides={5} step={1}>
						{this.renderBoxes(this.state.items)}
					</Carousel>
				);
			}
			
		} else {
			return this.renderBoxes(this.state.items);
		}

	}

	render() {
		return (
			<Container>
				<Text as="h2" align="center" marginBottom={Spacing.SPACE_24}>{this.state.title}</Text>
				<InnerDesktop as="div" className="inner desktop">
					{this.renderBoxSet(this.state.isCarousel)}
				</InnerDesktop>
				<InnerMobile
					as="div"
					className="inner mobile"
					mobilePaddingLeft={this.state.pageType === 'hp' ? 0 : px(Spacing.SPACE_16)}
					mobilePaddingRight={this.state.pageType === 'hp' ? 0 : px(Spacing.SPACE_16)}
				>
					<Carousel>
						{this.renderBoxes(this.state.items)}
					</Carousel>
				</InnerMobile>
			</Container>
		);
	}

}

export default BoxSet;
