/*!
 * mLeasing, info-popup portlet :: 12/05/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Button} from '@mbank-design/design-system/components';
import {eventLogger} from '@mbank-design/design-system/utils';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Stripe} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {Close} from '@mbank-design/design-system/icons';
import {StripeHeight} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {mapLinks} from '../../utils/map-links.js';
import Container from '../../components/container.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 01/06/2021 @ fs';
const PopUp = styled.div`
	display: ${props => props.show ? 'block' : 'none'};
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${palette.solidWhite};
	width: 100%;
	max-width: 644px;
	z-index: 3;

	@media screen and (max-width: ${px(676)}) {
		width: calc(100% - ${px(32)});
	}
`;
const Inner = styled.div`
	padding: ${px(Spacing.SPACE_32)};
	border-right: 2px solid rgb(216,216,216);
	border-bottom: 2px solid rgb(216,216,216);
	border-left: 2px solid rgb(216,216,216);
`;
const CloseButton = styled.div`
	position: absolute;
	top: ${px(Spacing.SPACE_8)};
	right: ${px(Spacing.SPACE_8)};
	width: ${px(Spacing.SPACE_24)};
	height: ${px(Spacing.SPACE_24)};
	cursor: pointer;
`;
const ImageWrapper = styled.div`
	& img {
		width: 100%;
		display: block;
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 32px;
`;
const buildState = (props, state) => {

	let	linkName = '';
	let	link = '';
	let	image = '';
	let altText = '';
	let date = '';
	let off = false;

	if (props.offline) {
		linkName = props.data.linkName;
		link = props.data.link && mapLinks([{
			url: props.data.link,
			navText: props.data.link
			}], props.offline)[0].url;
		image = props.data.image;
		altText = props.data.altText || '';
		date = props.data.date || '';
		off = props.data.off !== 'false' || false;
	} else {

		try {

			let obj = props.data['Info-popupData']['Info-popup'];
			linkName = obj.LinkName;
			link = obj.Link ? mapLinks([{
				url: obj.Link.link.target,
				navText: obj.LinkName
				}], props.offline)[0].url : '';
			image = obj.Image ? obj.Image.link.target.replace(environment.cmsExportPath, '') : '';
			altText = obj.AltText || '';
			date = obj.Date || '';
			off = obj.Off || false;
		} catch (err) {
			console.log(err);
		}
	}

	return  {
		offline: props.offline,
		pageType: props.pageType,
		linkName: linkName,
		link: link,
		image: image,
		altText: altText,
		show: state && !off ? state.show : false,
		date: date,
		off: off
	};

};

class InfoPopup extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.closePopup = this.closePopup.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('info-popup');
		}

		// read cookie
		let cookies = document.cookie;
		let cookie = '';
		let date = '';

		if (!this.state.off) {

			if (cookies) {
				cookies = cookies.split('; ');
				cookie = cookies.filter(c => c.match(/ml-info/));

				if (cookie[0]) {
					date = cookie[0].split('=')[1];
				}

				if (!cookie[0] || date && this.state.date && parseInt(date) !== this.state.date) {
					document.cookie = "ml-info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // reset cookie
					this.setState({
						offline: this.state.offline,
						pageType: this.state.pageType,
						linkName: this.state.linkName,
						link: this.state.link,
						image: this.state.image,
						altText: this.state.altText,
						show: true,
						date: this.state.date,
						off: this.state.off
					});
				}

			} else {
				this.setState({
					offline: this.state.offline,
					pageType: this.state.pageType,
					linkName: this.state.linkName,
					link: this.state.link,
					image: this.state.image,
					altText: this.state.altText,
					show: true,
					date: this.state.date,
					off: this.state.off
				});
			}

		}

	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	closePopup() {
		document.cookie = `ml-info=${this.state.date}`;
		this.setState({
			offline: this.state.offline,
			pageType: this.state.pageType,
			linkName: this.state.linkName,
			link: this.state.link,
			image: this.state.image,
			altText: this.state.altText,
			date: this.state.date,
			off: this.state.off,
			show: false
		});
	}

	render() {
		return (
			<PopUp show={this.state.show}>
				<Stripe height={StripeHeight.HEIGHT_4} />
				<Inner>
					<CloseButton onClick={this.closePopup}>
						<Icon iconComponent={Close} title="zamknij" />
					</CloseButton>
					{this.state.image ? 
					<ImageWrapper>
						<Image
							src={this.state.image}
							alt={this.state.altText}
							title={this.state.altText}
						/>
					</ImageWrapper> : ''}
					{this.state.link && this.state.linkName ? <ButtonWrapper>
						{generateLink(this.state.link, null, '', (
							<Button
								buttonType="primary"
								onClick={this.closePopup}
							>
								{this.state.linkName}
							</Button>
						), this.state.offline)}
					</ButtonWrapper> : ''}
				</Inner>
			</PopUp>
		);
	}

}

export default InfoPopup;
