/*!
 * mLeasing, slider component :: 26/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {Text} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import palette from '@mbank-design/design-system/palette';
import {px} from '@mbank-design/design-system/utils';

const version = 'v: 1.0.1 :: r. 10/06/2021 @ fs';
const SliderContainer = styled.div`
	position: relative;
	padding-top: ${px(Spacing.SPACE_0)};
	padding-bottom: ${px(Spacing.SPACE_0)};
`;
const ProgressButton = styled.div`
	position: absolute;
	top: ${Spacing.SPACE_0};
	left: -${px(Spacing.SPACE_12)};
	width: ${px(28)};
	height: ${px(28)};
	border-radius: 50%;
	border: 4px solid ${props => props.blocked ? palette.altoGray : palette.endeavourBlueUI};
	background-color: ${palette.solidWhite};
	transform: translate(0, 0);
	/*transition: transform linear .1s;*/
	will-change: transform;
`;
const LabelsContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
const Label = styled.div`
`;
const ProgressContainer = styled.div`
	padding-top: ${px(Spacing.SPACE_12)};
	padding-bottom: ${px(Spacing.SPACE_12)};
`;
const ProgressBar = styled.div`
	position: relative;
	width: 100%;
	height: 4px;
	background-color: #d8d8d8;
	box-shadow: inset 0 1px 2px #e4e4e4;
	overflow: hidden;
`;
const ProgressInner = styled.div`
	width: 100%;
	height: 100%;
	background: #0065b1;
	transform-origin: left;
	position: relative;
	transform: translate(0);
	left: -100%;
	background-color: ${props => props.bgColor};
	/*transition: transform linear .1s;*/
	will-change: transform;
`;

let move = false;

class Slider extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderLabels = this.renderLabels.bind(this);
		this.setMove = this.setMove.bind(this);
		this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
		this.mouseDownHandler = this.mouseDownHandler.bind(this);
		this.mouseUpHandler = this.mouseUpHandler.bind(this);
		this.mouseOutHandler = this.mouseOutHandler.bind(this);
		this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
		this.touchStartHandler = this.touchStartHandler.bind(this);
		this.touchEndHandler = this.touchEndHandler.bind(this);
		this.touchMoveHandler = this.touchMoveHandler.bind(this);
		this.changeValue = this.changeValue.bind(this);

		this.state = {
			max: 100,
			minValue: props.min,
			maxValue: props.max,
			value: props && props.value ? props.value : 0,
			val: 0,
			transform: 0,
			width: 0,
			labels: props.labels || [],
			blocked: props.blocked,
			step: props.step
		};
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('slider');
		}

		const progressBar = window.document.querySelector('.progress-bar');

		this.setState({
			max: this.state.max,
			minValue: this.state.minValue,
			maxValue: this.state.maxValue,
			value: this.state.value,
			val: this.state.val,
			transform: this.state.transform,
			width: progressBar.clientWidth,
			labels: this.state.labels,
			blocked: this.state.blocked,
			step: this.state.step
		});
	}

	static getDerivedStateFromProps(props, state) {
		return {
			max: props.max,
			minValue: props.min,
			maxValue: props.max,
			value: props.value,
			val: state.val,
			transform: props.value - props.min,
			labels: props.labels,
			blocked: props.blocked,
			step: props.step
		};
	}

	setMove(isMoving) {
		move = isMoving;
	}

	mouseMoveHandler(e) {
		e.preventDefault();

		if (e.buttons === 1) {
			this.changeValue(e);
		}

	}

	mouseDownHandler(e) {
		this.setMove(true);
		this.changeValue(e);
	}

	mouseUpHandler(e) {
		this.setMove(false);
	}

	mouseEnterHandler(e) {

		if (e.buttons === 1) {
			this.setMove(true);
		}

	}

	mouseOutHandler(e) {
		e.preventDefault();
		e.stopPropagation();

		let element = e.target.closest('.slider').querySelector('.progress-container')
		const posX = element.getBoundingClientRect().x;
		const posY = element.getBoundingClientRect().y;

		if (e.clientX < posX || e.clientX > posX + element.clientWidth || e.clientY < posY || e.clientY > posY + element.clientHeight) {
			setTimeout(() => {
			this.setMove(false);
			}, 0);
		}

	}

	touchStartHandler(e) {
		this.setMove(true);
		this.changeValue(e);
	}

	touchEndHandler(e) {
		this.setMove(false);
	}

	touchMoveHandler(e) {
		e.preventDefault();
		e.stopPropagation();

		let element = e.target.closest('.slider').querySelector('.progress-container')
		const posX = element.getBoundingClientRect().x;
		const posY = element.getBoundingClientRect().y;

		if (e.clientX < posX || e.clientX > posX + element.clientWidth || e.clientY < posY || e.clientY > posY + element.clientHeight) {
			this.setMove(false);
		} else {
			this.changeValue(e);
		}

	}

	changeValue(e) {

		if (move) {

			let element = e.target.closest('.slider').querySelector('.progress-container')

			if (this.state.minValue < this.state.maxValue) { // only when move is possible

				const width = element.clientWidth;
				const position = element.getBoundingClientRect().x;
				let clickPosition;

				if (e.nativeEvent.type === 'touchmove') {

					if (e.touches[0].clientX > position + width) {
						clickPosition =  position + width;
					} else if (e.touches[0].clientX < position) {
						clickPosition = position;
					} else {
						clickPosition = e.touches[0].clientX;
					}

				} else {
					clickPosition = e.clientX;
				}

				let clickAmplitude = clickPosition - position;
				let value = parseInt(this.state.max * clickAmplitude / width);
				const modulo = value % this.state.step;

				if (modulo) {

					if (modulo <= 2) {
						value = value - modulo;
					} else {
						value = value + (this.state.step - modulo);
					}

				}

				let calculatedValue = parseInt(value * (this.state.maxValue - this.state.minValue) / this.state.max + this.state.minValue);
				const calculatedModulo = calculatedValue % this.state.step;

				if (calculatedModulo) {

					if (calculatedModulo <= 2) {
						calculatedValue = calculatedValue - calculatedModulo;
					} else {
						calculatedValue = calculatedValue + (this.state.step - calculatedModulo);
					}

				}

				// calculate amplitude for steps
				let amplitudeValue = (calculatedValue - this.state.minValue) / (this.state.maxValue - this.state.minValue) * 100;
				let calculatedClickAmplitude = amplitudeValue * width / 100;

				if (calculatedValue <= this.state.maxValue && calculatedValue >= this.state.minValue) {

					if (this.props.slideCallback) {
						this.props.slideCallback(calculatedValue);
					}

					/*
					const progressButton = element.querySelector('.progress-button');
					const progressInner = element.querySelector('.progress-inner');

					progressButton.style.transform = 'translate(' + parseInt(calculatedClickAmplitude) + 'px)';
					progressInner.style.transform = 'translate(' + parseInt(calculatedClickAmplitude) + 'px)';
					*/
				}
			}

		}

	}

	renderLabels() {
		return <LabelsContainer>{
			this.state.labels.map((label, i) => {
				return (
					<Label key={'label-' + i}>
						<Text as="small">{label}%</Text>
					</Label>
				);
			})
		}</LabelsContainer>;
	}

	render() {

		let width = (this.state.value - this.state.minValue) / (this.state.maxValue - this.state.minValue) * this.state.width;

		return (
			<SliderContainer className="slider">
				<ProgressContainer
					className="progress-container"
					onMouseMove={this.mouseMoveHandler}
					onMouseDown={this.mouseDownHandler}
					onTouchStart={this.touchStartHandler}
					onTouchEnd={this.touchEndHandler}
					onTouchMove={this.touchMoveHandler}
				>
					<ProgressBar
						className="progress-bar"
					>
						<ProgressInner
							className="progress-inner"
							bgColor={this.state.blocked ? palette.altoGray : palette.endeavourBlueUI}
							max={this.state.max}
							min={0}
							style={{
								transform: 'translate(' + width + 'px)'
							}}
						/>
					</ProgressBar>
					<ProgressButton
						className="progress-button"
						blocked={this.state.blocked}
						style={{
							transform: 'translate(' + width + 'px)'
						}}
					/>
				</ProgressContainer>
				{this.state.labels.length ? this.renderLabels() : ''}
			</SliderContainer>
		);
	}

}

export default Slider;
