/*!
 * mLeasing, infobox-set portlet :: 04/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import {environment} from '../../../environment.js';
import styled from 'styled-components';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {Carousel} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {eventLogger} from '@mbank-design/design-system/utils';
import Container from '../../components/container.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const BoxWrapper = styled.div`
	display: flex;
	flex-direction: ${props => props.direction ? props.direction : 'row'};
	flex-wrap: nowrap;
	position: relative;
	align-items: ${props => {

		if (props.direction === 'column') {

			if (props.align === 'left') {
				return 'flex-start';
			}

			if (props.align === 'center') {
				return 'center';
			}

			if (props.align === 'right') {
				return 'flex-end';
			}

		} else {
			return 'stretch';
		}

	}};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		align-items: center;
		justify-content: center;
	}
`;
const CustomBox = styled.div`
	position: relative;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: calc(${props => {
		if (props.isProcess) {
			return 100 / props.count;
		} else {
			return !(props.count % 3) || props.count === 5 ? 33.33 : 25;
		}
	}}% - 24px);
	max-width: ${props => props.maxWidth || '33%'};;
	margin-right: ${props => {

		let margin = px(Spacing.SPACE_24);

		if (props.count === 4 && props.index === 3) {
			margin = px(Spacing.SPACE_0);
		}

		if (props.count === 3 && props.index === 2) {
			margin = px(Spacing.SPACE_0);
		}

		if (props.count === 5 && props.index === 4) {
			margin = px(Spacing.SPACE_0);
		}

		if (props.count === 5 && props.index === 2) {
			margin = px(Spacing.SPACE_0);
		}

		if (props.count === 6 && props.index === 2) {
			margin = px(Spacing.SPACE_0);
		}

		if (props.count === 6 && props.index === 5) {
			margin = px(Spacing.SPACE_0);
		}

		if (props.count === 7 && props.index === 3) {
			margin = px(Spacing.SPACE_0);
		}

		if (props.count === 7 && props.index === 6) {
			margin = px(Spacing.SPACE_0);
		}

		return margin;
	}};
	margin-bottom: ${props => {

		let margin = px(Spacing.SPACE_24);

		if (props.count <= 4) {
			margin = px(Spacing.SPACE_0);
		} else {

			if (props.index > 2 && props.count <= 5) {
				margin = px(Spacing.SPACE_0);
			} else {
				if(props.count >= 6 && props.index > 3) {
					margin = px(Spacing.SPACE_0);
				}
			}
		}

		return margin;
	}};
	padding: ${px(Spacing.SPACE_24)} ${props => px(props.padding)};
	background-color: ${props => props.background === 'dark' || props.background === 'light-dark' ? palette.wildSandGray : palette.solidWhite};
	box-shadow: ${props => props.shadow ? `${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08)` : 'none'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-width: unset;
		margin-right: ${px(Spacing.SPACE_0)};
		margin-bottom: ${px(Spacing.SPACE_0)};
	}
`;
const LinkWrapper = styled.div`
	position: absolute;
	bottom: ${px(Spacing.SPACE_16)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		position: inherit;
    	margin-top: ${px(Spacing.SPACE_24)};
	}
`;
const Inner = styled.div`
	max-width: 868px;
	margin: 0 auto;
	text-align: ${props => props.align ? props.align : 'left'};
	margin-top: ${props => props.marginTop || 0};
	padding-bottom: ${props => props.paddingBottom || Spacing.SPACE_0};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${props => props.mobilePadding || 0};
		padding-left: ${props => props.mobilePadding || 0};
		text-align: center;
	}
`;
const InnerMobile = styled(BoxWrapper)`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const InnerDesktop = styled(BoxWrapper)`
	flex-wrap: ${props => props.count > 3 && !(props.count % 3) || props.count > 4 ? 'wrap' : 'nowrap'};
	justify-content: center;
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const TitleFragment = styled.span`
	font-size: ${props => props.isNumber ? px(Spacing.SPACE_40) : px(Spacing.SPACE_20)};
	margin-right: ${px(Spacing.SPACE_8)};
`;
const PhoneNumber = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	text-align: left;
	height: 44px;
	font-size: 24px;
	color: ${palette.endeavourBlueUI};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		justify-content: center;
	}
`;
const ProcessIndicator = styled.div`
	position: absolute;
	top: ${px(Spacing.SPACE_24)};
	right: 0;
	width: calc(100% - ${px(72)});
	border-top: 2px solid ${palette.altoGray};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const TextContainer = styled(Box)`
	text-align: center;
	margin-bottom: ${px(Spacing.SPACE_24)};
	color: ${props => props.background === 'black' ? palette.solidWhite : palette.zeusBlackUI};
`;
const itemLinkStyles = `
	display: block;
	width: 100%;
`;
const buildState = (props) => {

	let title = '';
	let text = '';
	let link = '';
	let linkName = '';
	let background = '';
	let items = [];
	let isProcess = false;
	let shadowTiles = false;
	let bottomText = '';
	let bigTiles = false;

	if (props.offline) {
		title = props.data.title;
		text = props.data.text;
		bottomText = props.data.bottomText;
		link = props.data.link;
		linkName = props.data.linkName;
		background = props.data.background;
		isProcess = props.data.isProcess === 'true' ? true : false;
		shadowTiles = props.data.shadowTiles === 'true' ? true : false;
		bigTiles = props.data.bigTiles === 'true' ? true : false;
		items = props.data.items ? props.data.items.map(item => ({
			title: item.title,
			icon: item.icon,
			altText: item.altText || '',
			iconTitleRow: item.iconTitleRow === 'true' ? true : false,
			align: item.align,
			text: item.text,
			link: item.link,
			linkName: item.linkName,
			linkIsButton: item.linkIsButton === 'true' ? true : false,
			phoneNumber: item.phoneNumber
		})) : [];
	} else {

		try {

			let obj = props.data['Infobox-setData']['Infobox-set'];
			let linkUrlQuery = obj.Link.link.query ? ('?' + obj.Link.link.query) : '';

			title = obj.Title;
			text = obj.Text;
			bottomText = obj.BottomText;
			link = (obj.Link ? obj.Link.link.target.replace(environment.cmsExportPath, '') : '') + linkUrlQuery;
			linkName = obj.LinkName;
			background = obj.Background;
			isProcess = obj.IsProcess;
			shadowTiles = obj.ShadowTiles;
			bigTiles = obj.BigTiles;
			items = obj.Item ? obj.Item.map(item => {

				let textLinks = [];

				if (item.Text && Array.isArray(item.Text.links.link)) {

					textLinks = item.Text.links.link.map(link => {
						return {
							name: link.name,
							target: link.target || link.anchor
						};
					});

				} else if (item.Text && item.Text.links !== '') {

					textLinks = [{
						name: item.Text.links.link.name,
						target: item.Text.links.link.target || item.Text.links.link.anchor
					}];

				}

				return {
					title: item.Title,
					text: replaceCmsContentLinks(item.Text ? item.Text.content : '', textLinks),
					link: item.Link ? item.Link.link.target.replace(environment.cmsExportPath, '') : '',
					linkName: item.LinkName,
					linkIsButton: item.LinkIsButton,
					phoneNumber: item.PhoneNumber,
					iconTitleRow: item.IconTitleRow,
					align: item.Align,
					icon: item.Icon ? item.Icon.link.target.replace(environment.cmsExportPath, '') : '',
					altText: item.AltText || ''
				};
			}) : [];
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		text: text,
		bottomText: bottomText,
		link: link,
		linkName: linkName,
		background: background,
		isProcess: isProcess,
		shadowTiles: shadowTiles,
		bigTiles: bigTiles,
		items: items
	};
}

class InfoboxSet extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderBoxes = this.renderBoxes.bind(this);
		this.renderBoxHeader = this.renderBoxHeader.bind(this);
		this.highlightNumbers = this.highlightNumbers.bind(this);
		this.renderItemLink = this.renderItemLink.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('infobox-set');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	highlightNumbers(str) {

		const arr = str.split(' ');

		return (
			<>
				{arr.map((item, i) => <TitleFragment
					isNumber={parseInt(item)}
					key={'title-fragment-' + i}
				>{item}</TitleFragment>)}
			</>
		);
	}

	renderBoxHeader(item, index, isProcess) {
		return (
			<BoxWrapper direction={item.iconTitleRow ? 'row' : 'column'} align={item.align}>
				{item.icon ? <Image
					src={item.icon}
					title={item.title}
					alt={item.altText}
					maxWidth={Spacing.SPACE_48}
					maxHeight={Spacing.SPACE_48}
					marginTop={px(Spacing.SPACE_8)}
				/> : ''}
				<Box
					marginLeft={item.iconTitleRow ? Spacing.SPACE_24 : Spacing.SPACE_0}
					marginTop={item.iconTitleRow || !item.icon ? Spacing.SPACE_0 : Spacing.SPACE_24}
				>
					<Text
						as="h3"
						align={['center', 'left']}
						marginTop={!item.icon ? Spacing.SPACE_0 : Spacing.SPACE_16}
						marginBottom={Spacing.SPACE_16}
					>
						{item.iconTitleRow ? this.highlightNumbers(item.title) : item.title}
					</Text>
				</Box>
				{isProcess && index < this.state.items.length - 1 ? <ProcessIndicator /> : ''}
			</BoxWrapper>
		);
	}

	renderItemLink(item) {

		if (item.link) {

			if (item.linkIsButton) {
				return (
					<LinkWrapper>
						{generateLink(this.props.prefix + item.link, '', itemLinkStyles, (
							<Button buttonType='primary' isFullWidth={true} onClick={eventLogger.click}>{item.linkName}</Button>
						), this.state.offline)}
					</LinkWrapper>
				);
			} else {
				return (
					<LinkWrapper>
						{generateLink(this.props.prefix + item.link, item.linkName, itemLinkStyles, '', this.state.offline)}
					</LinkWrapper>
				);
			}
		} else {
			return '';
		}

	}

	renderBoxes(items) {
		return items.map((item, i) => {
			// Tile component should be used, but it is impossible because it uses react-router-dom link

			let phoneNumberRow = '';
			let linkRow = '';

			if (item.phoneNumber) {
				// const replacedPhone = item.phoneNumber ? item.phoneNumber.replaceAll(" ", "").replaceAll("\u00a0", "") : " " // replace space
				phoneNumberRow = (
					<Inner>
						{item.phoneNumber ? <PhoneNumber><a href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a></PhoneNumber> : ''}
					</Inner>
				);
			}

			if (item.link) {
				linkRow = (
					<Inner paddingBottom={item.link && item.linkIsButton ? px(Spacing.SPACE_44) : px(Spacing.SPACE_24)}>
						{this.renderItemLink(item)}
					</Inner>
				);
			}

			let padding = 0;

			if (this.state.background === 'dark' || this.state.background === 'light' || this.state.background === '') {
				padding = Spacing.SPACE_0;
			} else {

				if (this.state.bigTiles) {
					padding = Spacing.SPACE_32;
				} else {
					padding = Spacing.SPACE_24;
				}
			}

			return (
				<CustomBox
					key={'infobox-' + i}
					count={items.length}
					index={i}
					isProcess={this.state.isProcess}
					background={this.state.background}
					shadow={this.state.shadowTiles}
					padding={padding}
					maxWidth={this.state.bigTiles ? '533px' : '33%'}
				>
					<Inner>{this.renderBoxHeader(item, i, this.state.isProcess)}</Inner>
					<Inner align={item.align}>
						<Box>{componentsFromHtml(item.text, this.state.offline)}</Box>
					</Inner>
					{phoneNumberRow}
					{linkRow}
				</CustomBox>
			);
		});
	}

	render() {

		const titleColor = this.state.background === 'black' ? 'solidWhite' : 'zeusBlackUI';
		const title = this.state.title ? <Text
			as="h2"
			align="center"
			marginBottom={Spacing.SPACE_24}
			color={titleColor}
		>
			{this.state.title}
		</Text> : '';
		const text = this.state.text ? <TextContainer
			as="div"
			background={this.state.background}
		>
			{componentsFromHtml(this.state.text, this.state.offline)}
		</TextContainer> : '';
		const bottomText = this.state.bottomText ? <TextContainer
			as="div"
			background={this.state.background}
			style={{ marginTop: px(Spacing.SPACE_24) }}
		>
			{componentsFromHtml(this.state.bottomText, this.state.offline)}
		</TextContainer> : '';
		const link = this.state.link ? generateLink(this.props.prefix + this.state.link, '', '', (
			<Button buttonType='primary' onClick={eventLogger.click}>{this.state.linkName}</Button>
		), this.state.offline) : '';

		return (
			<Container>
				<Inner mobilePadding={px(Spacing.SPACE_16)}>
					{title}
					{text}
				</Inner>
				<InnerDesktop as="div" className="inner desktop" count={this.state.items.length}>
					{this.renderBoxes(this.state.items)}
				</InnerDesktop>
				<InnerMobile as="div" className="inner mobile">
					<Carousel>
						{this.renderBoxes(this.state.items)}
					</Carousel>
				</InnerMobile>
				<Inner
					align="center"
					marginTop={this.state.link ? px(Spacing.SPACE_24) : px(Spacing.SPACE_0)} mobilePadding={px(Spacing.SPACE_16)}
				>
					{bottomText}
				</Inner>
				<Inner
					align="center"
					marginTop={this.state.link ? px(Spacing.SPACE_24) : px(Spacing.SPACE_0)} mobilePadding={px(Spacing.SPACE_16)}
				>
					{link}
				</Inner>
			</Container>
		);
	}

}

export default InfoboxSet;
