/*!
 * mLeasing, image-text portlet :: 16/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import { environment } from '../../../environment.js';
import { Button } from '@mbank-design/design-system/components';
import { Text } from '@mbank-design/design-system/components';
import { Box } from '@mbank-design/design-system/components';
import { Image } from '@mbank-design/design-system/components';
import { Spacing } from '@mbank-design/design-system/enums';
import { ButtonColor } from '@mbank-design/design-system/enums';
import { px } from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import { mapLinks } from '../../utils/map-links.js';
import Container from '../../components/container.js';
import { replaceCmsContentLinks } from '../../utils/replace-cms-content-links.js';
import { componentsFromHtml } from '../../utils/components-from-html.js';
import { generateLink } from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const FlexContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;
	padding-top: ${props => px(props.paddingTop !== undefined || Spacing.SPACE_28)};
	padding-bottom: ${props => px(props.paddingBottom !== undefined || Spacing.SPACE_28)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: ${props => props.mobileDirection || 'row'};
	}
`;
const Column = styled.div`
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	order: ${props => props.order || 0};
	padding-right: ${props => px(props.paddingRight) || 0};
	padding-left: ${props => px(props.paddingLeft) || 0};
`;
const TextColumn = styled(Column)`
	flex-basis: 55%;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-left: ${px(Spacing.SPACE_16)};
		padding-right: ${px(Spacing.SPACE_16)};
		order: 1;
	}
`;
const ImageColumn = styled(Column)`
	flex-basis: 45%;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_0)};
		padding-left: ${px(Spacing.SPACE_0)};
		margin-bottom: ${px(Spacing.SPACE_24)};
		order: 0;
	}
`;
const Inner = styled.div`
	max-width: ${px(868)};
	margin: 0 auto;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${props => props.mobilePadding || px(Spacing.SPACE_0)};
		padding-left: ${props => props.mobilePadding || px(Spacing.SPACE_0)};
	}
`;
const List = styled.ul`
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	padding-left: 36px;
	list-style: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-top: ${px(Spacing.SPACE_0)};
		margin-bottom: ${px(Spacing.SPACE_0)};
	}
`;
const ListItem = styled.li`
	position: relative;
	margin-bottom: ${px(Spacing.SPACE_16)};
	&::before {
		content: '';
		position: absolute;
		left: -24px;
		top: 8px;
		display: block;
		width: 8px;
		height: 8px;
		background-color: ${palette.endeavourBlueUI};
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-bottom: ${px(Spacing.SPACE_12)};
	}
`;
const GroupContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
const BottomText = styled.div`
	margin-top: ${px(Spacing.SPACE_24)};
	text-align: center;
`;
const BottomLinkContainer = styled.div`
	margin-top: ${px(Spacing.SPACE_24)};
	text-align: center;
`;
const buildState = (props) => {

	let title = '';
	let subTitle = '';
	let items = [];
	let imageFirst = true;
	let bottomText = '';
	let link = '';
	let linkName = '';

	if (props.offline) {
		title = props.data.title;
		subTitle = props.data.subTitle;
		bottomText = props.data.bottomText;
		linkName = props.data.linkName || '';
		link = props.data.link ? mapLinks([{
			url: props.data.link,
			navText: props.data.linkName || '',
		}], props.offline)[0].url : '';
		imageFirst = props.data.imageFirst === 'true' ? true : false;
		items = props.data.items ? props.data.items.map(item => {
			return {
				title: item.title || '',
				text: item.text || '',
				image: item.image || '',
				altText: item.altText || '',
				groups: item.groups ? item.groups.map(group => {
					return {
						text: group.text || '',
						lists: group.lists ? group.lists : [],
					};
				}) : [],
				columnGroups: item.columnGroups === 'true' ? true : false,
				linkName: item.linkName || '',
				link: item.link ? mapLinks([{
					url: item.link,
					navText: item.linkName,
				}], props.offline)[0].url : '',
				textLinkName: item.textLinkName || '',
				textLink: item.textLink ? mapLinks([{
					url: item.textLink,
					navText: item.textLinkName,
				}], props.offline)[0].url : ''
			};
		}) : [];
	} else {

		try {

			let obj = props.data['Image-textData']['Image-text'];
			let bottomTextLinks = [];

			title = obj.Title;
			subTitle = obj.SubTitle;
			imageFirst = obj.ImageFirst;
			link = obj.Link ? mapLinks([{
				url: obj.Link.link.target,
				navText: obj.LinkName || '',
			}], props.offline)[0].url : '';
			linkName = obj.LinkName || '';

			if (obj.BottomText) {

				if (Array.isArray(obj.BottomText.links.link)) {

					bottomTextLinks = obj.BottomText.links.link.map(link => {
						return {
							name: link.name,
							target: link.target || link.anchor
						};
					});

				} else if (obj.BottomText.links !== '') {

					bottomTextLinks = [{
						name: obj.BottomText.links.link.name,
						target: obj.BottomText.links.link.target || obj.BottomText.links.link.anchor
					}];

				}

			}

			bottomText = obj.BottomText ? replaceCmsContentLinks(obj.BottomText.content, bottomTextLinks) : ''

			if (obj.Item) {

				if (Array.isArray(obj.Item)) {
					items = obj.Item.length ? obj.Item : [];
				} else {
					items = [obj.Item];
				}
			}

			items = items.map(item => {

				let groups = [];

				if (Array.isArray(item.Group)) {
					groups = item.Group;
				} else {
					groups = item.Group ? [item.Group] : [];
				}

				groups = groups.map(group => {

					let textLinks = [];

					if (group.Text) {

						if (Array.isArray(group.Text.links.link)) {

							textLinks = group.Text.links.link.map(link => {
								return {
									name: link.name,
									target: link.target || link.anchor
								};
							});

						} else if (group.Text.links !== '') {

							textLinks = [{
								name: group.Text.links.link.name,
								target: group.Text.links.link.target || group.Text.links.link.anchor
							}];

						}

					}

					let lists = [];

					if (group.List) {

						if (Array.isArray(group.List)) {
							lists = group.List;
						} else {
							lists = [group.List];
						}

					}

					return {
						text: group.Text ? replaceCmsContentLinks(group.Text.content, textLinks) : '',
						lists: lists.map(list => {

							let arr = [];

							if (list.Item) {

								if (Array.isArray(list.Item)) {
									arr = list.Item;
								} else {
									arr = [list.Item];
								}

							}

							return arr.map(item => item.Text);
						})
					};
				});
				let linkUrlQuery = item.Link ? (item.Link.link.query ? ('?' + item.Link.link.query) : '') : '';
				return {
					title: item.Title || '',
					image: item.Image ? item.Image.link.target.replace(environment.cmsExportPath, '') : '',
					altText: item.AltText || '',
					groups: groups,
					columnGroups: item.ColumnGroups,
					linkName: item.LinkName || '',
					link: item.Link ? mapLinks([{
						url: ((item.Link ? item.Link.link.target : '') + linkUrlQuery),
						navText: item.LinkName,
					}], props.offline)[0].url : '',
					textLinkName: item.TextLinkName || '',
					textLink: item.TextLink ? mapLinks([{
						url: item.TextLink ? item.TextLink.link.target : '',
						navText: item.TextLinkName,
					}], props.offline)[0].url : ''
				};
			});
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		subTitle: subTitle,
		imageFirst: imageFirst,
		link: link,
		linkName: linkName,
		bottomText: bottomText,
		items: items
	};

};

class ImageText extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderTextElement = this.renderTextElement.bind(this);
		this.renderRows = this.renderRows.bind(this);
		this.renderGroups = this.renderGroups.bind(this);
		this.renderLists = this.renderLists.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('image-text');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	renderTextElement(text, type) {

		if (text) {

			let marginTop = type === 'p' ? Spacing.SPACE_16 : Spacing.SPACE_0;
			let marginBottom = Spacing.SPACE_16;

			return <Text as={type || 'p'} marginTop={marginTop} marginBottom={marginBottom} align="center">{text}</Text>;
		} else {
			return '';
		}

	}

	renderLists(lists) {

		if (lists && lists.length) {
			return (
				<FlexContainer paddingBottom={0} paddingTop={0} mobileDirection="column">
					{lists.map((list, i) => {
						return (
							<List key={'list-' + i}>{list.map((item, j) => {
								return <ListItem key={'li-' + j}>{item}</ListItem>;
							})}</List>
						);
					})}
				</FlexContainer>
			);

		}
	}

	renderGroups(groups, columnGroups) {
		return groups.map((group, i) => {
			return (
				<Box key={'item-group-' + i} style={columnGroups ? { flexBasis: '50%' } : {}}>
					<Box
						marginTop={Spacing.SPACE_16}
						marginBottom={Spacing.SPACE_16}
					>
						{componentsFromHtml(group.text, this.state.offline)}
					</Box>
					{this.renderLists(group.lists)}
				</Box>
			);
		});
	}

	renderRows(items, imageFirst) {

		if (items && items.length) {
			return items.map((item, i) => {

				let imageOrder = imageFirst && i === 0 || imageFirst && !(i % 2) ? 0 : 1;
				let textOrder = imageFirst && i === 0 || imageFirst && !(i % 2) ? 1 : 0;

				return (
					<FlexContainer key={'item-' + i} paddingBottom={i < items.length - 1 ? 28 : 0} mobileDirection="column">
						<ImageColumn
							order={imageOrder}
							paddingRight={imageOrder === 0 ? 68 : 0}
							paddingLeft={imageOrder === 0 ? 0 : 68}
						>
							<Image
								src={item.image}
								title={item.title}
								alt={item.altText}
								width="100%"
							/>
						</ImageColumn>
						<TextColumn
							order={textOrder}
							paddingRight={textOrder === 0 ? 68 : 0}
							paddingLeft={textOrder === 0 ? 0 : 68}
						>
							<Text
								as="h3"
								marginBottom={Spacing.SPACE_16}
							>
								{item.title}
							</Text>
							{item.columnGroups ? (<GroupContainer>
								{this.renderGroups(item.groups, item.columnGroups)}
							</GroupContainer>) : this.renderGroups(item.groups, item.columnGroups)}
							<Box
								margin={'0 auto'}>
								{item.link ? generateLink(item.link, '', '', (
									<Button buttonType="primary">{item.linkName}</Button>
								), this.state.offline) : ''}
								{item.textLink ? generateLink(item.textLink, '', `margin-left: ${px(Spacing.SPACE_16)};`, (
									<Button buttonType="tertiary" color={ButtonColor.BLUE}>{item.textLinkName}</Button>
								), this.state.offline) : ''}
							</Box>
						</TextColumn>
					</FlexContainer>
				);
			});
		}

	}

	render() {
		return (
			<Container>
				<Inner mobilePadding={px(Spacing.SPACE_16)}>
					{this.renderTextElement(this.state.title, 'h2')}
					{this.renderTextElement(this.state.subTitle, 'p')}
				</Inner>
				{this.renderRows(this.state.items, this.state.imageFirst)}
				{this.state.bottomText ? <BottomText>{componentsFromHtml(this.state.bottomText, this.state.offline)}</BottomText> : ''}
				{this.state.link ? <BottomLinkContainer>{generateLink(this.state.link, '', '', (
					<Button buttonType="primary">{this.state.linkName}</Button>
				), this.state.offline)}</BottomLinkContainer> : ''}
			</Container>
		);
	}

}

export default ImageText;
