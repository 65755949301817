/*!
 * mLeasing, mapLinks utility :: 05/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

const version = 'v: 1.0.0 :: r. 19/02/2021 @ fs';

export const calculateSiteHeight = () => {

	let height = 0;

	if (typeof window !== undefined) {

		const header = window.document.querySelector('#header');
		const main = window.document.querySelector('#main');
		const footer = window.document.querySelector('#footer');

		try {
			height = header.clientHeight + main.clientHeight + footer.clientHeight;
		} catch (err) {
			console.log(err);
		}
	}

	return height;
};
