/*!
 * mLeasing, map popup component :: 12/04/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {Text} from '@mbank-design/design-system/components';
import {Stripe} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {Close} from '@mbank-design/design-system/icons';
import {StripeHeight} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';

const version = 'v: 1.0.0 :: r. 29/04/2021 @ fs';
const PopUp = styled.div`
	display: ${props => props.show ? 'block' : 'none'};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${palette.solidWhite};
`;
const Inner = styled.div`
	padding: ${px(Spacing.SPACE_32)};
`;
const TitleContainer = styled.div`
	text-align: center;
`;
const InfoContainer = styled.div`
	text-align: center;
`;
const ItemContainer = styled.div`
	padding-top: ${px(Spacing.SPACE_16)};
	padding-bottom: ${px(Spacing.SPACE_16)};
`;
const DataContainer = styled.div`
	display: flex;
	padding-top: ${px(Spacing.SPACE_16)};
	padding-bottom: ${px(Spacing.SPACE_16)};
`;
const DataBox = styled.div`
	flex-basis: 50%;
	text-align: center;
`;
const CloseButton = styled.div`
	position: absolute;
	top: ${px(Spacing.SPACE_8)};
	right: ${px(Spacing.SPACE_8)};
	width: ${px(Spacing.SPACE_24)};
	height: ${px(Spacing.SPACE_24)};
	cursor: pointer;
`;

class MapPopup extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.closePopup = this.closePopup.bind(this);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('map popup');
		}
	}

	closePopup() {
		this.props.callback();
	}

	render() {
		return (
			<PopUp show={this.props.show}>
				<Stripe height={StripeHeight.HEIGHT_4} />
				<Inner>
					<CloseButton onClick={this.closePopup}>
						<Icon iconComponent={Close} title="zamknij" />
					</CloseButton>
					<TitleContainer>
						<Text as="h3">Twoje opony są zdeponowane w:</Text>
					</TitleContainer>
					<InfoContainer>
						{this.props.info && this.props.info.length ? this.props.info.map((item, i) => {

							let arr = [];

							if (item.match(/ul.\s/)) {
								arr = item.split('ul.')
								arr[1] = 'ul. ' + arr[1];
							} else if (item.match(/UL.\s/)) {
								arr = item.split('UL.')
								arr[1] = 'UL. ' + arr[1];
							} else if (item.match(/AL.\s/)) {
								arr = item.split('AL.')
								arr[1] = 'AL. ' + arr[1];
							} else if (item.match(/al.\s/)) {
								arr = item.split('al.')
								arr[1] = 'al. ' + arr[1];
							} else if (item.match(/PL.\s/)) {
								arr = item.split('PL.')
								arr[1] = 'PL. ' + arr[1];
							} else if (item.match(/pl.\s/)) {
								arr = item.split('pl.')
								arr[1] = 'pl. ' + arr[1];
							} else {
								arr = [item];
							}

							return (
								<ItemContainer key={'item-' + i}>
									<Text as="p">{arr[0]}</Text>
									{arr.length > 1 && arr[1] ? <Text as="p">{arr[1]}</Text> : ''}
								</ItemContainer>
							);
						}) : ''}
					</InfoContainer>
					<TitleContainer>
						<Text as="h4">Wyszukiwania dokonano w oparciu o dane:</Text>
					</TitleContainer>
					<DataContainer>
						<DataBox>
							<Text>Numer rejestracyjny</Text>
							<Text>{this.props.registrationNumber}</Text>
						</DataBox>
						<DataBox>
							<Text>5 ostatnich cyfr numeru VIN</Text>
							<Text>{this.props.vin}</Text>
						</DataBox>
					</DataContainer>
				</Inner>
			</PopUp>
		);
	}

}

export default MapPopup;
