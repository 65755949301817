/*!
 * mLeasing, form-box portlet :: 23/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';
import {Box} from '@mbank-design/design-system/components';
import {Text} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {ArrowLeft} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import Container from '../../components/container.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const Wrapper = styled.div`
	background-color: ${props => props.variant === '2' ? palette.wildSandGray : palette.solidWhite};
`;
const FormContainer = styled.div`
`;
let FormComponent = styled.div`
`;
const Inner = styled.div`
	max-width: ${px(644)};
	margin: 0 auto;
	padding-bottom: ${px(Spacing.SPACE_16)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const BackLink = styled.div`
	color: ${props => props.variant === '2' ? palette.zeusBlackUI : palette.endeavourBlueUI};
`;
const IconContainer = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: ${px(Spacing.SPACE_16)};
`;
const linkStyles = `
	color: ${palette.zeusBlackUI};
`;
const buildState = (props) => {

	let	title = '';
	let text = '';
	let form = '';

	if (props.offline) {
		title = props.data.title;
		text = props.data.text;
		form = props.data.form;
	} else {

		try {

			let obj = props.data['Form-boxData']['Form-box'];

			title = obj.Title;
			text = obj.Text;
			form = props.data.Form;
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		text: text,
		form: form
	};

};

class FormBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderForm = this.renderForm.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('form-box');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	renderForm() {

		// can't use variable, webpack needs path beforehand
		if (this.state.form === '1') {
			FormComponent = loadable(() => import('../../includes/forms/contact.js'));
		}

		if (this.state.form === '2') {
			FormComponent = loadable(() => import('../../includes/forms/financing.js'));
		}

		return <FormComponent />;
	}

	render() {

		return (
			<Wrapper variant={this.state.form}>
				<Container>
					<Inner>
						<BackLink variant={this.state.form}>{generateLink('/', null, this.state.form === '2' ? linkStyles : '', (
							<IconContainer>
								<Icon
									iconComponent={ArrowLeft}
									primaryColor={this.state.form === '2' ? IconColor.BLACK : IconColor.BLUE}
									title="ikona"
									marginTop={Spacing.SPACE_8} 
								/>
								<Text as="small" marginLeft={Spacing.SPACE_8}>
									powrót
								</Text>
							</IconContainer>
						), this.state.offline)}</BackLink>
						{this.state.title ? <Box marginBottom={Spacing.SPACE_16}>
							<Text as="h1">{this.state.title}</Text>
						</Box> : ''}
						{this.state.text ? <Box marginBottom={Spacing.SPACE_16}>
							<Text as={this.state.form === '2' ? 'h4' : 'p'}>{this.state.text}</Text>
						</Box> : ''}
					</Inner>
				</Container>
				<FormContainer>
					{this.renderForm()}
				</FormContainer>
			</Wrapper>
		);
	}

}

export default FormBox;
