/*!
 * mLeasing, tabs portlet :: 03/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Tabs as ContentTabs} from '@mbank-design/design-system/components';
import {IconColor} from '@mbank-design/design-system/enums';
import {Icon} from '@mbank-design/design-system/components';
import {Paper} from '@mbank-design/design-system/icons';
import {Collapsible} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import Container from '../../components/container.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const TabContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: ${px(Spacing.SPACE_32)};
	padding-bottom: ${px(Spacing.SPACE_16)};
`;
const DownloadItem = styled.div`
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	margin-bottom: ${px(Spacing.SPACE_12)};
	cursor: pointer;
`;
const downloadLinkStyles = `
	margin-left: ${px(Spacing.SPACE_8)};
`;
const parseFileDownloads = fileDownloads => {

	let arr = [];

	if (Array.isArray(fileDownloads)) {
		arr = fileDownloads.map(file => {
			return {
				downloadText: file.DownloadText,
				downloadFile: file.DownloadFile ? file.DownloadFile.link.target
					.replace(environment.cmsExportPath, '') : ''
			};
		})
	} else {
		arr = [{
			downloadText: fileDownloads.DownloadText,
			downloadFile: fileDownloads.DownloadFile ? fileDownloads.DownloadFile.link.target
				.replace(environment.cmsExportPath, '') : ''
		}];
	}

	return arr;
};
const parseGroups = groups => {
	
	let arr = [];

	if (Array.isArray(groups)) {
		arr = groups.map(group => {
			return {
				title: group.Title,
				fileDownloads: parseFileDownloads(group.FileDownload)
			};
		})
	} else {
		arr = [{
			title: groups.Title,
			fileDownloads: parseFileDownloads(groups.FileDownload)
		}];
	}

	return arr;
};
const buildState = (props) => {

	let title = '';
	let items = [];

	if (props.offline) {
		title = props.data.title;
		items = props.data && props.data.items.map((item, i) => {
			return {
				title: item.title,
				active: i === 0 ? true : false,
				fileDownloads: item.fileDownloads ? item.fileDownloads.map(file => {
					return {
						downloadText: file.downloadText,
						downloadFile: file.downloadFile
					};
				}) : [],
				sections: item.sections ? item.sections : []
			};
		});
	} else {

		try {

			let obj = props.data['TabsData']['Tabs'];

			title = obj.Title;

			if (obj.Item && Array.isArray(obj.Item)) {
				items = obj.Item.map((item, i) => {

					let fileDownloads = [];

					if (item.FileDownload) {
						fileDownloads = parseFileDownloads(item.FileDownload)
					}

					let sections = [];

					if (item.Section) {
						
						if (Array.isArray(item.Section)) {
							sections = item.Section.map(section => {
								return {
									title: section.Title,
									groups: parseGroups(section.Group || [])
								};
							});
						} else {
							sections = [{
								title: item.Section.Title,
								groups: parseGroups(item.Section.Group || [])
							}];
						}

					}

					return { // items 
						title: item.Title,
						active: i === 0 ? true : false,
						fileDownloads: fileDownloads,
						sections: sections
					};
				});
			} else {

				let fileDownloads = [];

				if (obj.Item.FileDownload) {
					fileDownloads = parseFileDownloads(obj.Item.FileDownload)
				}

				let sections = [];

				if (obj.Item.Section) {
					
					if (Array.isArray(obj.Item.Section)) {
						sections = obj.Item.Section.map(section => {
							return {
								title: section.Title,
								groups: parseGroups(section.Group || [])
							};
						});
					} else {
						sections = [{
							title: obj.Item.Section.Title,
							groups: parseGroups(obj.Item.Section.Group || [])
						}];
					}

				}

				items = [{
					title: obj.Item.Title,
					active: true,
					fileDownloads: fileDownloads,
					sections: sections
				}]
			}

		} catch (err) {
			console.log(err);
		}

	}

	return {
		offline: props.offline,
		title: title,
		items: items
	};

};

class Tabs extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderTabs = this.renderTabs.bind(this);
		this.renderGroups = this.renderGroups.bind(this);
		this.renderSections = this.renderSections.bind(this);
		this.activateItem = this.activateItem.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('tabs');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	activateItem(index) {
		this.setState({
			title: this.state.title,
			items: this.state.items.map((item, i) => {
				return {
					title: item.title,
					active: i === index && !item.active ? true : false,
					icon: item.icon,
					activeIcon: item.activeIcon,
					fileDownloads: item.fileDownloads
				};
			})
		});
	}

	renderFileDownloads(fileDownloads) {
		return fileDownloads.map((file, i) => {
			return (
				<DownloadItem key={'file-' + i}>
					<Icon iconComponent={Paper}  primaryColor={IconColor.BLUE} size={24} />
					{generateLink(file.downloadFile, file.downloadText, downloadLinkStyles, '', this.state.offline)}
				</DownloadItem>
			);
		});
	}

	renderGroups(groups) {
		
		if (groups && groups.length) {
			return groups.map((group, i) => {
				return (
					<React.Fragment key={'group-' + i}>
						<Box as="div" paddingTop={i === 0 ? Spacing.SPACE_32 : Spacing.Space_0}>
							<Text as="h4">{group.title}</Text>
						</Box>
						<TabContent>
							{this.renderFileDownloads(group.fileDownloads)}
						</TabContent>
					</React.Fragment>
				);
			});
		} else {
			return '';
		}
	}

	renderSections(sections) {

		if (sections) {
			return sections.map((section, i) => {
				if (section.groups && section.groups.length) {
					return (
						 <Collapsible key={'section-' + i} isDefaultExpanded={false}>
							<Collapsible.Header>
								<Text
									as="h4"
									style={{
										color: palette.zeusBlackUI,
										marginTop: px(Spacing.SPACE_16),
										marginBottom: px(Spacing.SPACE_16)
									}}
								>
									{section.title}
								</Text>
							</Collapsible.Header>
							<Collapsible.Content>
								{this.renderGroups(section.groups)}
							</Collapsible.Content>
						</Collapsible>
					);
				} else {
					return '';
				}
			});
		} else {
			return '';
		}

	}

	renderTabs(items) {
		if (items) {
			return (
				<ContentTabs isExpandable={true} isFullWidth={true} defaultOpenTabTitle={items[0].title}>
					{items.map((item, i) => {
						return (
							<ContentTabs.Tab title={item.title} key={'tab-' + i} style={{justifyContent: 'center'}}>
								<TabContent>
									{this.renderFileDownloads(item.fileDownloads)}
								</TabContent>
								{this.renderSections(item.sections)}
							</ContentTabs.Tab>
						);
					})}
				</ContentTabs>
			);
		} else {
			return '';
		}
	}

	render() {
		return (
			<Container>
				<Text as="h2" align="center" marginBottom={Spacing.SPACE_12}>{this.state.title}</Text>
				{this.renderTabs(this.state.items)}
			</Container>
		);
	}

}

export default Tabs;
