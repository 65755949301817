/*!
 * mLeasing, mapLinks utility :: 15/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */
import {environment} from '../../environment.js';

const version = 'v: 1.0.0 :: r. 02/04/2021 @ fs';

export const mapLinks = (links, offline) => {
	return links.map(link => {

		let url = link.url;

		// get rid of .html and index for online react router
		if (!offline) {
			url = url
				.replace('.html', '/')
				.replace('index/', '')
				.replace(environment.cmsExportPath, '')
				.replace('/export', '');
		} else {

			let hash = url.match(/#.*$/);

			if (hash) {
				url = url.replace(hash[0], '');
			}

			if (hash) {
				url += hash[0];
			} else {
				url += '?__disableDirectEdit=false'; // enable cms edit mode
			}
		}

		let type = 'link';

		if (url.match(/^mailto/)) {
			type = 'email';
		}

		let obj = {
			url: url,
			type: type
		};

		// copy rest of link properties
		Object.keys(link).forEach((key) => {

			if (key !== 'url') {
				obj[key] = link[key];
			}

		});

		// add nodes if they exist
		if (link.nodes) {
			obj.nodes = mapLinks(link.nodes, offline);
		}

		return obj;
	});
};

