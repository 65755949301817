/*!
 * mLeasing, top carousel :: 01/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Button} from '@mbank-design/design-system/components';
import {eventLogger} from '@mbank-design/design-system/utils';
import {Text} from '@mbank-design/design-system/components';
import {Chip} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import Container from '../../components/container.js';
import {Carousel} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import {Icon} from '@mbank-design/design-system/components';
import {IconColor} from '@mbank-design/design-system/enums';
import {Tick} from '@mbank-design/design-system/icons';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {mapLinks} from '../../utils/map-links.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links';
import {componentsFromHtml} from '../../utils/components-from-html';
import {generateLink} from '../../utils/generate-link';
import './top-carousel.css';

const version = 'v: 1.0.1 :: r. 13/05/2021 @ fs';
const FlexContainer = styled(Box)`
	display: flex;
	flex-wrap: nowrap;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
	}
`;
const DarkFlexContainer = styled(FlexContainer)`
	background-color: ${palette.zeusBlackUI};
	justify-content: space-between;
	height: 100%;
	padding: ${px(Spacing.SPACE_20)} ${px(Spacing.SPACE_32)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: row;
		padding: ${px(Spacing.SPACE_20)} ${px(Spacing.SPACE_16)};
	}
`;
const EqualHightBox = styled(Box)`
	height: 100%;
	overflow: hidden;
`;
const Outer = styled.div` 
	padding: ${px(Spacing.SPACE_32)};
	background-repeat: no-repeat;
	background-size: cover;
	height: ${px(420)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding: ${px(Spacing.SPACE_16)} ${px(Spacing.SPACE_16)} ${px(Spacing.SPACE_24)} ${px(Spacing.SPACE_16)};
		height: 460px;
	}
`;
const SlideOuter = styled(Outer)`
	position: relative;
	overflow: hidden;
`;

const CarouselImage = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	min-height: 420px;
	min-width: 100%;
	width: auto;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: auto;
		height: 100%;
	};

	@media screen and (max-width: ${px(460)}) {
		display: ${props => props.className === 'with-mobile' ? `none` : `block`};
	};
`;

const CarouselImageMobile = styled.img`
	display: none;

	@media screen and (max-width: ${px(460)}) {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 0;
		max-height: 500px;
		min-width: 360px;
		min-height: 196px;
	}
`;
const SlideInner = styled.div`
	position: relative;
	z-index: 1;
`;
const MainCarouselContainer = styled(Box)`
	flex-basis: calc(66.66% - ${px(Spacing.SPACE_12)});
	width: calc(66.66% - ${px(Spacing.SPACE_12)});

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-basis: 100%;
		width: 100%;
	}
`;
const AutoCarouselContainer = styled(Box)`
	flex-basis: ${px(420)};
	width: ${px(420)};
	margin-left: ${px(Spacing.SPACE_24)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-basis: 100%;
		width: 100%;
		margin-left: ${px(Spacing.SPACE_0)};
		padding-left: ${props => props.mobilePaddingLeft || 0};
		padding-right: ${props => props.mobilePaddingRight || 0};
	}
`;
const FlexRow = styled(FlexContainer)`
	margin-bottom: {props => px(props.marginBottom) || px(Spacing.SPACE_12)}
	justify-content: ${props => props.justifyContent};
	align-items: ${props => props.alignItems};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: row;
		padding: ${props => props.type === 'mobile-padding' ? `${px(Spacing.SPACE_12)} ${px(Spacing.SPACE_12)}` : `${px(Spacing.SPACE_0)} ${px(Spacing.SPACE_0)}`};
	}
`;
const HtmlContainer = styled.div`
	max-width: ${px(500)};
	color: ${palette.solidWhite};
	margin-bottom: ${px(Spacing.SPACE_12)};
`;
const ImageContainer = styled.div`
	height: ${px(240)};
`;
const DesktopBox = styled.div`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const MobileBox = styled.div`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const Banner = styled.div`
`;
const blockLinkStyles = `
	width: auto;
	display: inline-block;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: 100%;
		display: flex;
		justify-content: center; 
	}
`;
const textLinkStyles = `
	margin-left: ${px(Spacing.SPACE_16)};
	color: ${palette.solidWhite};

	&:hover {
		color: ${palette.solidWhite};
		text-decoration: none;
	}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-left: ${Spacing.SPACE_0};
		margin-top: ${px(Spacing.SPACE_8)};
		display: block;
		text-align: center;
	}
`;
const mAutoLinkStyles = `
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;
const ChipBox = styled.span`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const ItemBox = styled.div`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;

const buildState = (props, state) => {

	let slides = [];
	let rotationTime = 1000; // default

	// different json for offline and online call
	if (props.offline) {
		rotationTime = parseInt(props.rotationTime) ? parseInt(props.rotationTime) : rotationTime;
		slides = props.data && props.data.slides ? props.data.slides.map(slide => {
			return {
				offline: props.offline,
				title: slide.title,
				subTitle: slide.subTitle,
				label: slide.label,
				image: slide.image,
				mobileImage: slide.mobileImage,
				altText: slide.altText,
				items: slide.items,
				linkName: slide.linkName,
				link: slide.link ? mapLinks([{
					url: slide.link,
					navText: slide.linkName,
				}], props.offline)[0].url : '',
				textLinkName: slide.textLinkName,
				textLink: slide.textLink ? mapLinks([{
					url: slide.textLink,
					navText: slide.textLinkName,
				}], props.offline)[0].url : ''
			};
		}) : [];
	} else {

		try {

			let obj = props.data["Top-carouselData"]["Top-carousel"];

			rotationTime = parseInt(obj.RotationTime) ? parseInt(obj.RotationTime) : rotationTime;
			slides = Array.isArray(obj.Slide) ? obj.Slide : [obj.Slide];
			slides = slides.map(slide => {

				let subtitleLinks = [];

				if (Array.isArray(slide.SubTitle.links.link)) {

					subtitleLinks = slide.SubTitle.links.link.map(link => {
						return {
							name: link.name,
							target: link.target || link.anchor
						};
					});

				} else if (slide.SubTitle.links !== '') {

					subtitleLinks = [{
						name: slide.SubTitle.links.link.name,
						target: slide.SubTitle.links.link.target || slide.SubTitle.links.link.anchor
					}];

				}

				return {
					title: slide.Title,
					subTitle: replaceCmsContentLinks(slide.SubTitle.content, subtitleLinks),
					label: slide.Label,
					image: slide.Image ? slide.Image.link.target.replace(environment.cmsExportPath, '') : '',
					mobileImage: slide.MobileImage ? slide.MobileImage.link.target.replace(environment.cmsExportPath, '') : '',
					altText: slide.AltText || '',
					items: Array.isArray(slide.TextItem) ? slide.TextItem : [slide.TextItem],
					linkName: slide.LinkName,
					link: slide.Link ? mapLinks([{
						url: slide.Link.link.target,
						navText: slide.LinkName,
					}], props.offline)[0].url : '',
					textLinkName: slide.TextLinkName,
					textLink: slide.TextLink ? mapLinks([{
						url: slide.TextLink.link.target,
						navText: slide.TextLinkName,
					}], props.offline)[0].url : ''
				};
			});
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		mAutoSlides: state ? state.mAutoSlides : [],
		rotationTime: rotationTime,
		slides: slides
	};

};

class TopCarousel extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderSlides = this.renderSlides.bind(this);
		this.renderMAutoSlides = this.renderMAutoSlides.bind(this);
		this.renderCarousel = this.renderCarousel.bind(this);
		this.renderTextItemIcon = this.renderTextItemIcon.bind(this);
		this.renderLabel = this.renderLabel.bind(this);
		this.renderMAutoSlidesLabel = this.renderMAutoSlidesLabel.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {

		let apiPath = '';

		if (this.state.offline) {
			apiPath = environment.offlineMAutoApiPath + 'carousel.json';
		} else {
			apiPath = environment.onlineMAutoApiPath + 'carousel.json';
		}

		fetch(apiPath)
			.then(res => res.json())
			.then(json => {
				this.setState({
					slides: this.state.slides,
					mAutoSlides: json.map(slide => {
						return {
							type: slide.type,
							rentPriceNetto: slide.rentPriceNetto,
							leasePriceNetto: slide.leasePriceNetto,
							isNew: slide.isNew,
							mainPhotoUrl: (this.state.offline ? 'mleasing' : '') + slide.mainPhotoUrl.replace(environment.cmsExportPath, ''),
							make: slide.make,
							model: slide.model,
							mautoUrl: slide.mautoUrl
						};
					})
				});
			});
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	renderMAutoSlidesLabel() {

		// const length = this.state.mAutoSlides.length;
		// let label = '';

		// if (length == 0) {
		// 	label = 'samochodów'
		// } else if (length == 1) {
		// 	label = 'samochód'
		// } else {

		// 	const str = length.toString()[length.toString().length - 1];

		// 	if (length > 10 && length < 20) {
		// 		label = 'samochodów';
		// 	} else {
		// 		label = parseInt(str) >= 5 || parseInt(str) <= 1  ? 'samochodów' : 'samochody';
		// 	}
		// }

		// return length + ' ' + label;

		return 'nowe samochody'
	}

	renderMAutoSlides(slides) {

		let arr = [...slides];

		if (arr.length > 4) {
			arr = arr.splice(0, 4);
		}

		return arr.map((slide, i) => {
			return (
				<EqualHightBox key={'slide-' + i}>
					{generateLink(slide.mautoUrl, '', mAutoLinkStyles, '', this.state.offline)}
					<ImageContainer>
						<Image
							src={slide.mainPhotoUrl}
							alt={slide.make + ' ' + slide.model}
							title={slide.make + ' ' + slide.model}
							height="240px"
							width="420px"
						/>
					</ImageContainer>
					<DarkFlexContainer>
						<Box>
							<Text as="h3" color="solidWhite">{slide.make + ' ' + slide.model}</Text>
							<Text as="small" color="solidWhite">{slide.type}</Text>
						</Box>
						<Box>
							<FlexRow justifyContent="flex-end" alignItems="flex-end">
								<Text as="small" color="solidWhite" marginBottom={2}>od</Text>
								<Text as="h2" color="solidWhite" marginLeft={Spacing.SPACE_8}>{this.showCarPrice(slide.rentPriceNetto, slide.leasePriceNetto)}&nbsp;zł</Text>
							</FlexRow>
							<Text as="small" color="solidWhite">netto&nbsp;miesięcznie</Text>
						</Box>
					</DarkFlexContainer>
				</EqualHightBox>
			);
		});
	}

	showCarPrice(rentPriceNetto, leasePriceNetto) {
		rentPriceNetto = parseInt(rentPriceNetto);
		leasePriceNetto = parseInt(leasePriceNetto);


		if (rentPriceNetto > 0 && leasePriceNetto > 0) {
			if (rentPriceNetto < leasePriceNetto) {
				return rentPriceNetto;

			} else if (rentPriceNetto > leasePriceNetto) {
				return leasePriceNetto;

			}
		} else {
			if (rentPriceNetto <= 0) {
				return leasePriceNetto;

			} else if (leasePriceNetto <= 0) {
				return rentPriceNetto;

			}
		}
	}

	renderTextItemIcon(item) {

		if (item) {
			return <Icon iconComponent={Tick} primaryColor={IconColor.BLUE} title="ikona" />;
		}

	}

	renderLabel(label) {

		if (label) {
			return <Chip>{label}</Chip>;
		}

	}

	renderMobileImg(mobileImage, slide) {

		if (mobileImage) {
			return <CarouselImageMobile src={slide.mobileImage} alt={slide.altText} title={slide.title} />
		}

	}

	renderSlides(slides) {
		return slides.map((slide, i) => {
			return (
				<SlideOuter key={'slide-' + i} className="slide-inner">
					<CarouselImage
						src={slide.image}
						alt={slide.altText}
						title={slide.title}
						className={(slide.mobileImage ? 'with-mobile' : '')}
					/>
					{this.renderMobileImg(slide.mobileImage, slide)}
					<SlideInner>
						<ChipBox>{this.renderLabel(slide.label)}</ChipBox>
						<DesktopBox>
							<Text as="h1" color="solidWhite" marginBottom={Spacing.SPACE_12} marginTop={Spacing.SPACE_12} dangerouslySetInnerHTML={{__html: slide.title}}></Text>
						</DesktopBox>
						<MobileBox>
							<Text as="h2" color="solidWhite" marginBottom={Spacing.SPACE_12} marginTop={Spacing.SPACE_12} dangerouslySetInnerHTML={{__html: slide.title}}></Text>
						</MobileBox>
						{slide.subTitle ? <HtmlContainer color={palette.solidWhite}>{componentsFromHtml(slide.subTitle, this.state.offline)}</HtmlContainer> : ''}
						{slide.items ? <ItemBox>{slide.items.map((item, j) => {
							return (<Box as="div" key={'item-' + j} marginBottom={Spacing.SPACE_12}>
								<FlexRow>
									{this.renderTextItemIcon(item)}
									<Text as="div" color="solidWhite">{componentsFromHtml(`<p>${item}</p>`, this.state.offline)}</Text>
								</FlexRow>
							</Box>);
						})}</ItemBox> : ''}
						{generateLink(this.props.prefix + slide.link, '', blockLinkStyles, (
							<Button buttonType='primary' onClick={eventLogger.click}>{slide.linkName}</Button>
						), this.state.offline)}
						{slide.textLink ? generateLink(this.props.prefix + slide.textLink, slide.textLinkName, textLinkStyles, '', this.state.offline) : ''}
					</SlideInner>
				</SlideOuter>
			);
		});
	}

	renderCarousel() {

		if (this.state.slides.length > 1) {
			return (<Carousel
				autoRotationInterval={this.state.rotationTime}
				isLoop={true}
				isNavigationShown={false}
			>
				{this.renderSlides(this.state.slides)}
			</Carousel>);
		} else {
			return (<Banner>
				{this.renderSlides(this.state.slides)}
			</Banner>);
		}

	}

	render() {
		return (
			<Container>
				<FlexContainer>
					<MainCarouselContainer className="main-carousel">
						{this.renderCarousel()}
					</MainCarouselContainer>
					<AutoCarouselContainer
						mobilePaddingLeft={this.state.pageType === 'hp' ? 0 : px(Spacing.SPACE_16)}
						mobilePaddingRight={this.state.pageType === 'hp' ? 0 : px(Spacing.SPACE_16)}
					>
						<FlexRow
							justifyContent="space-between"
							alignItems="flex-end"
							marginBottom={px(Spacing.SPACE_12)}
							type="mobile-padding"
						>
							<Text as="h2">mAuto</Text>
							<Text as="h4" color="endeavourBlueUI">{this.renderMAutoSlidesLabel()}</Text>
						</FlexRow>
						<div className="mauto-carousel">
							<Carousel>
								{this.renderMAutoSlides(this.state.mAutoSlides)}
							</Carousel>
						</div>
					</AutoCarouselContainer>
				</FlexContainer>
			</Container>
		);
	}

}

export default TopCarousel;
