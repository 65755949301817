/*!
 * mLeasing, blog-article portlet :: 25/10/23 @ pp
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {ButtonColor, FontSize} from '@mbank-design/design-system/enums';
import {ChevronRight} from '@mbank-design/design-system/icons';
import {eventLogger} from '@mbank-design/design-system/utils';
import {Image} from '@mbank-design/design-system/components';
import {Stripe} from '@mbank-design/design-system/components';
import {HorizontalLine} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {IconColor} from '@mbank-design/design-system/enums';
import {Spacing, StripeHeight} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {mapLinks} from '../../utils/map-links';
import Container from '../../components/container';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links';
import {componentsFromHtml} from '../../utils/components-from-html';
import {generateLink} from '../../utils/generate-link';
import './blog-article.css';

const version = 'v: 0.1.0 :: r. 25/10/23 @ pp';

let site = '';
let thisUrl = '';

const svg = {
	clock: '<svg xmlns="http://www.w3.org/2000/svg" width="17.351" height="17.351" viewBox="0 0 17.351 17.351"><path id="Subtraction_1" data-name="Subtraction 1" d="M-2463.833,2100.851a8.681,8.681,0,0,1-8.666-8.676,8.68,8.68,0,0,1,8.666-8.675,8.689,8.689,0,0,1,8.685,8.675A8.69,8.69,0,0,1-2463.833,2100.851Zm.01-16.066a7.4,7.4,0,0,0-7.391,7.39,7.4,7.4,0,0,0,7.391,7.39,7.4,7.4,0,0,0,7.39-7.39A7.4,7.4,0,0,0-2463.823,2084.785Zm3.707,10.752h0l-4.351-2.611v-4.964h.84v4.579l3.922,2.328-.409.667Z" transform="translate(2472.499 -2083.5)"/></svg>',
	calendar: '<svg xmlns="http://www.w3.org/2000/svg" width="15.568" height="17.165" viewBox="0 0 15.568 17.165"><g id="Icon_feather-calendar" data-name="Icon feather-calendar" transform="translate(0.6 0.6)"><path id="Path_13" data-name="Path 13" d="M6.1,6H17.272a1.6,1.6,0,0,1,1.6,1.6V18.772a1.6,1.6,0,0,1-1.6,1.6H6.1a1.6,1.6,0,0,1-1.6-1.6V7.6A1.6,1.6,0,0,1,6.1,6Z" transform="translate(-4.5 -4.404)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/><path id="Path_14" data-name="Path 14" d="M24,3V6.193" transform="translate(-13.623 -3)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/><path id="Path_15" data-name="Path 15" d="M12,3V6.193" transform="translate(-8.009 -3)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/><path id="Path_16" data-name="Path 16" d="M4.5,15H18.868" transform="translate(-4.5 -8.614)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/></g></svg>',
	cite: '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="35" viewBox="0 0 40 35"><path id="quote-left" d="M26.25,35a3.751,3.751,0,0,1-3.75-3.75V12.5A12.5,12.5,0,0,1,35,0h.625A1.871,1.871,0,0,1,37.5,1.875v3.75A1.871,1.871,0,0,1,35.625,7.5H35a5.006,5.006,0,0,0-5,5v5H36.25A3.751,3.751,0,0,1,40,21.25v10A3.751,3.751,0,0,1,36.25,35ZM3.75,35A3.751,3.751,0,0,1,0,31.25V12.5A12.5,12.5,0,0,1,12.5,0h.625A1.871,1.871,0,0,1,15,1.875v3.75A1.871,1.871,0,0,1,13.125,7.5H12.5a5.006,5.006,0,0,0-5,5v5H13.75A3.751,3.751,0,0,1,17.5,21.25v10A3.751,3.751,0,0,1,13.75,35Z" fill="#fff" /></svg>'
}
const ArticleContainer = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: ${props => props.align === 'center' ? 'center' : 'flex-start'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
	}
`;
const InnerBox = styled.div`
	max-width: 100%;
`;
const AuthorBox = styled.div`
	display: grid;
	grid-template: auto / 80fr 20fr;

	@media screen and (max-width: ${px(480)}) {
		display: block;
	}
`;
const TocBox = styled.div`
	padding: .5rem 0;
	font-size: 1.2rem;
	font-weight: 700;
	@media screen and (max-width: ${px(480)}) {
		font-size: 1rem;
	}
`;
const TocList = styled.ol`
	margin: 2rem;
	padding: 1rem 1rem 1rem 1rem;
	list-style: none;
	border-left 2px solid black;
	@media screen and (max-width: ${px(480)}) {
		margin: 1rem;
		padding: .5rem 1rem;
	}
`;
const TocItem = styled.li`
	line-height: 1.3;
	margin: .5rem 0;
`;
const TocNumber = styled.span`
	float: left;
	width: 20px;
	margin: 0 1rem 0;
	text-align: right;

	@media screen and (max-width: ${px(480)}) {
		margin: 0;
		text-align: left;
	}
`;
const TocLink = styled.a`
	display: block;
	margin: 0 0 0 52px;
	color: ${palette.black30};
	&:hover {
		color: ${palette.black30};
	}
`;
const LinkBox = styled(Box)`
	position: relative;
	margin:  ${px(Spacing.SPACE_40)} 0;
	padding: ${px(Spacing.SPACE_24)};
	background-color: ${palette.wildSandGray};
	color: ${palette.black30};
	&:visited {
		text-decoration: none;
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-top: ${px(Spacing.SPACE_40)};
	}
`;
const LinkBoxInner = styled(Box)`
	display: flex;
	justify-content: space-between;
	gap: ${px(Spacing.SPACE_32)};
	align-items: center;
`;
const CiteBox = styled.div`
	margin:  ${px(Spacing.SPACE_40)} 0;
	padding: ${px(Spacing.SPACE_32)};
	background: ${palette.blueHighlight30};
	color: ${palette.solidWhite};
	text-align: center;
	font-size: 1.5rem;
`;
const ArticleBox = styled(Box)`
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: ${props => props.align === 'center' ? px(866) : 'calc(100% - 333px)'};
	max-width: ${props => props.align === 'center' ? px(866) : '100%'};
	padding: ${px(Spacing.SPACE_24)};
	margin-right: ${props => props.align === 'center' ? px(Spacing.SPACE_0) : px(Spacing.SPACE_24)};
	box-shadow: ${props => props.background === 'dark-light' || props.background === 'light-dark' ? `${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08)` : '0'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding: ${px(Spacing.SPACE_24)} ${px(Spacing.SPACE_16)};
		margin-right: ${px(Spacing.SPACE_0)};
		box-shadow: ${px(Spacing.SPACE_0)};
	}
`;
const PopularBox = styled(Box)`
	position: relative;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: ${px(309)};
	padding: ${px(Spacing.SPACE_24)};
	max-width: ${px(309)};
	background-color: ${palette.solidWhite};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
		max-width: 100%;
		margin-top: ${px(Spacing.SPACE_40)};
	}
	@media screen and (max-width: ${px(480)}) {
		position: static;
		margin-top: 0;
	}
`;
const TextBox = styled(Box)`
	width: 100%;
	@media screen and (max-width: ${px(480)}) {
		&.date-time {
			margin-top: ${px(Spacing.SPACE_16)};
		}
	}
`;
const TitleRow = styled.div`
	display: flex;
	align-items: center;
	padding-top: ${px(Spacing.SPACE_12)};
	padding-bottom: ${px(Spacing.SPACE_12)};
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 16px;
	}
`;
const ImageBox = styled(Box)`
	margin-top: ${px(Spacing.SPACE_16)};
	margin-bottom: ${px(Spacing.SPACE_16)};
	max-width: 100%;
	@media screen and (max-width: ${px(540)}) {
		display: none;
	}	
`;
const GalleryBox = styled.div`
	display: grid;
	grid-template-columns: 50fr 50fr;
	grid-template-rows: 300px 300px;
	grid-auto-flow: column;
	gap: ${px(Spacing.SPACE_24)};
	margin: ${px(Spacing.SPACE_40)} 0;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
		margin: ${px(Spacing.SPACE_24)};
	}
`;
const GalleryImage = styled.div`
	&:first-of-type {
		grid-column: 1;
		grid-row: 1 / 3;
		width: 100%;
	}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	}	
`;
const linkStyles = `
	color: ${palette.zeusBlackUI};
`;
const LinkItem = styled.div`
	display: flex;
	align-items: center;
	margin-top: ${px(Spacing.SPACE_12)};
	margin-bottom: ${px(Spacing.SPACE_12)};
	font-weight: 500;
`;
const Separator = styled.div`
	height: ${px(Spacing.SPACE_16)};
`;
const ButtonLink = styled.div`
	text-align: center;
	padding-top: ${px(Spacing.SPACE_16)};
`;
const MarkContainer = styled.div`
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-bottom: 16px;
	}
`;
const FilterMark = styled.div`
	color: ${palette.solidWhite};
	background-color: ${palette.endeavourBlueUI};
	border-radius: 3px;
	padding-left: 4px;
	padding-right: 4px;
	margin-right: 8px;
	line-height: 1rem;
`;
const PopularMark = styled(FilterMark)`
	background-color: ${palette.flushOrange};
`;
const CategoryMark = styled(FilterMark)`
	background-color: ${palette.endeavourBlueUI};
`;
const buildState = (props, state) => {

	const buildLinkArray = ((content) => {

		let links = [];

		if (Array.isArray(content.links.link)) {
			links = content.links.link.map(link => {

				return {
					name: link.name,
					target: link.target + (link.hasOwnProperty('query') ? '?' + link.query : '') || link.anchor
				};
			});
		}
		else if (content.links !== '') {
			links = [{
				name: content.links.link.name,
				target: content.links.link.target + (content.links.link.hasOwnProperty('query') ? '?' + content.links.link.query : '') || content.links.link.anchor
			}];
		}
		return links;
	});

	const getLink = ((link) => {

		if (typeof link === 'object') {
			link = link.link.target.replace(environment.cmsExportPath, '');
		}
		return link;
	});

	const twoDigits = ((no) => {

		return no < 10 ? '0' + no : no;
	});

	const getItems = ((items) => {

		let arr = items.map((item) => {

			let obj1 = {};
			let obj2 = {};
			let number = 0;

			for (const [key, value] of Object.entries(item.Choice)) {
				for (const [key2, value2] of Object.entries(value)) {
					++number;

					switch (key2) {
						case 'ChapterTitle':
							if (value2) {
								toc.push(value2);
							}
							break;
						case 'Text':
							if (!props.offline) {
								let textLinks = buildLinkArray(value2);
								value2 = textLinks.length ? replaceCmsContentLinks(value2['content'], textLinks) : value2['content'];
							}
							break;
						case 'Link':
							value2 = getLink(value2);
							break;
						case 'Image':
							if (!props.offline) {
								if (!Array.isArray(value2)) {
									if (typeof value2 === 'object') {
										value2 = [value2];
									}
								}
							}
						default:
					}
					obj2[key2] = value2;
					obj1[key] = obj2;
				}
			}
			return obj1;
		});
		return arr;
	});

	let author = '';
	let avatar = '';
	let addInfo = '';
	let date = '';
	let readingTime = '';
	let title = '';
	let intro = '';
	let teaser = '';
	let toc = [];	//table of contents generated from chapters
	let items = [];	//generated page sections: chapter, linkbox, citebox, images
	let recommended = false;
	let popular = false;
	let share = false;
	let categories = [];

	if (props.offline) {
		date = props.data.date;
		author = props.data.author;
		addInfo = props.data.addInfo;
		avatar = props.data.avatar;
		readingTime = props.data.readingTime;
		title = props.data.title;
		intro = props.data.intro;
		recommended = props.data.recommended === 'true' ? true : false;
		popular = props.data.popular === 'true' ? true : false;
		share = props.data.share === 'true' ? true : false;
		categories = props.data && props.data.category.length ? [''] : [];

		if (props.data.item && props.data.item.length) {
			items = getItems(props.data.item);
		}
	}
	else {
		try {
			let obj = props.data['BlogArticleData']['BlogArticle'];

			if (obj.Item && obj.Item.length) {
				items = getItems(obj.Item);
			}
			author = obj.Author;
			addInfo = obj.AddInfo;
			avatar = obj.Avatar.link.target.replace(environment.cmsExportPath, '');
			title = obj.Title;
			share = obj.Share;
			readingTime = obj.ReadingTime;
			date = obj.Date;

			// replace link href attribute representations with actual paths
			let textLinks = buildLinkArray(obj.Intro);
			intro = textLinks.length ? replaceCmsContentLinks(obj.Intro.content, textLinks) : obj.Intro.content;
			//console.log(`intro: ${intro}`);
			recommended = obj.Recommended;
			popular = obj.Popular;
		} catch (err) {
			console.log(err);
		}
	}
	if (date) {
		date = `${twoDigits(new Date(date).getDate())}.${twoDigits(new Date(date).getMonth() + 1)}.${new Date(date).getFullYear()}`;
	}
	readingTime += readingTime ? ' min' : '';

	return {
		offline: state ? state.offline : props.offline,
		pageType: state ? state.pageType : props.pageType,
		author: (author == '') ? (state ? state.author : author) : author,
		avatar: (avatar == '') ? (state ? state.avatar : avatar) : avatar,
		addInfo: (addInfo == '') ? (state ? state.addInfo : addInfo) : addInfo,
		readingTime: (readingTime == '') ? (state ? state.readingTime : readingTime) : readingTime,
		title: (title == '') ? (state ? state.title : title) : title,
		intro: (intro == '') ? (state ? state.intro : intro) : intro,
		teaser: (teaser == '') ? (state ? state.teaser : teaser) : teaser,
		toc: !toc ? (state ? state.toc : []) : toc,
		date: (date == '') ? (state ? state.date : date) : date,
		recommended: (recommended === undefined) ? false : recommended,
		popular: (popular === undefined) ? false : popular,
		categories: state ? state.categories : categories,
		items: !items ? (state ? state.items : items) : items,
		share: (share === undefined) ? false : share
	};
};

class BlogArticle extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.scrollPopularBox = this.scrollPopularBox.bind(this);
		this.renderLinks = this.renderLinks.bind(this);
		this.renderLinkBox = this.renderLinkBox.bind(this);
		this.share = this.share.bind(this);
		this.state = buildState(props);
	}

	scrollPopularBox() {	//moving box with popular articles (on page scroll)

		const p = document.getElementById('popular-box');
		/* after veryfing css display state of the box its top position can be set (box is visible only in desktop view (above 1022 pixels screen width)) */
		if (document.defaultView.getComputedStyle(p, null).getPropertyValue('display') !== 'none') {
			const ph = p.clientHeight;
			const pps = document.getElementsByClassName('blog-article')[0];
			const aot = pps.offsetTop;
			const aoh = pps.offsetHeight;
			const ws = window.scrollY;
			p.style.top = (ws < aoh ? (ws < aot ? 0 : ws - aot + 22) : aoh - aot) + 'px';
		}
	}

	getVersion() {

		return version;
	}

	componentWillUnmount() {

		window.removeEventListener('scroll', this.scrollPopularBox);
	}

	componentDidMount() {

		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('blog-article');

			if (window.origin.includes('localhost')) {
				site = environment.siteUrl;
			}
		}
		window.addEventListener('scroll', this.scrollPopularBox);
		thisUrl = window.location.toString();

		const scriptPaths = [
			site + "/.templates/js/jquery.min.js",
			site + "/.templates/js/anchors.js"
		];

		const loadScript = (path, i) => {

			let timeout = setTimeout(() => {

				if (path.match(/anchors\.js/) && typeof $ === 'undefined') {
					loadScript(path);
				}
				else {
					const script = document.createElement('script');
					script.src = path;
					script.defer = true;
					document.body.appendChild(script);
				}
				clearTimeout(timeout);
			}, 100);
		};

		scriptPaths.forEach((path, i) => {

			loadScript(path, i);
		});
		// show authomatic popular links if article is blog article or cms content based links
		// const url = 'https://test-mleasing11.qrqs.eu/api/paths/data.json'
		const url = (this.state.offline ? '/api/paths/' : environment.onlineApiPathsPath) + environment.apiPathsFileName;
		const folderName = 'blog';

		if (window.location.href.match("/" + folderName + "/")) {
			fetch(url)
				.then(res => res.json())
				.then(json => {
					let paths = json.filter(path => path.match("^/" + folderName + "/") && !path.match(/index.html/) && !path.match("^/" + folderName + "/$"));
					let items = [];

					Promise.all(paths.map(async path => {

						let url = environment.onlineApiPath.slice(0, -1) + path + 'index.html.json';

						if (this.state.offline) {
							url = path + '?__json=true';
						}
						try {
							const res = await fetch(url);
							const json = await res.json();
							let article;
							let categories = [];

							/* if (json['page'].hasOwnProperty('category') && (path == window.location.pathname)) { // get categories
								json['page'].category.forEach(obj => {
		
									for (let key in obj) {
										categories.push({
											name: key,
											title: obj[key]
										});
									}
								});
							} */
							for (let key_1 in json) {
								if (key_1.match(/blog-article(?!s)/)) {
									article = json[key_1];

									let title = '';
									let recommended = false;
									let popular = false;
									let date = '';
									let obj_1 = [];

									if (this.state.offline) {
										obj_1 = JSON.parse(article);
										title = obj_1.title;
										recommended = obj_1.recommended == 'true' ? true : false;
										popular = obj_1.popular == 'true' ? true : false;
										date = obj_1.date;
									}
									else {
										obj_1 = article['BlogArticleData']['BlogArticle'];

										title = obj_1.Title;
										recommended = obj_1.Recommended;
										popular = obj_1.Popular;
										date = obj_1.Date;
									}
									let arr = [];

									/* if (obj_1.category && obj_1.category !== '[]') {
										arr = obj_1.category.replace(/\]/g, '').replace(/\[/g, '').split(',');
										arr = arr.map(cat => {
		
											let catArr = cat.split(':')[1].trim().replace('/sites/mleasing/.categories/', '').split('/');
		
											catArr = catArr.filter(c => c); // check for empty strings
											return catArr[catArr.length - 1];
										});
									}
		
									arr.forEach(cat_1 => {
		
										if (!categories.includes(cat_1)) {
											categories.push({
												name: cat_1,
												title: cat_1
											});
										}
									}); */

									items.push({
										title: title,
										path: path,
										recommended: recommended,
										popular: popular,
										date: date,
										categories: categories
									});
								}
							}
						} catch (err) {
							return console.log(err);
						}
					})).then((res) => {

						// sort news by date
						const quickSort = (arr) => {

							const sort = (arr) => {

								let sorted = [arr[0]];
								let bigger = [];
								let lower = [];

								arr.forEach(elm => {

									// js date needs reverted order 
									let elmDate = new Date(elm.date);
									let firstElmDate = new Date(sorted[0].date);

									if (elmDate > firstElmDate) {
										bigger.push(elm);
									}
									if (elmDate < firstElmDate) {
										lower.push(elm);
									}
								});

								if (lower.length) {
									lower = sort(lower);
								}
								if (bigger.length) {
									bigger = sort(bigger);
								}
								sorted = lower.concat(sorted).concat(bigger);
								return sorted;
							}
							return sort(arr);
						}

						let sortedItems = quickSort(items).reverse();
						let popularItems = sortedItems
							.filter((item) => item.popular)
							.filter((item) => {
								return item.path !== window.location.pathname;
							})
							.filter((item, i) => i <= 3)
							.map(item => ({

								path: item.path,
								title: item.title
							}));
						let links = [];

						links = popularItems.map(item => {

							return {
								linkName: item.title,
								link: mapLinks([{
									url: item.path,
									navText: item.title,
								}], this.state.offline)[0].url
							};
						});

						let categories = [];

						/* sortedItems.forEach(item => {
		
							item.categories.forEach(category => {
		
								let arr = categories.filter(cat => cat.name === category.name);
		
								if (!arr.length && item.path == window.location.pathname) {
									categories.push(category);
								}
							});
						}); */
						this.setState({
							offline: this.state.offline,
							pageType: this.state.pageType,
							title: this.state.title,
							date: this.state.date,
							recommended: this.state.recommended,
							popular: this.state.popular,
							links: links,
							categories: categories
						});

					});
				})
				.catch(err => console.log(err));
		}
		else {
			let links = [];

			if (this.props.offline) {
				links = this.props.data && this.props.data.links.map(item => {

					return {
						linkName: item.linkName,
						link: mapLinks([{
							url: item.link,
							navText: item.linkName,
						}], this.props.offline)[0].url
					};
				});
			}
			else {
				let obj = this.props.data['BlogArticleData']['BlogArticle'];

				if (obj.Link) {
					if (Array.isArray(obj.Link)) {
						links = obj.Link.map(item => {
							return {
								linkName: item.LinkName,
								link: mapLinks([{
									url: item.Link.link.target,
									navText: item.LinkName,
								}], this.props.offline)[0].url
							};
						});
					}
					else {
						links = [{
							linkName: obj.Link.LinkName,
							link: mapLinks([{
								url: obj.Link.Link.link.target,
								navText: obj.Link.LinkName,
							}], this.props.offline)[0].url
						}];
					}
				}
			}
			this.setState({
				offline: this.state.offline,
				pageType: this.state.pageType,
				title: this.state.title,
				date: this.state.date,
				recommended: this.state.recommended,
				popular: this.state.popular,
				links: links
			});
		}
	}

	static getDerivedStateFromProps(props, state) {

		return buildState(props, state);
	}

	renderLinks(links) {

		if (links) {
			return links.map((item, i) => {
				return (<>
					<LinkItem as="div" key={'link-item-' + i}>
						{generateLink(this.props.prefix + item.link, item.linkName, linkStyles, '', this.state.offline)}
						<Icon
							iconComponent={ChevronRight}
							primaryColor={IconColor.BLUE}
							title="ikona"
						/>
					</LinkItem>
					<HorizontalLine />
				</>
				);
			})
		}
		else {
			return '';
		}
	}

	renderLinkBox(elm) {

		return (
			<LinkBox as="div" className="link-box">
				<Stripe height={StripeHeight.HEIGHT_6} style={{position: ` absolute`, top: `-6px`, left: 0, width: `calc(100% - ${px(Spacing.SPACE_24)})`}} />
				{elm.LinkName && <Text as="p">{elm.LinkName}</Text>}
				<LinkBoxInner className="link-box-inner">
					{elm.Image && <ImageBox><Image as="image" src={site + (this.state.offline ? elm.Image : elm.Image[0].link.target.replace(environment.cmsExportPath, ''))} alt={elm.AltText} /></ImageBox>}
					<Text as="p" styledAs="h2">{elm.Name}</Text>
					<Image as="image" className={'button'} src={`${site}/.templates/img/icons/svg/red/circle-arrow.svg`} alt={elm.LinkName} />
				</LinkBoxInner>
				<Stripe height={StripeHeight.HEIGHT_6} style={{position: ` absolute`, bottom: '-6px', left: '80%', width: `calc(20% - ${px(Spacing.SPACE_24)})`}} />
			</LinkBox>
		);
	}

	share(e, where) {

		e.preventDefault();

		const url = window.location.href; // Adres URL aktualnej strony
		let shareUrl = `https://www.`;

		shareUrl += (where === 'fb' ? 'facebook.com/sharer/sharer.php?u=' : 'linkedin.com/shareArticle?url=') + encodeURIComponent(url);
		window.open(shareUrl, 'ShareWindow', 'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');

		return false;
	}

	render() {
		return (
			<Container>
				<ArticleContainer>
					<InnerBox>
						<ArticleBox>
							<AuthorBox>
								<TextBox as="div" className="author-box">
									{this.state.avatar && <img className="avatar" src={site + this.state.avatar} alt="" />}
									<Text as="p" fontWeight={700}>{this.state.author}</Text>
									<Text as="small" fontWeight={400}>{this.state.addInfo}</Text>
								</TextBox>
								<TextBox as="div" className="date-time">
									{this.state.readingTime && <Text as="p"><span dangerouslySetInnerHTML={{__html: svg.clock}}></span>{` Czas czytania: ${this.state.readingTime}`}</Text>}
									{this.state.date && <Text as="p"><span dangerouslySetInnerHTML={{__html: svg.calendar}}></span> {this.state.date}</Text>}
								</TextBox>
							</AuthorBox>
							<TitleRow>
								<MarkContainer>
									{this.state.popular ? <PopularMark>
										<Text as="small" color="solidWhite">popularne</Text>
									</PopularMark> : ''}
									{this.state.categories.map((category, i) => {
										return (<CategoryMark key={'category-' + i}>
											<Text as="small" color="solidWhite">{category.title}</Text>
										</CategoryMark>);
									})}
								</MarkContainer>
							</TitleRow>
							<Text as="div" className="intro" marginTop={Spacing.SPACE_24} marginBottom={Spacing.SPACE_24}>{componentsFromHtml(this.state.intro, this.state.offline)}</Text>
							{this.state.toc ? <TocBox className='table-of-contents'>
								Spis treści<TocList>
									{this.state.toc.map((chapter, i) => {

										const link = chapter.replace(/ /g, '-');
										return (
											<TocItem>
												<TocNumber>{i + 1}</TocNumber>
												<TocLink href={`#${link}`}>{chapter}</TocLink>
											</TocItem>
										);
									})}
								</TocList></TocBox> : ''}
							{/*<Text as="p" styledAs={'h2'} marginBottom={Spacing.SPACE_24}>{this.state.title}</Text>*/}
							{this.state.items.map(element => {
								let elm = {};

								if (element['Chapter']) {
									elm = element['Chapter'];
									return (
										<>
											<a id={elm.ChapterTitle.replace(/ /g, '-')}></a><Text as="h2" styledAs={'h3'} marginTop={Spacing.SPACE_32} marginBottom={Spacing.SPACE_32} className={'chapter-title'}>{elm.ChapterTitle}</Text>
											<Text as="div" className={'chapter-content'} marginTop={Spacing.SPACE_24} marginBottom={Spacing.SPACE_24}>{componentsFromHtml(elm.Text, this.state.offline)}</Text>
										</>
									)
								}
								if (element['CiteBox']) {
									elm = element['CiteBox'];
									return (
										<>
											<CiteBox as="div" className="cite-box">
												<TextBox as="div" className={'cite-image'} dangerouslySetInnerHTML={{__html: svg.cite}}></TextBox>
												<Text as="div" fontSize={FontSize.SIZE_24} className="cite">{componentsFromHtml(elm.Text, this.state.offline)}</Text>
												<Text as="small" className={'cite-author'}>{elm.Author}</Text>
											</CiteBox>
										</>
									)
								}
								if (element['LinkBox']) {
									elm = element['LinkBox'];
									return (
										elm.Link ? generateLink(elm.Link, '', `color: ${palette.black30};&:hover {color: ${palette.black30};text-decoration: none;}&:visited{text-decoration: none;}`, this.renderLinkBox(elm), this.state.offline) : this.renderLinkBox(elm)
									)
								}
								if (element['Images']) {
									elm = element.Images?.Image;

									if (elm && elm.length) {
										let cssClass = elm.length > 1 ? 'multiple' : 'single';
										return (
											<>
												<GalleryBox className={`gallery ${cssClass} images-${elm.length}`}>
													{elm.map((img, i) => {

														let role = img.AltText ? ' role="presentation"' : '';

														return (
															<GalleryImage>
																{img.Image && <img className={`img-${i}`} src={site + (this.state.offline ? img.Image : img.Image.link.target.replace(environment.cmsExportPath, ''))} alt={img.AltText} role={role} />}
																{img.MobileImage && <img className={`mobile img-${i}`} src={site + (this.state.offline ? img.MobileImage : img.MobileImage.link.target.replace(environment.cmsExportPath, ''))} alt={img.AltText} role={role} />}
															</GalleryImage>)
													})}
												</GalleryBox>
											</>
										)
									}
								}
							})}
							{this.state.share &&
								<Box as="div" className="share">
									<Text styledAs="h3" className={'title'}>Udostępnij</Text>
									<Box as="ul" className="list">
										<Box as="li" background={palette.altoGray}>
											{<a href="#" onClick={(e) => {this.share(e, 'fb')}} title="Udostępnij na Facebooku" rel="noreferrer noopener"><Image src={`${site}/.templates/img/icons/svg/gray/facebook.svg`} /></a>}
										</Box>
										<Box as="li" background={palette.altoGray}>
											{<a href="#" onClick={(e) => {this.share(e, 'li')}} title="Udostępnij na LinkedIn" rel="noreferrer noopener"><Image src={`${site}/.templates/img/icons/svg/gray/linkedin.svg`} /></a>}
										</Box>
										{/*<Box as="li" background={palette.altoGray}>
											{generateLink(`mailto:?body=${thisUrl}&subject=Polecam artykuł z bloga mLeasingu`, '', '', (<Image src={`${site}/.templates/img/icons/svg/gray/envelope.svg`} />), this.state.offline)}
										</Box>*/}
									</Box>
								</Box>}
						</ArticleBox>
					</InnerBox>
					{<PopularBox id="popular-box">
						<Text as="p" styledAs={'h4'} fontWeight={700}>popularne artykuły</Text>
						<Separator />
						<Separator />
						{this.renderLinks(this.state.links)}
					</PopularBox>}
				</ArticleContainer>
			</Container>
		);
	}
}

export default BlogArticle;