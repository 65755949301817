/*!
 * mLeasing, image-box portlet :: 09/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {ButtonColor} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import Container from '../../components/container.js';
import {mapLinks} from '../../utils/map-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const FlexContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
	}
`;
const ItemFlexContainer = styled(FlexContainer)`
	padding-bottom: ${props => props.lastRow ? Spacing.SPACE_0 : px(Spacing.SPACE_24)};
	padding-right: ${props => props.firstInRow ? px(Spacing.SPACE_12) : Spacing.SPACE_0};
	padding-left: ${props => props.firstInRow ? Spacing.SPACE_0 : px(Spacing.SPACE_12)};
	flex-wrap: nowrap;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_24)};
		padding-left: ${px(Spacing.SPACE_24)};
	}
`;
const FlexBox = styled.div`
	flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 1;
	line-height: ${Spacing.SPACE_0};
`;
const ImageFlexBox = styled.div`
	flex-basis: 50%;
	padding-right: ${px(Spacing.SPACE_12)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${Spacing.SPACE_0};
		padding-left: ${Spacing.SPACE_0};
	}
`;
const TextFlexBox = styled.div`
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: ${px(Spacing.SPACE_12)};
	line-height: ${Spacing.SPACE_0};
	box-sizing: border-box;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${Spacing.SPACE_0};
		padding-left: ${Spacing.SPACE_0};
	}
`;
const TextBox = styled(Box)`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-top: ${px(Spacing.SPACE_16)};
		padding-bottom: ${px(Spacing.SPACE_16)};
	}
`;
const bottomLinkStyles = `
	margin-bottom: ${px(Spacing.SPACE_24)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-bottom: ${Spacing.SPACE_0};
	}
`;
const buildState = (props) => {

	let items = [];

	if (props.offline) {
		items = props.data && props.data.items.map(item => {
			return {
				title: item.title,
				linkName: item.linkName,
				link: mapLinks([{
					url: item.link,
					navText: item.linkName,
				}], props.offline)[0].url,
				image: item.image,
				altText: item.altText,
				text: item.text
			};
		});
	} else {

		try {

			let obj = props.data['Image-boxData']['Image-box'];

			items = obj.Item.map(item => {

				let text = '';
				let textLinks = [];

				if (item.Text) {

					if (Array.isArray(item.Text.links.link)) {

						textLinks = item.Text.links.link.map(link => {
							return {
								name: link.name,
								target: link.target || link.anchor
							};
						});

					} else if (item.Text && item.Text.links !== '') {

						textLinks = [{
							name: item.Text.links.link.name,
							target: item.Text.links.link.target || item.Text.links.link.anchor
						}];

					}

					text = replaceCmsContentLinks(item.Text ? item.Text.content : '', textLinks);
				}

				return {
					linkName: item.LinkName,
					title: item.Title,
					link: item.Link ? mapLinks([{
						url: item.Link.link.target,
						navText: item.LinkName,
					}], props.offline)[0].url : '',
					image: item.Image ? item.Image.link.target.replace(environment.cmsExportPath, '') : '',
					altText: item.AltText || '',
					text: text
				};
			});
		} catch (err) {
			console.log(err);
		}

	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		items: items
	};

};

class ImageBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('image-box');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	render() {
		return (
			<Container>
				<FlexContainer>
					{this.state.items && this.state.items.map((item, i) => {
						return (<FlexBox key={'image-box-item-' + i}>
							<ItemFlexContainer lastRow={i >= (this.state.items.length - 2)} firstInRow={!(i%2)}>
								<ImageFlexBox>
									<Image
										src={item.image}
										alt={item.altText}
										title={item.title}
										width={'100%'}
									/>
								</ImageFlexBox>
								<TextFlexBox>
									<Text as="h3" marginTop={Spacing.SPACE_24}>{item.title}</Text>
									<TextBox as="div">{componentsFromHtml(item.text, this.state.offline)}</TextBox>
									{item.link ? generateLink(item.link, '', bottomLinkStyles, (
										<Button buttonType="tertiary" color={ButtonColor.BLUE}>{item.linkName}</Button>
									), this.state.offline) : <span></span>}
								</TextFlexBox>
							</ItemFlexContainer>
						</FlexBox>);
					})}
				</FlexContainer>
			</Container>
		);
	}

}

export default ImageBox;
