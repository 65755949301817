/*!
 * mLeasing, faq portlet :: 06/04/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Button} from '@mbank-design/design-system/components';
import {Text} from '@mbank-design/design-system/components';
import {TextField} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {MagnifyingGlass} from '@mbank-design/design-system/icons';
import {Close} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import {Box} from '@mbank-design/design-system/components';
import {Accordion} from '@mbank-design/design-system/components';
import {AccordionColor} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {mapLinks} from '../../utils/map-links.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.2 :: r. 08/11/2021 @ vlk';
const PortletContainer = styled.div`
	max-width: 100%;
`;
const SearchContainer = styled.div`
	display: none;
	justify-content: center;
	align-items: center;
	padding-top: ${px(Spacing.SPACE_40)};
	padding-bottom: ${px(Spacing.SPACE_40)};
	background-color: ${palette.solidWhite};
`;
const FaqContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: ${px(Spacing.SPACE_40)};
	padding-bottom: ${px(Spacing.SPACE_40)};
	background-color: ${palette.wildSandgray};
`;
const SearchBoxInner = styled.div`
	position: relative;
	max-width: ${px(644)};
	flex-basis: ${px(644)};
	flex-grow: 1;
	flex-shrink: 0;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-width: 100%;
		flex-basis: 100%;
		padding: ${Spacing.SPACE_0} ${px(Spacing.SPACE_32)}
	}
`;
const Wrapper = styled.div`
	max-width: ${px(1315)};
	flex-basis: ${px(644)};
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-width: 100%;
		flex-basis: 100%;
	}
`;
const LeftColumn = styled.div`
	flex-basis: ${px(445)};
	flex-grow: 0;
	flex-shrink: 1;
	position: relative;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const RightColumn = styled.div`
	flex-basis: ${px(866)};
	flex-grow: 0;
	flex-shrink: 1;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-basis: 100%;
	}
`;
const FloatingBox = styled.div`
	background-color: ${palette.solidWhite};
	width: ${px(309)};
	padding: ${px(Spacing.SPACE_24)};
	position: absolute;
	top: 0;
	will-change: top, bottom, position;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const BoxTitle = styled.div`
	padding-bottom: ${px(Spacing.SPACE_8)};
	border-bottom: 1px solid ${palette.altoGray};
`;
const SectionTitle = styled.div`
	margin-top: ${px(Spacing.SPACE_8)};
	margin-bottom: ${px(Spacing.SPACE_8)};
	cursor: pointer;
`;
const SectionHeadingWrapper = styled.div`
	margin-bottom: ${px(Spacing.SPACE_12)};
	
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding: ${Spacing.SPACE_0} ${px(Spacing.SPACE_32)}
	}
`;
const FaqSection = styled.div`
	margin-bottom: ${px(Spacing.SPACE_40)};
`;
const ItemTitleWrapper = styled.div`
	width: calc(100% - 30px);
	padding-top: 24px;
	padding-bottom: 24px;
	position: absolute;
	height: ${px(30)};
	box-sizing: content-box;
`;
const SearchingIndicator = styled.div`
	position: absolute;
	right: ${px(Spacing.SPACE_16)};
	top: ${px(18)};
`;
const prepareItemData = (item, sectionIndex, itemIndex) => {

	let textLinks = [];

	if (item.Text && Array.isArray(item.Text.links.link)) {

		textLinks = item.Text.links.link.map(link => {
			return {
				name: link.name,
				target: link.target || link.anchor
			};
		});

	} else if (item.Text && item.Text.links !== '') {

		textLinks = [{
			name: item.Text.links.link.name,
			target: item.Text.links.link.target || item.Text.links.link.anchor
		}];

	}

	return {
		title: item.Title,
		text: replaceCmsContentLinks(item.Text ? item.Text.content : '', textLinks),
		link: item.Link ? item.Link.link.target.replace(environment.cmsExportPath, '') : '',
		linkName: item.LinkName,
		open: sectionIndex === 0 && itemIndex === 1 ? true : false,
	};
};
const buildState = (props) => {

	let	sections = [];
	let placeholder = '';

	if (props.offline) {
		placeholder = props.data.placeholder || '';
		sections = props.data.sections ? props.data.sections.map(section => {
			return {
				title: section.title,
				items: section.items ? section.items.map(item => {
					return {
						title: item.title || '',
						text: item.text || '',
						linkName: item.linkName,
						link: mapLinks([{
							url: item.link,
							navText: item.linkName,
						}], props.offline)[0].url,
						open: false
					};
				}) : []
			};
		}) : [];
	} else {

		try {

			let obj = props.data['FaqData']['Faq'];

			placeholder = obj.Placeholder || '';

			if (obj.Section) {

				let itemIndex = 0;
			
				if (Array.isArray(obj.Section)) {
					sections = obj.Section.map((section, i) => {
						
						let items = [];

						if (Array.isArray(section.Item)) {
							items = section.Item.map(item => {
								itemIndex++;
								return prepareItemData(item, i, itemIndex);
							});
						} else {
							items = [prepareItemData(section.Item, i, itemIndex)];
							itemIndex++;
						}

						return {
							title: section.Title || '',
							items: items
						};
					});
				} else {
						
					let items = [];

					if (Array.isArray(obj.Section.Item)) {
						items = obj.Section.Item.map(item => {
							itemIndex++;
							return prepareItemData(item, 0, itemIndex);
						});
					} else {
						items = [prepareItemData(obj.Section.Item, 0, itemIndex)];
						itemIndex++;
					}

					sections = [{
						title: obj.Section.Title,
						items: items
					}]
				}

			}
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		placeholder: placeholder,
		sections: sections,
		searchText: '',
		searching: false,
	};

};

class Faq extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.searchCallback = this.searchCallback.bind(this);
		this.scrollToSection = this.scrollToSection.bind(this);
		this.updateItems = this.updateItems.bind(this);
		this.updateState = this.updateState.bind(this);
		this.clearSearch = this.clearSearch.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('faq');
		}
		window.addEventListener('scroll', e => {

			const faqContainer = document.querySelector('#faq-container');
			const floatingBox = document.querySelector('#floating-box');
			const header = document.querySelector('#header-content');
			let height = 0;

			if (header) {
				height = header.clientHeight;
			}

			if (faqContainer) {

				const paddingHeight = 40;
				const topPos = faqContainer.offsetTop + paddingHeight;
				const bottomPos = faqContainer.offsetTop + faqContainer.clientHeight - paddingHeight - floatingBox.clientHeight;

				let floating = false;
				let position = '';

				if (topPos <= window.scrollY + height && bottomPos - height >= window.scrollY) {
					floating = true;
				} else {
					
					if (topPos > window.scrollY + height) {
						position = 'top';
					} else {
						position = 'bottom';
					}

				}

				floatingBox.style.position = floating ? 'fixed' : 'absolute';

				if (!floating) {
				
					if (position === 'bottom') {
						floatingBox.style.bottom ='0px';
						floatingBox.style.top = 'auto';
					} else if (position === 'top') {
						floatingBox.style.bottom = 'auto';
						floatingBox.style.top = '0';
					}

				} else {
					floatingBox.style.bottom = 'auto';
					floatingBox.style.top = this.state.offline ? '152px' : '100px';
				}

			}

		});
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	updateState(key, value) {
		this.setState({
			offline: this.state.offline,
			pageType: this.state.pageType,
			placeholder: this.state.placeholder,
			sections: key === 'sections' ? value : this.state.sections,
			searchText: key === 'searchText' ? value : this.state.searchText,
			searching: key === 'searching' ? value : this.state.searching,
		});
	}

	updateItems(sectionIndex, itemIndex) {

		const sections = this.state.sections.map((section, i) => {
			return {
				title: section.title,
				items: section.items.map((item, j) => {

					let open = item.open;

					if (open) {
						open = false;
					}

					if (i === sectionIndex && j === itemIndex) {
						open = true;
					}

					return {
						title: item.title,
						text: item.text,
						link: item.link,
						linkName: item.linkName,
						open: open
					};
				})
			};
		});

		this.updateState('sections', sections);
	}

	scrollToSection(index) {
		
		const section = document.querySelector('#section-' + index);
		const header = document.querySelector('#header-content');
		let height = 0;

		if (header) {
			height = header.clientHeight;
		}

		this.state.sections.forEach((section, i) => {

			const sectionElement = document.querySelector('#section-' + i);
			const itemTitles = sectionElement.querySelectorAll('h4 button');
				
			section.items.forEach((item, j) => {

				if (item.open) {
					itemTitles[j].click();
				}

				if (i === index && j === 0) {
					itemTitles[j].click();
				}

			});
		});

		const sections =  this.state.sections.map((section, i) => {
			return {
				title: section.title,
				items: section.items.map((item, j) => {

					let open = item.open;

					if (open) {
						open = false;
					}

					if (i === index && j === 0) {
						open = true;
					}

					return {
						title: item.title,
						text: item.text,
						link: item.link,
						linkName: item.linkName,
						open: open
					};
				})
			};
		});

		this.updateState('sections', sections);

		let timeout = setTimeout(() => {
			window.scrollTo(0, section.offsetTop - height - 20);
			clearTimeout(timeout);
		}, 500);

	}

	searchCallback(e) {

		let str = e.target.value;

		this.updateState('searchText', str);

		let timeout = setTimeout(() => {
			this.updateState('searching', str.length >= 3 ? true : false);
			clearTimeout(timeout);
		}, 0);

		let sections = this.state.sections;

		if (str.length >= 3) {
			sections = this.state.sections.map((section, i) => {

				const sectionElement = document.querySelector('#section-' + i);
				const itemTitles = sectionElement.querySelectorAll('h4 button');
					
				let items = section.items.map((item, j) => {
					
					let text = item.text;
					let found = false;
					const regex = new RegExp(str);

					if (text.toLowerCase().match(regex)) {
						found = true;

						if (!item.open) {
							itemTitles[j].click();
						}

					}

					return {
						title: item.title,
						text: text,
						link: item.link,
						linkName: item.linkName,
						open: found 
					}
				});

				return {
					title: section.title,
					items: items
				};
			});
			let timeout2 = setTimeout(() => {
				this.updateState('sections', sections);
				clearTimeout(timeout2);
			}, 0);
		}
	}

	clearSearch() {
		this.updateState('searchText', '');

		let timeout = setTimeout(() => {
			this.updateState('searching', false);
			clearTimeout(timeout);
		}, 0);
	}

	render() {
		return (
			<PortletContainer>
				<SearchContainer>
					<SearchBoxInner>
						<TextField
							value={this.state.searchText}
							placeholder={this.state.placeholder}
							height={Spacing.SPACE_24}
							leadingDecoration={<Icon iconComponent={MagnifyingGlass} primaryColor={IconColor.BLUE} title="ikona" />}
							onChange={this.searchCallback}
						/>
						{this.state.searching ? <SearchingIndicator onClick={this.clearSearch}>
							<Icon
								iconComponent={Close}
								primaryColor={IconColor.BLUE}
								title="ikona"
							/>
						</SearchingIndicator> : ''}
					</SearchBoxInner>
				</SearchContainer>
				<FaqContainer id="faq-container">
					<Wrapper>
						<LeftColumn>
							<FloatingBox
								id="floating-box"
							>
								<BoxTitle>
									<Text as="h4">tematy</Text>
								</BoxTitle>
								{this.state.sections ? this.state.sections.map((section, i) => {
									return (
										<SectionTitle
											key={'section-' + i}
											onClick={() => this.scrollToSection(i)}
										>
											<Text as="p" keyword={'section-' + i}>{section.title}</Text>
										</SectionTitle>
									)}) : ''}
							</FloatingBox>
						</LeftColumn>
						<RightColumn>
							{this.state.sections ? this.state.sections.map((section, i) => {
								return (<FaqSection
										key={'section-' + i}
										id={'section-' + i}
										className="section"
									>
									<SectionHeadingWrapper>
										<Text as="h2">{section.title}</Text>
									</SectionHeadingWrapper>
									{section.items ?  <Accordion layout="normal" color={AccordionColor.WHITE}>
										{section.items.map((item, j) => {
											return (
												<Accordion.Item key={'accordion-item-' + j} isDefaultOpen={item.open}>
													<Accordion.Header level={4}>
														<ItemTitleWrapper onClick={() => this.updateItems(i, j)}></ItemTitleWrapper>
														{item.title}
													</Accordion.Header>
													<Accordion.Content>
														<Box
															as="div"
															marginBottom={Spacing.SPACE_24}
															className="item"
														>
															{componentsFromHtml(item.text, this.state.offline)}
														</Box>
														{item.link ? generateLink(this.props.prefix + item.link, '', '', (
															<Button buttonType="primary">{item.linkName}</Button>
														), this.state.offline) : ''}
													</Accordion.Content>
												</Accordion.Item>
											);
										})}
									</Accordion> : ''}
								</FaqSection>);
							}) : ''}
						</RightColumn>
					</Wrapper>
				</FaqContainer>
			</PortletContainer>
		);
	}

}

export default Faq;
