/*!
 * mLeasing, contact-box portlet :: 07/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import palette from '@mbank-design/design-system/palette';
import {Spacing} from '@mbank-design/design-system/enums';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {Direction} from '@mbank-design/design-system/enums';
import {Alignment} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import {Icon} from '@mbank-design/design-system/components';
import {PhoneHandle} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import Container from '../../components/container.js';
import {mapLinks} from '../../utils/map-links.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const InnerContainer = styled.div`
	display: flex;
	justify-content: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-wrap: wrap;
	}
`;
const ItemWrapper = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	margin-right: ${props => props.marginRight ? props.marginRight[0] :  Spacing.SPACE_0};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: ${props => props.marginRight ? props.marginRight[1] :  Spacing.SPACE_0};
		&:first-child {
			justify-content: center;
			text-align: center;
			flex-basis: 100%;
			flex-grow: 1;
			flex-shrink: 0;
		}
	}
`;
const InnerBox = styled(Box)`
	background-color: ${palette.solidWhite};
	padding-top: ${px(Spacing.SPACE_24)};
	padding-bottom: ${px(Spacing.SPACE_24)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-left: ${px(Spacing.SPACE_24)};
		margin-right: ${px(Spacing.SPACE_24)};
	}
`;
const buildState = (props) => {

	let title = '';
	let link = '';
	let linkName = '';

	if (props.offline) {
		title = props.data.title;
		link = mapLinks([{
			url: props.data.link,
			navText: props.data.linkName,
		}], props.offline)[0].url;
		linkName = props.data.linkName;
	} else {

		try {

			let obj = props.data['Contact-boxData']['Contact-box'];

			title = obj.Title;
			link = mapLinks([{
				url: obj.Link.link.target,
				navText: obj.LinkName,
			}], props.offline)[0].url;
			linkName = obj.LinkName;
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		title: title,
		pageType: props.pageType,
		link: link,
		linkName: linkName
	};

};

class ContactBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('contact-box');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	render() {

		return (
			<Container>
				<InnerBox
					as="div"
					className="inner"
				>
					<InnerContainer direction={Direction.ROW} alignX={Alignment.CENTER}>
						<ItemWrapper marginRight={[px(Spacing.SPACE_12), px(Spacing.SPACE_0)]}>
							<Text as="h4">{this.state.title}</Text>
						</ItemWrapper>
						<ItemWrapper marginRight={[px(Spacing.SPACE_8), px(Spacing.SPACE_8)]}>
							<Icon iconComponent={PhoneHandle} primaryColor={IconColor.BLUE} title="ikona" />
						</ItemWrapper>
						<ItemWrapper>
							{generateLink(this.state.link, this.state.linkName, '', '', this.state.offline)}
						</ItemWrapper>
					</InnerContainer>
				</InnerBox>
			</Container>
		);
	}

}

export default ContactBox;
