/*!
 * mLeasing, article portlet :: 11/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {HorizontalLine} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {mapLinks} from '../../utils/map-links.js';
import Container from '../../components/container.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.8 :: r. 17/11/2021 @ vlk';
const ArticleContainer = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: ${props => props.align === 'center' ? 'center' : 'flex-start'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
	}
`;
const ArticleBox = styled(Box)`
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: ${props => props.align === 'center' ? px(866) : 'calc(100% - 333px)'};
	max-width: ${props => props.align === 'center' ? px(866) : '100%'};
	padding: ${px(Spacing.SPACE_24)};
	margin-right: ${props => props.align === 'center' ? px(Spacing.SPACE_0) : px(Spacing.SPACE_24)};
	background-color: ${props => props.background === 'light' || props.background === 'dark-light' ? palette.solidWhite : palette.wildSandGray};
	box-shadow: ${props => props.background === 'dark-light' || props.background === 'light-dark' ? `${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08)` : '0'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding: ${px(Spacing.SPACE_24)} ${px(Spacing.SPACE_16)};
		margin-right: ${px(Spacing.SPACE_0)};
		box-shadow: ${px(Spacing.SPACE_0)};
	}
`;
const InnerBox = styled.div`
	max-width: 100%;
`;
const LinkBox = styled(Box)`
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: ${px(309)};
	padding: ${px(Spacing.SPACE_24)};
	max-width: ${px(309)};
	background-color: ${props => props.background === 'dark' || props.background === 'dark-light' ? palette.solidWhite : palette.wildSandGray};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-width: 100%;
		margin-top: ${px(Spacing.SPACE_40)};
	}
`;
const TextBox = styled(Box)`
	width: 100%;
`;
const TitleRow = styled.div`
	display: flex;
	align-items: center;
	padding-top: ${px(Spacing.SPACE_12)};
	padding-bottom: ${px(Spacing.SPACE_12)};
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 16px;
	}
`;
const ImageBox = styled(Box)`
	margin-top: ${px(Spacing.SPACE_16)};
	margin-bottom: ${px(Spacing.SPACE_16)};
	max-width: 100%;
`;
const linkStyles = `
	color: ${palette.zeusBlackUI};
`;
const LinkItem = styled.div`
	margin-top: ${px(Spacing.SPACE_12)};
	margin-bottom: ${px(Spacing.SPACE_12)};
	font-weight: 500;
`;
const Separator = styled.div`
	height: ${px(Spacing.SPACE_16)};
`;
const ButtonLink = styled.div`
	text-align: center;
	padding-top: ${px(Spacing.SPACE_16)};
`;
const MarkContainer = styled.div`
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-bottom: 16px;
	}
`;
const FilterMark = styled.div`
	color: ${palette.solidWhite};
	background-color: ${palette.endeavourBlueUI};
	border-radius: 3px;
	padding-left: 4px;
	padding-right: 4px;
	margin-right: 8px;
	line-height: 1rem;
`;
const PopularMark = styled(FilterMark)`
	background-color: ${palette.flushOrange};
`;
const CategoryMark = styled(FilterMark)`
	background-color: ${palette.endeavourBlueUI};
`;
const buildState = (props, state) => {

	let	title = '';
	let	subTitle = '';
	let	date = '';
	let	text = '';
	let	image = '';
	let links = [];
	let isPopular = false;
	let background = '';
	let align = 'left';
	let altText = '';
	let bottomLink = '';
	let bottomLinkName = '';
	let categories = [];

	if (props.offline) {
		title = props.data.title;
		subTitle = props.data.subTitle;
		text = props.data.text;
		date = props.data.date;
		align = props.data.align;
		isPopular = props.data.isPopular === 'true' ? true : false;
		background = props.data.background;
		image = props.data.image;
		altText = props.data.altText || '';
		bottomLinkName = props.data.bottomLinkName ? props.data.bottomLinkName : '';
		bottomLink = props.data.bottomLink ? mapLinks([{
			url: props.data.bottomLink,
			navText: props.data.bottomLinkName,
		}], props.offline)[0].url : '';
		categories = props.data && props.data.category.length ? [''] : [];
		links = props.data && props.data.links.length ? [''] : []; // if there are links, let the box appear

	} else {

		try {

			let obj = props.data['ArticleData']['Article'];
			let textLinks = [];

			if (Array.isArray(obj.Text.links.link)) {

				textLinks = obj.Text.links.link.map(link => {
					return {
						name: link.name,
						target: link.target + (link.hasOwnProperty('query') ? "?"+link.query : '') || link.anchor
					};
				});

			} else if (obj.Text.links !== '') {

				textLinks = [{
					name: obj.Text.links.link.name,
					target: obj.Text.links.link.target + (obj.Text.links.link.hasOwnProperty('query') ? "?"+obj.Text.links.link.query : '') || obj.Text.links.link.anchor
				}];

			}

			title = obj.Title;
			subTitle = obj.SubTitle;
			background = obj.Background;
			align = props.data.Align;

			if (obj.Link) { // if there are links, let the box appear
				links = [''];
			}
			bottomLinkName = obj.BottomLinkName ? obj.BottomLinkName : '';
			bottomLink = obj.BottomLink ? mapLinks([{
				url: obj.BottomLink.link.target,
				navText: obj.BottomLinkName,
			}], props.offline)[0].url : '';
			
			// replace link href attribute representations with actual paths
			text = replaceCmsContentLinks(obj.Text.content, textLinks);
			date = obj.Date;
			isPopular = obj.IsPopular;
			image = obj.Image ? obj.Image.link.target.replace(environment.cmsExportPath, '') : '';
			altText = obj.AltText || '';
		} catch (err) {
			console.log(err);
		}
	}
	return {
		offline: state ? state.offline : props.offline,
		pageType: state ? state.pageType : props.pageType,
		title: (title == '') ? (state ? state.title : title) : title,
		subTitle: (subTitle == '') ? (state ? state.subTitle : subTitle) : subTitle,
		text: (text == '') ? (state ? state.text : text) : text,
		date: (date == '') ? (state ? state.date : date) : date,
		isPopular: (isPopular == false) ? (state ? state.isPopular : isPopular) : isPopular,
		align: (align == 'left') ? (state ? state.align : align) : align,
		background: (background == '') ? (state ? state.background : background) : background,
		links: state ? state.links : links,
		image: (image == '') ? (state ? state.image : image) : image,
		altText: (altText == '') ? (state ? state.altText : altText) : altText,
		bottomLinkName: (bottomLinkName == '') ? (state ? state.bottomLinkName : bottomLinkName) : bottomLinkName,
		bottomLink: (bottomLink == '') ? (state ? state.bottomLink : bottomLink) : bottomLink,
		categories: state ? state.categories : categories
	};
};

class Article extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderLinks = this.renderLinks.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('article');
		}

		// show authomatic popular links if article is news article or cms content based links
		// const url = 'https://test-mleasing11.qrqs.eu/api/paths/data.json'
		const url = (this.state.offline ? '/api/paths/' : environment.onlineApiPathsPath) + environment.apiPathsFileName;

		if (window.location.href.match(/\/aktualnosci/)) {
			fetch(url)
				.then(res => res.json())
				.then(json => {

					let paths = json.filter(path => path.match(/^\/aktualnosci/) && ! path.match(/index.html/) && !path.match(/^\/aktualnosci\/$/));
					let items = [];
					Promise.all(paths.map(path => {
						
						let url = environment.onlineApiPath.slice(0, -1) + path + 'index.html.json';

						if (this.state.offline) {
							url = path + '?__json=true';
						}
						return fetch(url)
							.then(res => res.json())
							.then(json => {
								let article;
								let categories = [];
								if (json['page'].hasOwnProperty('category') && (path == window.location.pathname)) { // get categories
									json['page'].category.forEach(obj => {
										for (let key in obj) {
											categories.push({
												name: key,
												title: obj[key]
											});
										}
									});
								}
								for (let key in json) {
									
									if (key.match(/article/)) {
										article = json[key];

										let title = '';
										let popular = false;
										let date = '';
										let obj = [];

										if (this.state.offline) {

											obj = JSON.parse(article);

											title = obj.title;
											popular = obj.isPopular;
											date = obj.date;
										} else {

											obj = article['ArticleData']['Article'];

											title = obj.Title;
											popular = obj.IsPopular;
											date = obj.Date;
										}
										
										let arr = [];

										if (obj.category && obj.category !== '[]') {
											arr = obj.category.replace(/\]/g, '').replace(/\[/g, '').split(',');
											arr = arr.map(cat => {
												
												let catArr = cat.split(':')[1].trim().replace('/sites/mleasing/.categories/', '').split('/');

												catArr = catArr.filter(c => c); // check for empty strings
												return catArr[catArr.length - 1];
											});
										}

										arr.forEach(cat => {
											
											if (!categories.includes(cat)) {
												categories.push({
													name: cat,
													title: cat
												});
											}

										});
										items.push({
											title: title,
											path: path,
											popular: popular,
											date: date,
											categories: categories
										});
									}

								}

							})
							.catch(err => console.log(err));
					})).then((res) => {

						// sort news by date
						const quickSort = (arr) => {
							
							const sort = (arr) => {
								
								let sorted = [arr[0]];
								let bigger = [];
								let lower = [];
								
								arr.forEach(el => {
									
									// js date needs reverted order 
									let elDateArr = el.date.split('/');
									let elDate = new Date(elDateArr[1] + '/' + elDateArr[0] + '/' + elDateArr[2]);
									let firstElDateArr = sorted[0].date.split('/');
									let firstElDate = new Date(firstElDateArr[1] + '/' + firstElDateArr[0] + '/' + firstElDateArr[2]);

									if (elDate > firstElDate) {
										bigger.push(el);
									}

									if (elDate < firstElDate) {
										lower.push(el);
									}
										
								});
								
								if (lower.length) {
									lower = sort(lower);
								}
								
								if (bigger.length) {
									bigger = sort(bigger);
								}
								
								sorted = lower.concat(sorted).concat(bigger);
								return sorted;
							}
							
							return sort(arr);
						}

						let sortedItems = quickSort(items).reverse();
						let popularItems = sortedItems.filter((item) => item.popular)
							.filter(item => item.path !== window.location.pathname)
							.filter((item, i) => i < 3)
							.map(item => ({
								path: item.path,
								title: item.title
							}));
						let links = [];

						links = popularItems.map(item => {
							return {
								linkName: item.title,
								link: mapLinks([{
									url: item.path,
									navText: item.title,
								}], this.state.offline)[0].url
							};
						});

						let categories = [];
						sortedItems.forEach(item => {
							item.categories.forEach(category => {
								
								let arr = categories.filter(cat => cat.name === category.name);

								if (!arr.length && item.path == window.location.pathname) {
									categories.push(category);
								}

							});
						});
						this.setState({
							offline: this.state.offline,
							pageType: this.state.pageType,
							title: this.state.title,
							subTitle: this.state.subTitle,
							text: this.state.text,
							date: this.state.date,
							isPopular: this.state.isPopular,
							align: this.state.align,
							background: this.state.background,
							links: links,
							image: this.state.image,
							altText: this.state.altText,
							bottomLink: this.state.bottomLink,
							bottomLinkName: this.state.bottomLinkName,
							categories: categories
						});

					});
				})
				.catch(err => console.log(err));
			} else {
				let links = [];

				if (this.props.offline) {
					links = this.props.data && this.props.data.links.map(item => {
						return {
							linkName: item.linkName,
							link: mapLinks([{
								url: item.link,
								navText: item.linkName,
							}], this.props.offline)[0].url
						};
					});
				} else {

					let obj = this.props.data['ArticleData']['Article'];

					if (obj.Link) {

						if (Array.isArray(obj.Link)) {
							links = obj.Link.map(item => {
								return {
									linkName: item.LinkName,
									link: mapLinks([{
										url: item.Link.link.target,
										navText: item.LinkName,
									}], this.props.offline)[0].url
								};
							});
						} else {
							links = [{
								linkName: obj.Link.LinkName,
								link: mapLinks([{
									url: obj.Link.Link.link.target,
									navText: obj.Link.LinkName,
								}], this.props.offline)[0].url
							}];
						}

					}

				}
				this.setState({
					offline: this.state.offline,
					pageType: this.state.pageType,
					title: this.state.title,
					subTitle: this.state.subTitle,
					text: this.state.text,
					date: this.state.date,
					isPopular: this.state.isPopular,
					align: this.state.align,
					background: this.state.background,
					links: links,
					image: this.state.image,
					altText: this.state.altText,
					bottomLink: this.state.bottomLink,
					bottomLinkName: this.state.bottomLinkName
				});
			}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	renderLinks(links) {

		if (links) {
			return links.map((item, i) => {
				return (
					<LinkItem as="div" key={'link-item-' + i}>
						{generateLink(this.props.prefix + item.link, item.linkName, linkStyles, '', '', this.state.offline)}
					</LinkItem>
				);
			})
		} else {
			return '';
		}

	}

	render() {
		return (
			<Container>
				<ArticleContainer align={this.state.align}>
					<InnerBox>
						<ArticleBox align={this.state.align} background={this.state.background}>
							<Text as="h2" marginBottom={4}>{this.state.title}</Text>
							<TitleRow>
								<MarkContainer>
									{this.state.isPopular ? <PopularMark>
										<Text as="small" color="solidWhite">popularne</Text>
									</PopularMark> : ''}
									{this.state.categories.map((category, i) => {
										return (<CategoryMark key={'category-' + i}>
											<Text as="small" color="solidWhite">{category.title}</Text>
										</CategoryMark>);
									})}
								</MarkContainer>
								<Text as="small" marginLeft={Spacing.SPACE_8}>
									{this.state.date}
								</Text>
							</TitleRow>
							<Text as="h4" marginTop={12} marginBottom={12}>{this.state.subTitle}</Text>
							{this.state.image && <ImageBox>
								<Image maxWidth="100%" src={this.state.image} alt={this.state.altText} title={this.state.title} />
							</ImageBox>}
							<TextBox as="div">{componentsFromHtml(this.state.text, this.state.offline)}</TextBox>
						</ArticleBox>
						{this.state.bottomLink ? generateLink(this.props.prefix + this.state.bottomLink, '', '', (<ButtonLink>
							<Button buttonType="primary">{this.state.bottomLinkName}</Button>
						</ButtonLink>), this.state.offline) : ''}
					</InnerBox>
					{this.state.links.length ? <LinkBox background={this.state.background}>
						<Text as="h4">popularne artykuły</Text>
						<Separator />
						<HorizontalLine />
						<Separator />
						{this.renderLinks(this.state.links)}
					</LinkBox> : ''}
				</ArticleContainer>
			</Container>
		);
	}

}

export default Article;
