/*!
 * mLeasing, exp-content portlet :: 12/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {createHistory} from '@reach/router';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Accordion} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {IconColor} from '@mbank-design/design-system/enums';
import {AccordionColor} from '@mbank-design/design-system/enums';
import {Download} from '@mbank-design/design-system/icons';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import Container from '../../components/container.js';
import {replaceCmsContentLinks } from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {mapLinks} from '../../utils/map-links.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.4 :: r. 28/05/2021 @ fs';
const FlexContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;
	justify-content: ${props => props.align === 'center' ? 'center' : 'flex-end'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const FlexBox = styled(FlexContainer)`
	justify-content: center;
	align-items: center;
	padding-top: ${px(Spacing.SPACE_12)};
`;
const Column = styled(Box)`
	max-width: ${props => props.align === 'center' ? px(866) : 'unset'};
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	text-align: left;
`;
const ImageWrapper = styled.div`
	width: 100%;
	max-width: ${px(420)};
`;
const LinkBox = styled(Box)`
	display: flex;
	margin-top: ${px(Spacing.SPACE_16)};
	margin-bottom: ${px(Spacing.SPACE_16)};
`;
const BoxContainer = styled(Box)`
	display: flex;
	flex-wrap: nowrap;
`;
const InnerBox = styled(Box)`
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
`;
const ImageBox = styled(Box)`
	flex-basis: ${px(Spacing.SPACE_48)};
	flex-grow: 0;
	flex-shrink: 0;
`;
const TextBox = styled(Box)`
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: ${px(Spacing.SPACE_24)};
`;
const FakeLink = styled.h4`
	color: ${palette.endeavourBlueUI};
	margin-top: ${Spacing.SPACE_0};
	margin-bottom: ${Spacing.SPACE_0};
`;
const ImageLinkBox = styled.div`
	position: relative;
`;
const ImageLinkButton = styled(Button)`
	position: absolute;
	bottom: ${px(Spacing.SPACE_56)};
	left: 50%;
	transform: translate(-50%);
`;
const isActive = (anchor, hash) => {

	if (hash) {

		if (anchor === hash) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}

};
const buildState = (props, state) => {

	let title = '';
	let text = '';
	let items = [];
	let image = '';
	let background = 'light';
	let align = 'left';
	let altText = '';

	if (props.offline) {
		title = props.data.title;
		text = props.data.text;
		image = props.data.image || '';
		altText = props.data.altText;
		background = props.data.background || 'light';
		align = props.data.align || 'left';
		items = props.data && props.data.items.map(item => {
			return {
				title: item.title,
				text: item.text,
				anchor: item.anchor,
				links: item.links ? item.links.map(link => {
					return {
						linkName: link.linkName,
						link: mapLinks([{
							url: link.link,
							navText: link.linkName,
						}], props.offline)[0].url,
					};
				}) : [],
				image: item.image || '',
				altText: item.altText || '',
				imageLinkName: item.imageLinkName || '',
				active: false,
				imageLink: item.imageLink ? mapLinks([{
					url: item.imageLink,
					navText: item.imageLinkName,
				}], props.offline)[0].url : '',
				boxes: item.boxes ? item.boxes.map(box => {
					return {
						text: box.text,
						icon: box.icon,
						altText: box.altText,
						linkName: box.linkName,
						link: mapLinks([{
							url: box.link,
							navText: box.linkName,
						}], props.offline)[0].url,

					};
				}) : []
			};
		});
	} else {

		try {

			let obj = props.data['Exp-contentData']['Exp-content'];

			title = obj.Title;
			text = obj.Text ? obj.Text.content : '';
			image = obj.Image ? obj.Image.link.target.replace(environment.cmsExportPath, '') : '';
			altText = obj.AltText || '';
			background = obj.Background || 'light';
			align = props.data.Align || 'left';

			if (obj.Item) {

				if (Array.isArray(obj.Item)) {
					items = obj.Item.map((item, i) => {

						let textLinks = [];
						let title = item.Title || '';
						let text = '';
						let anchor = item.Anchor || '';
						let links = [];
						let boxes = [];

						if (item.Text) {

							if (Array.isArray(item.Text.links.link)) {

								textLinks = item.Text.links.link.map(link => {
									return {
										name: link.name,
										target: link.target || link.anchor
									};
								});

							} else if (item.Text.links !== '') {

								textLinks = [{
									name: item.Text.links.link.name,
									target: item.Text.links.link.target || item.Text.links.link.anchor
								}];

							}

							text = replaceCmsContentLinks(item.Text.content, textLinks);
						}

						if (item.Link) {

							if (Array.isArray(item.Link)) {
								links = item.Link.map(link => {
									return {
										linkName: link.LinkName,
										link: link.Link ? mapLinks([{
											url: link.Link.link.target,
											navText: link.LinkName,
										}], props.offline)[0].url : ''
									};
								});
							} else {
								links = [{
									linkName: item.Link.LinkName,
									link: item.Link.Link ? mapLinks([{
										url: item.Link.Link.link.target,
										navText: item.Link.LinkName,
									}], props.offline)[0].url : ''
								}];
							}

						}

						if (item.Box) {

							if (Array.isArray(item.Box)) {
								boxes = item.Box.map(box => {
									return {
										text: box.Text,
										icon: box.Icon ? box.Icon.link.target.replace(environment.cmsExportPath, '') : '',
										altText: box.AltText || '',
										linkName: box.LinkName,
										link: box.Link ? mapLinks([{
											url: box.Link.link.target,
											navText: box.LinkName,
										}], props.offline)[0].url : ''
									};
								});
							} else {
								boxes = [{
									text: item.Box.Text,
									icon: item.Box.Icon ? item.Box.Icon.link.target.replace(environment.cmsExportPath, '') : '',
									altText: item.Box.AltText || '',
									linkName: item.Box.LinkName,
									link: item.Box.Link ? mapLinks([{
										url: item.Box.Link.link.target,
										navText: item.Box.LinkName,
									}], props.offline)[0].url : ''
								}];
							}

						}

						return {
							title: title,
							text: text,
							anchor: anchor,
							links: links,
							boxes: boxes,
							image: item.Image ? item.Image.link.target.replace(environment.cmsExportPath, '') : '',
							altText: item.AltText || '',
							active: state && state.hash ? isActive(anchor, state.hash) : false,
							imageLink: item.ImageLink ? mapLinks([{
								url: item.ImageLink.link.target,
								navText: item.ImageLinkName,
							}], props.offline)[0].url : '',
							imageLinkName: item.ImageLinkName ? item.ImageLinkName : ''
						};
					});
				} else {

					let item = obj.Item;
					let textLinks = [];
					let title = item.Title || '';
					let text = '';
					let anchor = item.Anchor || '';
					let links = [];
					let boxes = [];

					if (item.Text) {

						if (Array.isArray(item.Text.links.link)) {

							textLinks = item.Text.links.link.map(link => {
								return {
									name: link.name,
									target: link.target || link.anchor
								};
							});

						} else if (item.Text.links !== '') {

							textLinks = [{
								name: item.Text.links.link.name,
								target: item.Text.links.link.target || item.Text.links.link.anchor
							}];

						}

						text = replaceCmsContentLinks(item.Text.content, textLinks);
					}

					if (item.Link) {

						if (Array.isArray(item.Link)) {
							links = item.Link.map(link => {
								return {
									linkName: link.LinkName,
									link: link.Link ? mapLinks([{
										url: link.Link.link.target,
										navText: link.LinkName,
									}], props.offline)[0].url : ''
								};
							});
						} else {
							links = [{
								linkName: item.Link.LinkName,
								link: item.Link ? mapLinks([{
									url: item.Link.Link.link.target,
									navText: item.Link.LinkName,
								}], props.offline)[0].url : ''
							}];
						}

					}

					if (item.Box) {

						if (Array.isArray(item.Box)) {
							boxes = item.Box.map(box => {
								return {
									text: box.Text,
									icon: box.Icon ? box.Icon.link.target.replace(environment.cmsExportPath, '') : '',
									altText: box.AltText || '',
									linkName: box.LinkName,
									link: box.Link ? mapLinks([{
										url: box.Link.link.target,
										navText: box.LinkName,
									}], props.offline)[0].url : ''
								};
							});
						} else {
							boxes = [{
								text: item.Box.Text,
								icon: item.Box.Icon ? item.Box.Icon.link.target.replace(environment.cmsExportPath, '') : '',
								altText: item.Box.AltText || '',
								linkName: item.Box.LinkName,
								link: item.Box.Link ? mapLinks([{
									url: item.Box.Link.link.target,
									navText: item.Box.LinkName,
								}], props.offline)[0].url : ''
							}];
						}

					}

					items = [{
						title: title,
						text: text,
						anchor: anchor,
						links: links,
						boxes: boxes,
						image: item.Image ? item.Image.link.target.replace(environment.cmsExportPath, '') : '',
						altText: item.AltText || '',
						active: state && state.hash ? isActive(anchor, state.hash) : false,
						imageLink: item.ImageLink ? mapLinks([{
							url: item.ImageLink.link.target,
							navText: item.ImageLinkName,
						}], props.offline)[0].url : '',
						imageLinkName: item.ImageLinkName ? item.ImageLinkName : ''
					}];
				}

			}

		} catch (err) {
			console.log(err);
		}

	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		text: text,
		background: background,
		align: align,
		image: image,
		altText: altText,
		key: state ? state.key + '1' : 'accordion-', // change key of the accordion component to rerender it (show active anchor item)
		hash: state ? state.hash : '',
		items: items
	};

};

class ExpContent extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderAccordionItems = this.renderAccordionItems.bind(this);
		this.renderBoxes = this.renderBoxes.bind(this);
		this.renderBoxLink = this.renderBoxLink.bind(this);
		this.renderItemContent = this.renderItemContent.bind(this);
		this.updateState = this.updateState.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('exp-content');
		}

		let history = createHistory(window)

		let hash = window.location.hash;

		if (hash) {
			this.updateState(hash);
		}

		history.listen(() => {

			let hash = window.location.hash;

			if (hash) {
				this.updateState(hash);
			}

		});
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	updateState(hash) {
		this.setState({
			offline: this.state.offline,
			pageType: this.state.pageType,
			title: this.state.title,
			text: this.state.text,
			background: this.state.background,
			align: this.state.align,
			image: this.state.image,
			altText: this.state.altText,
			key: this.state.key + '1', // change key of the accordion component to rerender it (show active anchor item)
			hash: hash,
			items: this.state.items.map(item => {
				return {
					title: item.title,
					text: item.text,
					anchor: item.anchor,
					links: item.links,
					boxes: item.boxes,
					image: item.image,
					altText: item.altText,
					active: isActive(item.anchor, hash),
					imageLink: item.imageLink,
					imageLinkName: item.imageLinkName
				};
			})
		});
	}

	renderBoxLink(box) {

		if (box.link) {
			return generateLink(this.props.prefix + box.link, box.linkName, '', '', this.state.offline);
		} else if (box.linkName) {

			if (box.linkName.match(/@/g)) {

				const email = `mailto:${box.linkName}`;

				return <a href={email}>{box.linkName}</a>;
			} else {
				return <FakeLink>{box.linkName}</FakeLink>;
			}

		} else {
			return '';
		}
	}

	renderBoxes(boxes) {

		if (boxes) {
			return (
				<BoxContainer>{
					boxes.map((box, i) => {
						return (
							<InnerBox key={'box-' + i}>
								<ImageBox>
									<Image
										src={box.icon}
										alt={box.altText}
										title={box.text}
										width="100%"
									/>
								</ImageBox>
								<TextBox>
									<Text as="h4" marginTop={Spacing.SPACE_8} marginBottom={Spacing.SPACE_8}>{box.text}</Text>
									{this.renderBoxLink(box)}
								</TextBox>
							</InnerBox>
						);
					})
				}</BoxContainer>
			);
		} else {
			return '';
		}

	}

	renderItemContent(item) {
		return (<>
			<Box as="div" marginBottom={Spacing.SPACE_24}>{componentsFromHtml(item.text, this.state.offline)}</Box>
			{item.links ? item.links.map((link, i) => {
				return (<React.Fragment key={'link-' + i}>{
					generateLink(this.props.prefix + link.link, '', '', (
						<LinkBox as="div">
							<Icon iconComponent={Download} primaryColor={IconColor.BLUE} title="ikona" />
							<Box as="span" marginLeft={Spacing.SPACE_8}>{link.linkName}</Box>
						</LinkBox>
					), this.state.offline)
				}</React.Fragment>);
			}) : ''}
			{this.renderBoxes(item.boxes)}
			{item.image ? <Box>
				<Image
					src={item.image}
					alt={item.altText}
					title={item.title}
					width="100%"
				/>
				{item.imageLink ? <ImageLinkBox>{generateLink(this.props.prefix + item.imageLink, '', '', (
					<ImageLinkButton buttonType="primary">{item.imageLinkName}</ImageLinkButton>
				), this.state.offline)}</ImageLinkBox> : ''}
			</Box> : ''}
		</>);
	}

	renderAccordionItems() {
		return this.state.items.map((item, i) => {

			let options = {};

			if (item.active) {
				options['isDefaultOpen'] = true;
			}

			return (
				<Accordion.Item key={'accordion-item-' + i} {...options}>
					<Accordion.Header level={4}>{item.title}</Accordion.Header>
					<Accordion.Content>
						{this.renderItemContent(item)}
					</Accordion.Content>
				</Accordion.Item>
			);
		});
	}

	render() {
		return (
			<Container>
				<FlexContainer align={this.state.align}>
					<Column align={this.state.align}>
						{this.state.title ? <Text as="h2" align={['center', 'left']} marginBottom={Spacing.SPACE_24}>{this.state.title}</Text> : ''}
						{this.state.text && <Box as="div">{componentsFromHtml(this.state.text, this.state.offline)}</Box>}
						<Box>
							<Accordion layout="normal" key={this.state.key} color={this.state.background === 'dark' ? AccordionColor.WHITE : AccordionColor.GREY}>
								{this.renderAccordionItems(this.state.items)}
							</Accordion>
						</Box>
					</Column>
					{this.state.align === 'left' ? <Column>
						<FlexBox>
							{this.state.image && <ImageWrapper>
								<Image
									src={this.state.image}
									alt={this.state.altText}
									title={this.state.title}
									width="100%"
								/>
							</ImageWrapper>}
						</FlexBox>
					</Column> : ''}
				</FlexContainer>
			</Container>
		);
	}

}

export default ExpContent;
