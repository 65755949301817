/*!
 * mLeasing, video-box portlet :: 11/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Button} from '@mbank-design/design-system/components';
import {eventLogger} from '@mbank-design/design-system/utils';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {mapLinks} from '../../utils/map-links.js';
import Container from '../../components/container.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const Inner = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const TitleBox = styled(Box)`
	margin-top: ${px(Spacing.SPACE_24)};
	margin-bottom: ${px(Spacing.SPACE_8)};
	text-align: center;
`;
const TextBox = styled(Box)`
	margin-top: ${px(Spacing.SPACE_8)};
	margin-bottom: ${px(Spacing.SPACE_12)};
	text-align: center;
`;
const LinkBox = styled(Box)`
	margin-top: ${px(Spacing.SPACE_12)};
	text-align: center;
`;
const VideoFrame = styled.iframe`
	max-width: 100%;
`;
const buildState = (props) => {

	let	title = '';
	let	text = '';
	let	linkName = '';
	let	link = '';
	let	video = '';

	if (props.offline) {
		title = props.data.title;
		text = props.data.text;
		linkName = props.data.linkName;
		link = props.data.link && mapLinks([{
			url: props.data.link,
			navText: props.data.link
			}], props.offline)[0].url;
		video = props.data.video;
	} else {

		try {

			let obj = props.data['Video-boxData']['Video-box'];
			let textLinks = [];

			if (Array.isArray(obj.Text.links.link)) {

				textLinks = obj.Text.links.link.map(link => {
					return {
						name: link.name,
						target: link.target || link.anchor
					};
				});

			} else if (obj.Text.links !== '') {

				textLinks = [{
					name: obj.Text.links.link.name,
					target: obj.Text.links.link.target || obj.Text.links.link.anchor
				}];

			}

			title = obj.Title;
			// replace link href attribute representations with actual paths
			text = replaceCmsContentLinks(obj.Text.content, textLinks);
			linkName = obj.LinkName;
			link = obj.Link ? mapLinks([{
				url: obj.Link.link.target,
				navText: obj.LinkName
				}], props.offline)[0].url : '';
			video = obj.Video ? obj.Video : '';
		} catch (err) {
			console.log(err);
		}
	}

	video = video.replace('watch?v=', 'embed/');
	link = link.replace(environment.cmsExportPath, '');

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		text: text,
		linkName: linkName,
		link: link,
		video: video
	};

};

class VideoBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('video-box');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	render() {
		return (
			<Container>
				<Inner>
					<VideoFrame
						width="756"
						height="437"
						src={this.state.video}
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					>
					</VideoFrame>
					{this.state.title && <TitleBox>
						<Text as="h2">{this.state.title}</Text>
					</TitleBox>}
					{this.state.text && <TextBox>{componentsFromHtml(this.state.text, this.state.offline)}</TextBox>}
					{this.state.link && <LinkBox>
						{generateLink(this.props.prefix + this.state.link, '', '', (
							<Button buttonType='primary' onClick={eventLogger.click}>{this.state.linkName}</Button>
						), this.state.offline)}
					</LinkBox>}
				</Inner>
			</Container>
		);
	}

}

export default VideoBox;
