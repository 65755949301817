/*!
 * mLeasing, edge-menu portlet :: 20/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import { environment } from '../../../environment.js';
import { Text } from '@mbank-design/design-system/components';
import { Box } from '@mbank-design/design-system/components';
import { Image } from '@mbank-design/design-system/components';
import { Spacing } from '@mbank-design/design-system/enums';
import { px } from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import { mapLinks } from '../../utils/map-links.js';
import { generateLink } from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const EdgeMenuContainer = styled.div`
	position: fixed;
	right: ${Spacing.SPACE_0};
	top: ${px(200)};
	padding-top: ${px(Spacing.SPACE_12)};
	z-index: 2;
	background-color: ${palette.zeusBlackUI};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const ItemBox = styled(Box)`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-right: ${px(Spacing.SPACE_12)};
	padding-bottom: ${px(Spacing.SPACE_12)};
	padding-left: ${px(Spacing.SPACE_12)};
	max-width: ${px(Spacing.SPACE_72)};
	text-align: center;
`;
const ImageContainer = styled(Box)`
	margin-bottom: ${px(Spacing.SPACE_4)};
`;
const StyledLink = styled.a`
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;
const tileLinkStyles = `
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;
const buildState = (props) => {

	let items = [];

	// different json for offline and online call
	if (props.offline) {
		items = props.data.items ? props.data.items : [];
	} else {

		try {

			let obj = props.data['Edge-menuData']['Edge-menu'];

			items = obj.Item.map((item, i) => {
				return {
					link: mapLinks([{
						url: item.Link.link.target,
						navText: item.LinkName,
					}], props.offline)[0].url,
					icon: item.LinkIcon.link.target.replace(environment.cmsExportPath, ''),
					altText: item.AltText || '',
					linkName: item.LinkName
				};
			});
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		items: items
	};

};

class EdgeMenu extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderBox = this.renderBox.bind(this);
		this.fastCallClick = this.fastCallClick.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('edge menu');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	fastCallClick(e) {
		e.preventDefault();
		document.querySelector('.cp-widget-button__inner').click();
	}

	renderBox(item, i) {
		return (
			<ItemBox as="div" key={'edge-menu-item' + i}>
				{item.linkName !== 'szybki kontakt' ? generateLink(item.link, '', tileLinkStyles, '', this.state.offline) : ''}
				{item.linkName === 'szybki kontakt' ? <StyledLink
					className="fastcall"
					href="#"
					onClick={this.fastCallClick}
				/> : ''}
				<ImageContainer>
					<Image
						src={item.icon}
						alt={item.altText}
						title={item.linkName}
						maxWidth={px(Spacing.SPACE_24)}
						maxHeight={px(Spacing.SPACE_24)}
					/>
				</ImageContainer>
				<Text as="overline" color="solidWhite">{item.linkName}</Text>
			</ItemBox>
		);
	}

	render() {
		return (
			<EdgeMenuContainer>
				{this.state.items.map((item, i) => this.renderBox(item, i))}
			</EdgeMenuContainer>
		);
	}

}

export default EdgeMenu;
