/*!
 * mLeasing, contianer component :: 26/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from "react";
import {Box} from '@mbank-design/design-system/components';
import {px} from '@mbank-design/design-system/utils';
import {Spacing} from '@mbank-design/design-system/enums';

const version = 'v: 1.0.0 :: r. 18/02/2021 @ fs';
const getVersion = () => version;

export default function Container({ children }) {
	return (
	<Box
		maxWidth={px(1315)}
		marginTop={Spacing.SPACE_0}
		marginRight="auto"
		marginBottom={Spacing.SPACE_0}
		marginLeft="auto"
		className="container"
	>
		{children}
	</Box>
	);
}
