/*!
 * mLeasing, replaceCmsContentLinks utility :: 10/02/2021
 * replaces href attributes from generic cms json
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import {mapLinks} from './map-links.js';

const version = 'v: 1.0.0 :: r. 10/02/2021 @ fs';

export const replaceCmsContentLinks = (content, links) => {
	
	let str = content;

	links.forEach(link => {

		const target = mapLinks([{
			url: link.target,
			navText: link.name,
		}], false)[0].url;

		str = str.replace('\"%(' + link.name + ')\"', '"' + target + '"')
	});

	return str;
};

