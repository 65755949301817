/*!
 * mLeasing, scroll top button component :: 02/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from "react";
import styled from 'styled-components';
import {px} from '@mbank-design/design-system/utils';
import {Spacing} from '@mbank-design/design-system/enums';
import palette from '@mbank-design/design-system/palette';

const version = 'v: 1.0.0 :: r. 12/04/2021 @ fs';
const ButtonContainer = styled.div`
	position: relative;
	max-width: ${px(1315)};
	margin: 0 auto;
`;
const CustomButton = styled.button`
	position: absolute;
	right: ${Spacing.SPACE_0};
	bottom: ${px(Spacing.SPACE_36)};
	width: ${px(Spacing.SPACE_56)};
	height: ${px(Spacing.SPACE_56)};
	border-radius: 50%;
	background-color: ${palette.endeavourBlueUI};
	background-image: url(/.templates/img/icons/arrow-scatter.svg);
	background-repeat: no-repeat;
	background-position: center;
	border: 0;
	cursor: pointer;
`;

class ScrollTopButton extends React.Component {

	constructor() {
		super();
		this.getVersion = this.getVersion.bind(this);
		this.clickHandler = this.clickHandler.bind(this);
		this.state = {
			title: 'mLeasing',
		};
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('scroll top button');
		}
	}

	getVersion() {
		return version;
	}

	clickHandler() {

		if (window) {

			let scroll = window.scrollY;

			if (scroll > 0 ) {

				let interval = setInterval(() => {
				
					scroll = scroll - 100;
					window.scrollTo(0, scroll);

					if (scroll < 0 ) {
						clearInterval(interval);
					}

				}, 10);

			}
		}
	}

	render() {
		return (
			<ButtonContainer>
				<CustomButton onClick={this.clickHandler} />
			</ButtonContainer>
		);
	}

}

export default ScrollTopButton;
