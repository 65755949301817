/*!
 * mLeasing, componentsFromHtml utility :: 15/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import {Suspense, lazy} from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';
import palette from '@mbank-design/design-system/palette';
import {Text} from '@mbank-design/design-system/components';
import {Table} from '@mbank-design/design-system/components';
import {px} from '@mbank-design/design-system/utils';
import {generateLink} from './generate-link.js';

const version = 'v: 1.0.0 :: r. 15/04/2021 @ fs';
const UnorderedStyledList = styled.ul`
	padding-left: 36px;
	list-style: none;
`;
const ULItem = styled.li`
	position: relative;
	margin-bottom: ${px(16)};
	&::before {
		content: '';
		position: absolute;
		left: -24px;
		top: 8px;
		display: block;
		width: 8px;
		height: 8px;
		background-color: ${palette.endeavourBlueUI};
	}
`;
const OrderedStyledList = styled.ol`
	padding-left: 36px;
	list-style: none;
	counter-reset: list;
`;
const OLItem = styled.li`
	position: relative;
	margin-bottom: ${px(16)};
	&::before {
		position: absolute;
		left: -${px(24)};
		counter-increment: list;
		content: counters(list, ".") ".";
		color: ${palette.endeavourBlueUI};
	}
`;

export const componentsFromHtml = (html, offline) => {

	// get array of html elemnts from text
	let arr;

	try {
		arr = html
			.replace(/\n/g, '')
			.match(/(<table.+?><\/table>)|(<p><small.+?><\/small><\/p>)|<(|p|h1|h2|h3|h4|h5|h6|ul|ol)>.+?<\/(p|h1|h2|h3|h4|h5|h6|ul|ol)>/g);
	} catch (err) {
		console.log(err);
		return '';
	}

	// map to objects of specific types
	arr = arr && arr.length ? arr.map(str => {

		if (str.match(/<table/)) {

			let matchId = str.match(/id=".+?"/),
				matchClass = str.match(/class=".+?"/),
				id = '', cssClass = 'default';
			
			if (matchId) {
				id = matchId[0].replace('id=\"', '').replace('\"', '');
			}
			if (matchClass) {
				cssClass = matchClass[0].replace('class=\"', '').replace('\"', '');
			}
			return {
				type: 'table',
				id: id,
				cssClass: cssClass,
				content: str.replace(/<table.?>/, '').replace(/<\/table>/, '')
			};
		}
		if (str.match(/<small/)) {
			let content = str.replace(/<p><small.?>/, '').replace(/<\/small><\/p>/, '');

			if (content === '&nbsp;') {
				content = '<br/>';
			}
			return {
				type: 'small',
				content: content
			};
		}
		if (str.match(/<p/)) {
			let content = str.replace(/<p.?>/, '').replace(/<\/p>/, '');

			if (content === '&nbsp;') {
				content = '<br/>';
			}
			return {
				type: 'p',
				content: content
			};
		}
		if (str.match(/<h1/)) {
			return {
				type: 'h1',
				content: str.replace(/<h1.?>/, '').replace(/<\/h1>/, '')
			};
		}
		if (str.match(/<h2/)) {
			return {
				type: 'h2',
				content: str.replace(/<h2.?>/, '').replace(/<\/h2>/, '')
			};
		}
		if (str.match(/<h3/)) {
			return {
				type: 'h3',
				content: str.replace(/<h3.?>/, '').replace(/<\/h3>/, '')
			};
		}
		if (str.match(/<h4/)) {
			return {
				type: 'h4',
				content: str.replace(/<h4.?>/, '').replace(/<\/h4>/, '')
			};
		}
		if (str.match(/<h5/)) {
			return {
				type: 'h5',
				content: str.replace(/<h5.?>/, '').replace(/<\/h5>/, '')
			};
		}
		if (str.match(/<h6/)) {
			return {
				type: 'h6',
				content: str.replace(/<h6.?>/, '').replace(/<\/h6>/, '')
			};
		}

		if (str.match(/<ul/)) {
			return {
				type: 'ul',
				content: str.replace(/<ul.?>/, '').replace(/<\/ul>/, '')
			};
		}
		if (str.match(/<ol/)) {
			return {
				type: 'ol',
				content: str.replace(/<ol.?>/, '').replace(/<\/ol>/, '')
			};
		}
		return;
	}) : [];

	// replace brs
	const parseBRs = text => {

		let arr = text.split('<br');

		arr = arr.map(item => item.replace('/>', '').replace('>', ''));

		return arr.map((item, i) => {
			return (
				<React.Fragment key={'item-' + i}>
					{item.replace('&nbsp;', '')}
					{i < arr.length - 1 ? <br /> : ''}
				</React.Fragment>
			);
		});

	};

	const parseBolds = (text) => {

		let arr = text.split('<strong');

		if (arr[0] === '') {
			arr.shift();
		}

		return arr.map((item, i) => {

			let str = '';

			try {

				str = item.match(/^.+?\/strong>/)[0];
				text = str.match(/>.+?</)[0].replace('>', '').replace('<', '');

			} catch (err) {
				return parseBRs(item);
			}

			return (
				<React.Fragment key={'item-' + i}>
					<strong>{text}</strong>
					{parseBRs(item.replace(/^.+?\/strong>/, ''))}
				</React.Fragment>
			);
		});
	};

	// replace simple anchors with Link components
	const parseLinks = (text) => {

		let arr = text.split('<a');

		if (arr[0] === '') {
			arr.shift();
		}

		return arr.map((item, i) => {

			let str = '';
			let href = '';
			let linkName = '';

			try {

				str = item.match(/^.+?\/a>/)[0];
				// href = str.replace('href="', '').replace('">', '');
				href = str.match(/href=".+?"/)[0].replace('href="', '').replace('"', '');
				linkName = str.match(/>.+?</)[0].replace('>', '').replace('<', '');

			} catch (err) {
				return parseBolds(item);
			}

			return (
				<React.Fragment key={'item-' + i}>
					{generateLink(href, linkName, '', '', offline)}
					{parseBolds(item.replace(/^.+?\/a>/, ''))}
				</React.Fragment>
			);
		});
	};

	// replace lisis with styled lists
	const parseLists = (obj, index) => {

		let arr = obj.content.split('<li>');

		if (arr[0] === '') {
			arr.shift();
		}

		if (obj.type === 'ul') {
			return (
				<UnorderedStyledList key={'ul-' + index}>
					{arr.map((listItem, i) => <ULItem key={'li-' + i}>{parseLinks(listItem.replace('</li>', '').replace('&nbsp;', ''))}</ULItem>)}
				</UnorderedStyledList>
			);
		} else {
			return (
				<OrderedStyledList key={'ol-' + index}>
					{arr.map((listItem, i) => <OLItem key={'li-' + i}>{parseLinks(listItem.replace('</li>', '').replace('&nbsp;', ''))}</OLItem>)}
				</OrderedStyledList>
			);
		}
	};

	let TableComponent = styled.div`
	`;

	const renderTable = (item, i) => {

		const tables = ['cookies-companies', 'mleasing-cookies', 'mauto-cookies', 'portal-klienta-cookies', 'portal-aukcyjny-cookies'];	//predefind tables (includes)
		const id = item.id;
		const cssClass = item.cssClass;

		if (tables.includes(id)) {
			// can't use variable, webpack needs path beforehand
			tables.some(tid => {

				if (id === tid) {
					TableComponent = loadable(() => import(`../includes/tables/${tid}.js`));
					return true;
				}
			});

			return <TableComponent key={'table-' + i} />
		}
		else {
			return <Table id={id} className={cssClass} dangerouslySetInnerHTML={{__html: item.content}} />
		}
	};

	return (
		<>
			{arr.map((item, i) => {

				if (item.type.match(/p/) || item.type.match(/h\d/) || item.type.match(/small/)) {
					return <Text as={item.type} key={'item-' + i}>{parseLinks(item.content)}</Text>;
				} else if (item.type.match(/table/)) {
					return renderTable(item, i)
				} else {
					return parseLists(item, i);
				}
			})}
		</>
	);
};
