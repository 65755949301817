/*!
 * mLeasing, text-box portlet :: 07/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {DesignSystemThemeProvider} from '@mbank-design/design-system/providers';
import {DesktopApplicationGlobalStyles} from '@mbank-design/design-system/styles';
import {mBank} from '@mbank-design/design-system/themes';
import {Button} from '@mbank-design/design-system/components';
import {eventLogger} from '@mbank-design/design-system/utils';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {mapLinks} from '../../utils/map-links.js';
import Container from '../../components/container.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const Inner = styled(Box)`
	text-align: ${props => props.alignLeft ? 'left' : 'center'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		text-align: center;
	}
`;
const Wrapper = styled.div`
	max-width: ${props => props.wide ? px(952) : px(868)};
	max-width: ${props => props.alignLeft ? '100%' : '""'};
	display: flex;
	flex-wrap: no-wrap;
	align-items: ${props => props.wide ? 'center' : 'flex-start'};
	justify-content: center;
	margin: ${Spacing.SPACE_0} auto;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const ImageWrapper = styled.div`
	flex-basis: ${px(240)};
	flex-grow: 1;
	flex-shrink: 0;
	margin-right: ${px(Spacing.SPACE_68)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: ${Spacing.SPACE_0};
		width: 100%;
		display: flex;
		justify-content: center;
	}
`;
const buildState = (props) => {

	let	title = '';
	let textAlignLeft = false;
	let	text = '';
	let	linkName = '';
	let	link = '';
	let	image = '';
	let linkIsButton = true;
	let altText = '';

	if (props.offline) {
		title = props.data.title;
		textAlignLeft = props.data.textAlignLeft === 'true' ? true : false;
		text = props.data.text;
		linkName = props.data.linkName;
		link = props.data.link && mapLinks([{
			url: props.data.link,
			navText: props.data.link
			}], props.offline)[0].url;
		linkIsButton = props.data.linkIsButton === 'true' ? true : false;
		image = props.data.image;
		altText = props.data.altText || '';
	} else {

		try {

			let obj = props.data['Text-boxData']['Text-box'];
			let textLinks = [];

			if (Array.isArray(obj.Text.links.link)) {

				textLinks = obj.Text.links.link.map(link => {
					return {
						name: link.name,
						target: link.target || link.anchor
					};
				});

			} else if (obj.Text.links !== '') {

				textLinks = [{
					name: obj.Text.links.link.name,
					target: obj.Text.links.link.target || obj.Text.links.link.anchor
				}];

			}

			title = obj.Title;
			// replace link href attribute representations with actual paths
			textAlignLeft = obj.TextAlignLeft;
			text = replaceCmsContentLinks(obj.Text.content, textLinks);
			linkName = obj.LinkName;
			link = obj.Link ? mapLinks([{
				url: obj.Link.link.target,
				navText: obj.LinkName
				}], props.offline)[0].url : '';
			linkIsButton = obj.LinkIsButton;
			image = obj.Image ? obj.Image.link.target.replace(environment.cmsExportPath, '') : '';
			altText = obj.AltText || '';
		} catch (err) {
			console.log(err);
		}
	}

	return  {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		textAlignLeft: textAlignLeft,
		text: text,
		linkName: linkName,
		link: link,
		linkIsButton: linkIsButton,
		image: image,
		altText: altText
	};

};

class TextBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderLink = this.renderLink.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('text-box');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	renderLink() {

		if (this.state.link) {

			if (this.state.linkIsButton) {
				return (
					<Box as="div">
						{generateLink(this.props.prefix + this.state.link, '', '', (
							<Button buttonType='primary' onClick={eventLogger.click}>{this.state.linkName}</Button>
						), this.state.offline)}
					</Box>
				);
			} else {
				return (
					<Box as="div">
						{generateLink(this.props.prefix + this.state.link, this.state.linkName, '', '', this.state.offline)}
					</Box>
				);
			}
		} else {
			return '';
		}
	
	}

	render() {
		let img = '';

		if (this.state.image) { // render image only if exists in data
			img = (<ImageWrapper>
					<Image
						src={this.state.image}
						alt={this.state.altText}
						title={this.state.title}
						maxWidth={240}
					/>
				</ImageWrapper>);
		} 

		let isFastcall = this.state.text.includes('id="fastcall"');

		if(isFastcall) {
			if(typeof window !== 'undefined') {
				const script = document.createElement('script');
				script.src = "https://fc10.3way.pl/mleasingkonsultanci/fastcall-form.min.js";
				script.async = true;
				document.body.appendChild(script);
			}
		}

		return (
			<DesignSystemThemeProvider theme={mBank}>
				<DesktopApplicationGlobalStyles />
				<Container>
					<Wrapper 
						wide={this.state.image ? true : false} 
						alignLeft={this.state.textAlignLeft ? true : false}
					>
						{img}
						<Inner
							as="div"
							className="inner"
							align={this.state.image ? 'left' : 'center'}
							alignLeft={this.state.textAlignLeft ? true : false}
						>
							<Box as="div">
								<Text as="h2">{this.state.title}</Text>
								{/* probably necessary to use parese for html to render Text compoments */}
							</Box>
							<Box as="div" marginTop={Spacing.SPACE_16} marginBottom={Spacing.SPACE_16}>{componentsFromHtml(this.state.text, this.state.offline)}</Box>
							{isFastcall && <div id="fastcall" data="Umleasingkonsultanci"></div>}
							{this.renderLink()}
						</Inner>
					</Wrapper>
				</Container>
			</DesignSystemThemeProvider>
		);
	}

}

export default TextBox;
