/*!
 * mLeasing, menu box component :: 24/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {PhoneHandle} from '@mbank-design/design-system/icons';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {generateLink} from '../utils/generate-link.js';

const version = 'v: 1.0.0 :: r. 29/04/2021 @ fs';
const Wrapper = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	width: ${px(420)};
	padding: ${px(Spacing.SPACE_24)};
	background-color: ${palette.wildSandGray};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const PhoneNumber = styled.a`
	display: block;
	font-size: {px(Spacing.SPACE_24)};
	color: ${palette.eneavourBlue};
	font-weight: 600;
`;
const StyledLink = styled.a`
	display: block;
`;
let config = {};

config['oferta'] = {
	title: 'potrzebujesz finansowania?',
	link: '/zapytanie-o-finansowanie/',
	linkName: 'zapytaj o ofertę',
	subtitle: 'nie wiesz, co wybrać?',
	text: '',
	phoneNumber: '',
	contactLink: '/kontakt-i-placowki/',
	contactLinkName: 'zostaw swój kontakt',
	bottomText: 'a my oddzwonimy do Ciebie',
};
config['obsluga-klienta'] = {
	title: 'napisz do nas',
	link: '/kontakt-i-placowki/napisz-do-nas/',
	linkName: 'wyślij wiadomość',
	subtitle: 'zadzwoń',
	text: 'pon.-pt. 9:00-16:00',
	phoneNumber: '+48 22 3 600 600',
	contactLink: '',
	contactLinkName: '',
	bottomText: '',
};

class MenuBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.fastCallClick = this.fastCallClick.bind(this);

		const category = props.category.replace(/\//g, '')

		this.state = {
			offline: props.offline,
			category: category,
			title: config[category] ? config[category].title : '',
			link: config[category] ? config[category].link : '',
			linkName: config[category] ? config[category].linkName : '',
			subtitle: config[category] ? config[category].subtitle : '',
			text: config[category] ? config[category].text : '',
			phoneNumber: config[category] ? config[category].phoneNumber : '',
			contactLink: config[category] ? config[category].contactLink : '',
			contactLinkName: config[category] ? config[category].contactLinkName : '',
			bottomText: config[category] ? config[category].bottomText : '',

		}
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('menu-box');
		}
	}

	fastCallClick(e) {
		e.preventDefault();
		document.querySelector('.fastcall-widget').click();
	}

	render() {
		return (
			<Wrapper>
				<Box>
					<Text as="h3">{this.state.title}</Text>
					<Box marginTop={Spacing.SPACE_16} marginBottom={Spacing.SPACE_24}>
						{generateLink(this.state.link, '', '', (
							<Button buttonType="primary">{this.state.linkName}</Button>
						), this.state.offline)}
					</Box>
					<Text as="h3">{this.state.subtitle}</Text>
					{this.state.text ? <Box marginTop={Spacing.SPACE_4} marginBottom={Spacing.SPACE_8}>
						<Text as="small" color="doveGrayUI">{this.state.text}</Text>
					</Box> : ''}
					{this.state.phoneNumber ? <PhoneNumber
						href={'tel:' + this.state.phoneNumber.replace(/\s/g, '')}
					>
						{this.state.phoneNumber}
					</PhoneNumber> : ''}
					{this.state.contactLinkName ? <Box marginTop={Spacing.SPACE_8}>
						<StyledLink
							href="#"
							className="fastcall"
							onClick={this.fastCallClick}
						>
							<Button
								buttonType="tertiary"
								color="blue"
								iconComponent={PhoneHandle}
							>
								{this.state.contactLinkName}
							</Button>
						</StyledLink>
					</Box> : ''}
					{this.state.bottomText ? <Text as="p">{this.state.bottomText}</Text>: ''}
				</Box>
			</Wrapper>
		);
	}

}

export default MenuBox;
