/*!
 * mLeasing, map-box portlet :: 17/03/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled, {css} from 'styled-components';
import {Link} from '@reach/router';
import {environment} from '../../../environment.js';
import {Button} from '@mbank-design/design-system/components';
import {eventLogger} from '@mbank-design/design-system/utils';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Stack} from '@mbank-design/design-system/components';
import {TextField} from '@mbank-design/design-system/components';
import {Select} from '@mbank-design/design-system/components';
//import {RadioGroup, Radio} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {MapMarker} from '@mbank-design/design-system/icons';
import {MagnifyingGlass} from '@mbank-design/design-system/icons';
import {ChevronDown} from '@mbank-design/design-system/icons';
import {ArrowLeft} from '@mbank-design/design-system/icons';
import {Car} from '@mbank-design/design-system/icons';
import {InfiniteScroll} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {IconColor} from '@mbank-design/design-system/enums';
import {ButtonColor} from '@mbank-design/design-system/enums';
import {ValidationMessage} from '@mbank-design/design-system/components';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {mapLinks} from '../../utils/map-links.js';
import Container from '../../components/container.js';
// import MapContainer from '../../components/map-container.js';
import MapPopup from '../../components/map-popup.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {generateLink} from '../../utils/generate-link.js';
// import {LoadScript, StandaloneSearchBox} from '@react-google-maps/api';
// import './map.css';

const version = 'v: 1.0.3 :: r. 18/05/2021 @ fs';
const places = ['places'];
const googleMapsKey = environment.googleMapsKey;
const CenteredBox = styled.div`
	position: relative;
	text-align: ${props => props.type === 'services' ? 'left' : 'center'};
	margin-bottom: ${props => props.marginBottom || 0};
	max-width: ${props => props.type === 'services' ? px(644) : 'unset'};
	margin-right: auto;
	margin-left: auto;
`;
const MapBoxContainer = styled.div`
	position: relative;
	max-width: 100%;
`;
const MapHeader = styled.div`
	background-color: ${props => props.type === 'services' ? palette.wildSandGray : palette.solidWhite};
	padding-top: ${props => props.type === 'services' ? px(Spacing.SPACE_56) : px(Spacing.SPACE_48) };
	padding-bottom: ${props => props.type === 'services' ? px(Spacing.SPACE_32) : px(Spacing.SPACE_0) };

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-left: 16px;
		padding-right: 16px;
	}
`;
const FlexContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		width: 100%;
	}
`;
const RadioGroup = styled.div`
	display: flex;
	align-items: center;
	margin-right: 16px;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-top: 16px;
		margin-right: 0;
		justify-content: center;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-top: 16px;
		margin-bottom: 16px;
		justify-content: center;
	}
`;
const FlexHeader = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding-top: 32px;
	padding-bottom: 32px;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		padding-left: 16px;
		padding-right: 16px;
	}
`;
const ShortFlexContainer = styled(FlexContainer)`
	max-width: ${px(1315)};
	margin: 0 auto;
`;
const LeftColumn = styled.div`
	flex-basis: 33.33%;
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: ${px(Spacing.SPACE_32)} ${px(Spacing.SPACE_24)};
	background-color: ${palette.wildSandGray};
	max-height: 824px;
	overflow: auto;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		order: 1;
		align-items: center;
		padding: ${px(Spacing.SPACE_24)} ${px(Spacing.SPACE_16)};
	}
`;
const RightColumn = styled.div`
	flex-basis: 66.66%;
	flex-grow: 1;
	flex-shrink: 1;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		order: 0;
	}
`;
const OptionsContainer = styled.div`
	position: absolute;
	z-index: 2;
	width: 100%;
	max-height: ${px(288)};
	overflow: auto;
	border: 1px solid ${palette.altoGray};
`;
const MapSearchBox = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: ${px(Spacing.SPACE_32)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding: ${Spacing.SPACE_0} ${px(Spacing.SPACE_16)};
	}
`;
const ShortSearchBox = styled(MapSearchBox)`
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;
const SearchTextField = styled(TextField)`
	margin-right: ${px(Spacing.SPACE_24)};
	width: ${px(644)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: 100%;
		margin-right: ${px(Spacing.SPACE_0)};
		margin-bottom: ${px(Spacing.SPACE_16)};
	}
`;
const ShortSearchTextFieldContainer = styled.div`
	width: ${px(420)};
	display: flex;
	align-items: center;
	position: relative;
	background-color: rgb(255, 255, 255);
	border: 2px solid rgb(216, 216, 216);
	border-radius: 0px;
	display: inline-flex;
	height: 62px;
	margin-right: 24px;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: 100%;
		margin-right: 0;
	}
`;
const LongSearchTextFieldContainer = styled(ShortSearchTextFieldContainer)`
	width: ${px(644)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: 100%;
	}
`;
const ShortSearchTextField = styled.input`
	flex-grow: 1;
	flex-shrink: 0;
	border: 0;
	height: 100%;
`;
const TextFieldIconContainer = styled.div`
	margin-top: 8px;
	margin-left: 16px;
	margin-right: 16px;
`;
const TyresTextFieldContainer = styled(TextField)`
	max-width: ${px(335)};
	margin-right: ${px(Spacing.SPACE_24)};
`;
const TyresTextField = styled(TextField)`
	max-width: ${px(335)};
`;
const ItemBox = styled.div`
	width: ${px(397)};
	padding: ${px(Spacing.SPACE_24)};
	margin-bottom: ${px(Spacing.SPACE_16)};
	background-color: ${palette.solidWhite};
	box-shadow: ${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08);

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: 100%;
	}
`;
const FilterBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: ${px(Spacing.SPACE_32)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-wrap: nowrap;
		overflow: auto;
		justify-content: flex-start;
	}
`;
const Filter = styled.a`
	display: block;
	margin-right: ${px(Spacing.SPACE_8)};
	margin-left: ${px(Spacing.SPACE_8)};
	color: ${props => props.active ? palette.endeavourBlueUI : palette.zeusBlackUI};
	border-bottom: 2px solid ${props => props.active ? palette.endeavourBlueUI : palette.solidWhite};

	${props => props.active ? css`
		color: ${palette.endeavourBlueUI};
		text-decoration: none !important;
	` : css`
		color: ${palette.zeusBlackUI};
		text-decoration: none !important;
	`};
`;
const FormSelectWrapper = styled.div`
	position: relative;
	flex-grow: 1;
	flex-shrink: 0;
	margin-right: ${px(Spacing.SPACE_24)};
	max-width: ${px(420)};
	width: ${px(420)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-width: 100%;
		width: 100%;
		margin-left: 0;
	}
`;
const FormSelect = styled(TextField)`
	width: ${px(420)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: 100%;
	}
`;
const RadioContainer = styled.div`
	width: auto;
	flex-grow: 1;
	flex-shrink: 0;
	margin-right: ${px(24)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: 0;
		margin-top: 16px;
		margin-bottom: 16px;
	}
`;
const RadioGroupInner = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;
const ErrorMessage = styled.div`
	position: absolute;
	bottom: -27px;
	left: 0;
`;
const TextFieldContainer = styled.div`
	position: relative;
	flex-basis: ${px(335)};
	margin-right: ${px(Spacing.SPACE_24)};
`;
const BackLinkContainer = styled.div`
	position: absolute;
	top: -${px(Spacing.SPACE_16)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		top: -${px(Spacing.SPACE_32)};
		left: 0;
	}
`;
const backLinkStyles = `
	display: flex;
	align-items: center;
	color: ${palette.solidWhite};
	&:hover {
		color: ${palette.solidWhite};
	}
`;
const LoadedMap = styled.div`
	height: 824px;
`;
const buildState = (props, state) => {

	const searchBox = null;
	let title = '';
	let text = '';
	let type = '';

	if (props.offline) {
		title = props.data.title || '';
		text = props.data.text || '';
		type = props.data.type;
	} else {

		try {

			let obj = props.data['Map-boxData']['Map-box'];
			let textLinks = [];

			if (Array.isArray(obj.Text.links.link)) {

				textLinks = obj.Text.links.link.map(link => {
					return {
						name: link.name,
						target: link.target || link.anchor
					};
				});

			} else if (obj.Text.links !== '') {

				textLinks = [{
					name: obj.Text.links.link.name,
					target: obj.Text.links.link.target || obj.Text.links.link.anchor
				}];

			}

			title = obj.Title || '';
			text = obj.Text ? replaceCmsContentLinks(obj.Text.content, textLinks) : '';
			type = props.data.Type || 'agencies';
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		text: text,
		type: type, // services, agencies, registration
		data: state ? state.data : [],
		mapData: state ? state.mapData : [],
		bounds: state ? state.bounds : {
			La: {
				g: '',
				i: ''
			},
			Sa: {
				g: '',
				i: ''
			}
		},
		position: state ? state.position : {
			lat: null,
			lng: null
		},
		origin: state ? state.origin : '',
		destination: state ? state.destination : '',
		filters: state ? state.filters : [],
		vehicleBrand: state ? state.vehicleBrand : {
			label: '',
			value: ''
		},
		vehicleBrands: state ? state.vehicleBrands : [],
		activeTab: state ? state.activeTab : '',
		searchType: state ? state.searchType : 'service',
		registrationNumber: state ? state.registrationNumber : '',
		registrationNumberErrorMessage: state ? state.registrationNumberErrorMessage : '',
		vin: state ? state.vin : '',
		vinErrorMessage: state ? state.vinErrorMessage : '',
		tyreInfo: state ? state.tyreInfo : '',
		showPopup: state ? state.showPopup : false
	};

};

class MapBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		// this.getData = this.getData.bind(this);
		// this.getBounds = this.getBounds.bind(this);
		this.reduceMapData = this.reduceMapData.bind(this);
		// this.setLocation = this.setLocation.bind(this);
		this.onChange = this.onChange.bind(this);
		this.loadStandaloneSearchBox = this.loadStandaloneSearchBox.bind(this);
		this.onPlacesChanged = this.onPlacesChanged.bind(this);
		this.setPointLocation = this.setPointLocation.bind(this);
		// this.setDestination = this.setDestination.bind(this);
		this.renderFilters = this.renderFilters.bind(this);
		this.createFilters = this.createFilters.bind(this);
		this.changeVehicleBrand = this.changeVehicleBrand.bind(this);
		this.updateState = this.updateState.bind(this);
		this.createVehicleBrands = this.createVehicleBrands.bind(this);
		this.changeSearchType = this.changeSearchType.bind(this);
		this.searchCar = this.searchCar.bind(this);
		this.setRegistrationNumber = this.setRegistrationNumber.bind(this);
		this.setVin = this.setVin.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.validateRegistrationNumber = this.validateRegistrationNumber.bind(this);
		this.validateVin = this.validateVin.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('map-box');
		}

		const scriptPaths = [
			"https://maps.googleapis.com/maps/api/js?key=AIzaSyAmOa-3O-pwynmMX8SGl6Yd0SgaLFsGFa8&libraries=places",
			"/.templates/js/jquery.min.js",
			//"/.templates/js/jquery.nicescroll.min.js",
			"/.templates/js/markerclusterer.min.js",
			"/.templates/js/map.js",
		];

		window.onlineApiPath = environment.onlineApiPath;
		const loadScript = (path, i) => {

			let timeout = setTimeout(() => {

				if (path.match(/map\.js/g) && (typeof $ === 'undefined' || typeof google === 'undefined')) {
					loadScript(path);
				} else {

					const script = document.createElement('script');

					script.src = path;
					script.defer = true;
					document.body.appendChild(script);
				}

				if (i === scriptPaths.length - 1) {
					document.querySelector('#map-form-container').style.display = 'flex';
				}
				
				clearTimeout(timeout);
			}, 100);
		};

		scriptPaths.forEach((path, i) => {
			loadScript(path, i);
		});
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	shouldComponentUpdate() {
		return false;
	}

	updateState(key, value, msgKey, message) {
		this.setState({
			offline: this.state.offline,
			pageType: this.state.pageType,
			title: this.state.title,
			text: this.state.text,
			type: this.state.type, // service, agency, registration
			data: key === 'data' ? value : this.state.data,
			mapData: key === 'mapData' ? value : this.state.mapData,
			bounds: key === 'bounds' ? value :this.state.bounds,
			position: key === 'position' ? value :this.state.position,
			origin: key === 'origin' ? value :this.state.origin,
			destination: key === 'destination' ? value :this.state.destination,
			filters: key === 'filters' ? value :this.state.filters,
			vehicleBrands: key === 'vehicleBrands' ? value : this.state.vehicleBrands,
			vehicleBrand: key === 'vehicleBrand' ? value : this.state.vehicleBrand,
			activeTab: key === 'activeTab' ? value : this.state.activeTab,
			searchType: key === 'searchType' ? value : this.state.searchType,
			registrationNumber: key === 'registrationNumber' ? value : this.state.registrationNumber,
			registrationNumberErrorMessage: msgKey === 'registrationNumberErrorMessage' ? message : this.state.registrationNumberErrorMessage,
			vin: key === 'vin' ? value : this.state.vin,
			vinErrorMessage: msgKey === 'vinErrorMessage' ? message : this.state.vinErrorMessage,
			tyreInfo: key === 'tyreInfo' ? value : this.state.tyreInfo,
			showPopup: key === 'showPopup' ? value : this.state.showPopup,
		});
	}

	/*
	getData() {

		const mapWorker = new Worker('/.templates/js/map-worker.js');
		let path = '';

		if (this.state.type === 'agencies') {
			path = environment.onlineApiPath + environment.agenciesApiPath;
		} 

		if (this.state.type === 'services') {
			path = environment.onlineApiPath + environment.servicePointsApiPath;
		} 

		mapWorker.postMessage(path);
		mapWorker.onmessage = (e) => {

			let data = e.data.map(point => ({
				adres: point.adres,
				email: point.email,
				geo_x: point.geo_x || point.lokalizacja_x,
				geo_y: point.geo_y || point.lokalizacja_y,
				id: point.id,
				nazwa: point.nazwa,
				nazwa_skrocona: point.nazwa_skrocona,
				nip: point.nip,
				siec: point.siec,
				telefon: point.telefon,
				typ: point.typ,
				active: false
			}));

			this.updateState('data', data);

			let timeout1 = setTimeout(() => {
				this.createFilters(0, true);
				this.createVehicleBrands();
				clearTimeout(timeout1);
			}, 0);
			let timeout2 = setTimeout(() => {
				this.updateState('mapData', this.reduceMapData(data, this.state.bounds));
				clearTimeout(timeout2);
			}, 0);

		}

	}
	*/

	/*
	getBounds(bounds) {

		let activePointArr = this.state.mapData.filter(point => point.active);
		let activePointId = activePointArr.length ? activePointArr[0].id : null;

		let data = this.state.data.map(point => ({
			adres: point.adres,
			email: point.email,
			geo_x: point.geo_x || point.lokalizacja_x,
			geo_y: point.geo_y || point.lokalizacja_y,
			id: point.id,
			nazwa: point.nazwa,
			nazwa_skrocona: point.nazwa_skrocona,
			nip: point.nip,
			siec: point.siec,
			telefon: point.telefon,
			typ: point.typ,
			active: point.id === activePointId ? true : false
		}));

		this.updateState('mapData', this.reduceMapData(data, this.state.bounds));

		let timeout1 = setTimeout(() => {
			this.updateState('bounds', bounds);
			clearTimeout(timeout1);
		}, 0);

	}
	*/

	changeVehicleBrand(value) {

		let brand = this.state.vehicleBrands.filter(sub => sub.value === value);

		if (brand.length) {
			brand = brand[0];
		} else {
			brand = {
				label: '',
				value: '',
				type: ''
			};
		}

		this.updateState('vehicleBrand', brand);

		let timeout1 = setTimeout(() => {
			this.updateState('mapData', this.reduceMapData(this.state.data, this.state.bounds));
			clearTimeout(timeout1);
		}, 0);

	}

	reduceMapData(data, bounds, index) {

		let mapData = [];

		if (bounds && bounds.getNorthEast) {

			const maxLng = bounds.getNorthEast().lng();
			const maxLat = bounds.getNorthEast().lat();
			const minLng = bounds.getSouthWest().lng();
			const minLat = bounds.getSouthWest().lat();

			mapData = data.reduce((acc, obj, i, arr) => {

				const lng = obj.geo_y ? parseFloat(obj.geo_y) : parseFloat(obj.lokalizacja_y);
				const lat = obj.geo_x ? parseFloat(obj.geo_x) : parseFloat(obj.lokalizacja_x);

				if (lat <= maxLat && lat >= minLat && lng <= maxLng && lng >= minLng) {
					return [...acc, obj];
				} else {
					return acc;
				}

			}, []);

		} else {
			mapData = data;
		}

		// apply service category filters
		if (this.state.filters.length && this.state.type === 'services') {

			let activeFilter;
			
			if (index || index === 0) {
				activeFilter = this.state.filters[index];
			} else {
				activeFilter = this.state.filters.filter(filter => filter.active)[0];
			}

			if (activeFilter) {
				mapData = mapData.filter(item => item.typ && item.typ.toLowerCase() === activeFilter.typ)
			}

		}

		// apply vehicle brand filter
		if (this.state.vehicleBrand.value) {
			mapData = mapData.filter(item => item.siec && item.siec.toLowerCase() === this.state.vehicleBrand.value.toLowerCase());
		}

		return mapData;
	}

	/*
	setLocation() {
		
		let coords = {};
		const success = position => {
			coords.lat = position.coords.latitude;
			coords.lng = position.coords.longitude;

			let timeout = setTimeout(() => {
				this.updateState('coords', coords);
				clearTimeout(timeout);
			}, 100);

		};
		const error = position => {
			coords.lat = null;
			coords.lng = null;
		};

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(success, error)
		}
	}
	*/

	setPointLocation(item) {

		let coords = {
			lng: item.geo_y ? parseFloat(item.geo_y) : parseFloat(item.lokalizacja_y),
			lat: item.geo_x ? parseFloat(item.geo_x) : parseFloat(item.lokalizacja_x)
		};
		let data = this.state.mapData.map(point => ({
			adres: point.adres,
			email: point.email,
			geo_x: point.geo_x || point.lokalizacja_x,
			geo_y: point.geo_y || point.lokalizacja_y,
			id: point.id,
			nazwa: point.nazwa,
			nazwa_skrocona: point.nazwa_skrocona,
			nip: point.nip,
			siec: point.siec,
			telefon: point.telefon,
			typ: point.typ,
			active: point.id === item.id ? true : false
		}));

		this.updateState('mapData', data);

		let timeout1 = setTimeout(() => {
			this.updateState('coords', coords);
			clearTimeout(timeout1);
		}, 0);

	}

	/*
	setDestination(item) {
		
		if (!this.state.position.lat) {
			this.setLocation();
		}

		let timeout1 = setTimeout(() => {
			this.updateState('destination', item.geo_x + ',' + item.geo_y);
			clearTimeout(timeout1);
		}, 500);

	}
	*/

	onChange(e) {
		return;
	}

	changeSearchType(e) {
		this.updateState('searchType', e.target.value);
	}

	searchCar() {
		
		const url = environment.mapSearchUrl;
		let body = '';

		if (this.state.registrationNumber && this.state.vin) {
			body = `plate=${this.state.registrationNumber}&vin=${this.state.vin}`;
			fetch(url, {
			  body: body,
			  headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			  },
			  method: "POST"
			})
				.then(res => res.json())
				.then(json => {

					if (json.status === 'success') {

						if (json.results.length) {
							this.updateState('tyreInfo', json.results);
							
							let timeout = setTimeout(() => {
								this.updateState('showPopup', true);
								clearTimeout(timeout);
							}, 0);

						} else {
							this.updateState('tyreInfo', ['Brak informacji o oponach, skontaktuj się z Serwisem Technicznym']);
						}

					} else {
						this.updateState('tyreInfo', ['Brak informacji o oponach, skontaktuj się z Serwisem Technicznym']);
					}

				})
				.catch(err => {
					this.updateState('tyreInfo', ['Brak informacji o oponach, skontaktuj się z Serwisem Technicznym']);
				});
		}

	}

	setRegistrationNumber(e) {

		let value = e.target.value;
		let message = '';
		const matchDigits = value.match(/^\d*$/);
		const matchLength = value.length === 10;

		if (value.length === 0) {
			message = 'pole nie może być puste';
		} else {

			if (!matchLength) {
				message = 'wpisz prawidłowy numer rejestracyjny';
			}

		}

		this.updateState('registrationNumber', value, 'registrationNumberErrorMessage', message);
	}

	validateRegistrationNumber(e) {

		let value = e.target.value;
		let message = '';
		const matchDigits = value.match(/^\d*$/);
		const matchLength = value.length === 10;

		if (value.length === 0) {
			message = 'pole nie może być puste';
		} else {

			if (!matchLength) {
				message = 'wpisz prawidłowy numer rejestracyjny';
			}

		}

		this.updateState('registrationNumber', value, 'registrationNumberErrorMessage', message);
	}

	setVin(e) {

		let value = e.target.value;
		let message = '';
		const matchDigits = value.match(/^\d*$/);
		const matchLength = value.length === 5;

		if (value.length === 0) {
			message = 'pole nie może być puste';
		} else {

			if (!matchLength) {
				message = 'wpisz pięć ostatnich cyfr VIN';
			}

			if (!matchDigits) {
				message = 'tylko cyfry';
			}

		}

		this.updateState('vin', value, 'vinErrorMessage', message);
	}

	validateVin(e) {

		let value = e.target.value;
		let message = '';
		const matchDigits = value.match(/^\d*$/);
		const matchLength = value.length === 5;

		if (value.length === 0) {
			message = 'pole nie może być puste';
		} else {

			if (!matchLength) {
				message = 'wpisz pięć ostatnich cyfr VIN';
			}

			if (!matchDigits) {
				message = 'tylko cyfry';
			}

		}

		this.updateState('vin', value, 'vinErrorMessage', message);
	}

	closePopup() {
		this.updateState('showPopup', false);
	}

	loadStandaloneSearchBox(searchBox) {
		this.searchBox = searchBox;
	};

	onPlacesChanged() {

		const places = this.searchBox.getPlaces();

		if (places.length) {

			const coords = {
				lat: places[0].geometry.location.lat(),
				lng: places[0].geometry.location.lng()
			};

			this.updateState('coords', coords);
		}
	}

	createFilters(index, onload) {
		
		let filters = [];

		if (this.state.data.length) {
			this.state.data.forEach((obj, i) => {
				
				if (obj.typ && !filters.includes(obj.typ)) {
					filters.push(obj.typ);
				}

			});
		}
	
		if (filters.length) {
			filters = filters.map((item, i) => {

				let active = false;
				let hash = window.location.hash;

				active = i === index ? true : false;

				if (onload && hash) {

					const str = item
						.replace(/\s/g, '-')
						.replace('ą', 'a')
						.replace('ć', 'c')
						.replace('ę', 'e')
						.replace('ł', 'l')
						.replace('ń', 'n')
						.replace('ó', 'o')
						.replace('ś', 's')
						.replace('ź', 'z')
						.replace('ż', 'z')
						.replace(' - ', ' ');

					active = str.toLowerCase() === hash.replace('#', '') ? true : false;

				}

				// active tab
				if (active) {
					this.updateState('activeTab', item.toLowerCase());
				}

				return {
					typ: item.toLowerCase(),
					active: active
				};
			});
		}

		let timeout1 = setTimeout(() => {
			this.updateState('filters', filters);
			clearTimeout(timeout1);
		}, 0);

		let timeout2 = setTimeout(() => {
			this.updateState('mapData', this.reduceMapData(this.state.data, this.state.bounds, index));
			clearTimeout(timeout2);
		}, 0);

	}

	createVehicleBrands() {
		
		let vehicleBrands = [];

		if (this.state.data.length) {
			this.state.data.forEach((obj, i) => {
				
				let filteredBrands = vehicleBrands.filter(brand => brand.value === obj.siec);

				if (obj.siec && !filteredBrands.length && obj.siec !== ' ') {
					vehicleBrands.push({
						value: obj.siec,
						label: obj.siec
					});
				}

			});
		}

		this.updateState('vehicleBrands', vehicleBrands);

		let timeout1 = setTimeout(() => {
			this.updateState('mapData', this.reduceMapData(this.state.data, this.state.bounds));
			clearTimeout(timeout1);
		}, 0);

	}

	renderFilters() {
		if (this.state.filters.length) {
			return this.state.filters.map((item, i) => {

				const hash = item.typ
					.replace(/\s/g, '-')
					.replace('ą', 'a')
					.replace('ć', 'c')
					.replace('ę', 'e')
					.replace('ł', 'l')
					.replace('ń', 'n')
					.replace('ó', 'o')
					.replace('ś', 's')
					.replace('ź', 'z')
					.replace('ż', 'z')
					.replace(' - ', ' ');

				return (
					<Filter
						onClick={() => this.createFilters(i)}
						active={item.active}
						href={'#' + hash}
						key={'item-' + i}
					>
						{item.typ}
					</Filter>
				);
			});
		} else {
			return '';
		}

	}

	render() {
		return (<>
			<MapHeader type={this.state.type}>
				{this.state.type === 'services' ? <CenteredBox
					type={this.state.type}
				>
					<BackLinkContainer className="back-link-container">
						{generateLink('/', null, backLinkStyles, (
							<>
								<Icon
									iconComponent={ArrowLeft}
									primaryColor={IconColor.BLACK}
									title="ikona"
								/>
								<Text
									as="small"
									marginLeft={Spacing.SPACE_16}
									color="zeusBlackUI"
								>powrót</Text>
							</>
						), this.state.offline)}
					</BackLinkContainer>
				</CenteredBox> : ''}
				{this.state.title ? <CenteredBox
					marginBottom={px(Spacing.SPACE_16)}
					type={this.state.type}
				>
					<Text as={this.state.type === 'services' ? 'h1' : 'h2'}>{this.state.title}</Text>
				</CenteredBox> : ''}
				{this.state.text ? <CenteredBox
					type={this.state.type}
				>
					{componentsFromHtml(this.state.text, this.state.offline)}
				</CenteredBox> : ''}
			</MapHeader>
			{this.state.type === 'services' ? <FilterBox id="filters-container"></FilterBox> : ''}
			<FlexContainer id="map-form-container" style={{display: 'none'}}>
				{this.state.type === 'services' ? <FlexHeader>
					<FormSelectWrapper id="brand-container">
						<FormSelect
							id="select-brand"
							placeholder="Marka samochodu"
							value={this.state.vehicleBrand.value}
							trailingDecoration={<Icon iconComponent={ChevronDown} primaryColor={IconColor.BLUE} title="ikona" />}
							onChange={() => false}
						/>
						<OptionsContainer id="select-brand-options"></OptionsContainer>
					</FormSelectWrapper>
					<FormSelectWrapper id="return-brand-container" className="hidden">
						<FormSelect
							id="select-return-brand"
							placeholder="Marka samochodu"
							value={this.state.vehicleBrand.value}
							trailingDecoration={<Icon iconComponent={ChevronDown} primaryColor={IconColor.BLUE} title="ikona" />}
							onChange={() => false}
						/>
						<OptionsContainer id="select-return-brand-options"></OptionsContainer>
					</FormSelectWrapper>
					<ShortSearchTextFieldContainer id="autocomplete-container">
						<TextFieldIconContainer>
							<Icon iconComponent={MagnifyingGlass} primaryColor={IconColor.BLUE} />
						</TextFieldIconContainer>
						<ShortSearchTextField
							placeholder="wyszukaj lokalizację"
							id="autocomplete"
						/>
					</ShortSearchTextFieldContainer>
					<ShortSearchTextFieldContainer id="reg-container" className="hidden">
						<ShortSearchTextField
							placeholder="Wpisz numer rejestracyjny"
							id="reg"
						/>
					</ShortSearchTextFieldContainer>
					<ShortSearchTextFieldContainer id="vin-container" className="hidden">
						<ShortSearchTextField
							placeholder="Wpisz 5 ostatnich cyfr VIN"
							id="vin"
						/>
					</ShortSearchTextFieldContainer>
					<RadioGroup id="radio-group" className="hidden"></RadioGroup>
					<ButtonContainer id="tyre-container" className="hidden">
						<Button
							id="tyre"
							buttonType="primary"
						>
							Szukaj
						</Button>
					</ButtonContainer>
					<ButtonContainer id="geo-container">
						<Button
							id="geo"
							buttonType="tertiary"
							color={ButtonColor.BLUE}
							iconComponent={MapMarker}
						>
							ustal moją lokalizację
						</Button>
					</ButtonContainer>
				</FlexHeader> : <FlexHeader>
					<LongSearchTextFieldContainer>
						<TextFieldIconContainer>
							<Icon iconComponent={MagnifyingGlass} primaryColor={IconColor.BLUE} />
						</TextFieldIconContainer>
						<ShortSearchTextField
							placeholder="wyszukaj oddział"
							id="autocomplete"
						/>
					</LongSearchTextFieldContainer>
					<ButtonContainer id="geo-container">
					<Button
						id="geo"
						buttonType="tertiary"
						color={ButtonColor.BLUE}
						iconComponent={MapMarker}
					>
						ustal moją lokalizację
					</Button>
					</ButtonContainer>
				</FlexHeader>}
			</FlexContainer>
			<FlexContainer id="map-container">
				<LeftColumn>
					<Box marginBottom={Spacing.SPACE_16} id="result-counter">
						<Text as="h3"></Text>
					</Box>
					<div id="result"></div>
				</LeftColumn>
				<RightColumn>
					<LoadedMap id="map" className={this.state.type}></LoadedMap>
				</RightColumn>
			</FlexContainer>
		</>)
	}

	/*
	render() {
		return (<>
				{this.state.type === 'services' ? <FilterBox>
					{this.renderFilters()}
				</FilterBox> : ''}
				{this.state.type === 'services' ? <ShortFlexContainer>
					<ShortSearchBox>
						{this.state.searchType === 'tyres' ? <>
							<TextFieldContainer>
								<TyresTextField
									placeholder="Wpisz numer rejestracyjny"
									value={this.state.registrationNumber}
									onChange={this.setRegistrationNumber}
									onBlur={this.validateRegistrationNumber}
									errorMessage={this.state.registrationNumberErrorMesssage}
								/>
								{this.state.registrationNumberErrorMessage ? (<ErrorMessage>
									<ValidationMessage>{this.state.registrationNumberErrorMessage}</ValidationMessage>
								</ErrorMessage>) : ''}
							</TextFieldContainer>
							<TextFieldContainer>
								<TyresTextField
									placeholder="Wpisz 5 ostatnich cyfr VIN"
									value={this.state.vin}
									onChange={this.setVin}
									onBlur={this.validateVin}
									errorMessage={this.state.vinErrorMesssage}
								/>
								{this.state.vinErrorMessage ? (<ErrorMessage>
									<ValidationMessage>{this.state.vinErrorMessage}</ValidationMessage>
								</ErrorMessage>) : ''}
							</TextFieldContainer>
						</> : <ShortSearchTextFieldContainer>
							<TextFieldIconContainer>
								<Icon iconComponent={MagnifyingGlass} />
							</TextFieldIconContainer>
							<ShortSearchTextField
								placeholder="wyszukaj oddział"
								id="autocomplete"
							/>
						</ShortSearchTextFieldContainer>}
						{this.state.activeTab === 'serwis ogumienia' ? <RadioContainer>
							<RadioGroup
								value={this.state.searchType}
								onChange={this.changeSearchType}
							>
								<RadioGroupInner>
									<Radio
										type="radio"
										value="service"
										name="searchType"
										checked={this.state.searchType === 'service' ? true : false}
										defaultChecked
										onChange={this.onChange}
									>
										znajdź serwis
									</Radio>
									<Radio
										type="radio"
										value="tyres"
										name="searchType"
										checked={this.state.searchType === 'service' ? false : true}
										onChange={this.onChange}
									>
										znajdź swoje opony
									</Radio>
								</RadioGroupInner>
							</RadioGroup>
						</RadioContainer> : <Button
							id="geo"
							buttonType="tertiary"
							color={ButtonColor.BLUE}
							iconComponent={MapMarker}
						>
							ustal moją lokalizację
						</Button>}
						{this.state.searchType === 'tyres' ? <Button
							buttonType="primary"
							onClick={this.searchCar}
						>
							Szukaj
						</Button> : <FormSelectWrapper>
							<FormSelect
								label="Marka samochodu"
								value={this.state.vehicleBrand.value}
								onChange={value => this.changeVehicleBrand(value)}
								items={this.state.vehicleBrands}
							/>
						</FormSelectWrapper>}
					</ShortSearchBox>
				</ShortFlexContainer> : ''}
			<FlexContainer id="map-container">
				<LeftColumn>
					<Box marginBottom={Spacing.SPACE_16} id="result-counter">
						<Text as="h3"></Text>
					</Box>
					<div id="result"></div>
				</LeftColumn>
				<RightColumn>
					<LoadedMap id="map"></LoadedMap>
				</RightColumn>
			</FlexContainer>
		</>)
	}
	*/

	/*
	render() {
		return (
			<LoadScript
				googleMapsApiKey={googleMapsKey}
				libraries={places}
			>
				<MapBoxContainer>
					<MapHeader type={this.state.type}>
						{this.state.type === 'services' ? <CenteredBox
							type={this.state.type}
						>
							<BackLinkContainer>
								{generateLink('/', null, backLinkStyles, (
									<>
										<Icon
											iconComponent={ArrowLeft}
											primaryColor={IconColor.BLACK}
											title="ikona"
										/>
										<Text
											as="small"
											marginLeft={Spacing.SPACE_16}
											color="zeusBlackUI"
										>powrót</Text>
									</>
								), this.state.offline)}
							</BackLinkContainer>
						</CenteredBox> : ''}
						{this.state.title ? <CenteredBox
							marginBottom={px(Spacing.SPACE_16)}
							type={this.state.type}
						>
							<Text as={this.state.type === 'services' ? 'h1' : 'h2'}>{this.state.title}</Text>
						</CenteredBox> : ''}
						{this.state.text ? <CenteredBox
							type={this.state.type}
						>
							{componentsFromHtml(this.state.text, this.state.offline)}
						</CenteredBox> : ''}
					</MapHeader>
					{this.state.type === 'agencies' ? <>
						<MapSearchBox>
							<Box>
							</Box>
							<FlexContainer>
								<StandaloneSearchBox
									onLoad={this.loadStandaloneSearchBox}
									onPlacesChanged={this.onPlacesChanged}
								>
									<SearchTextField
										placeholder="wyszukaj oddział"
										leadingDecoration={<Icon iconComponent={MagnifyingGlass} />}
										onChange={this.onChange}
									/>
								</StandaloneSearchBox>
								<Button
									buttonType="tertiary"
									color={ButtonColor.BLUE}
									iconComponent={MapMarker}
									onClick={this.setLocation}
								>
									ustal moją lokalizację
								</Button>
							</FlexContainer>
						</MapSearchBox>
					</> : ''}
					{this.state.type === 'services' ? <FilterBox>
						{this.renderFilters()}
					</FilterBox> : ''}
					{this.state.type === 'services' ? <ShortFlexContainer>
						<ShortSearchBox>
							{this.state.searchType === 'tyres' ? <>
								<TextFieldContainer>
									<TyresTextField
										placeholder="Wpisz numer rejestracyjny"
										value={this.state.registrationNumber}
										onChange={this.setRegistrationNumber}
										onBlur={this.validateRegistrationNumber}
										errorMessage={this.state.registrationNumberErrorMesssage}
									/>
									{this.state.registrationNumberErrorMessage ? (<ErrorMessage>
										<ValidationMessage>{this.state.registrationNumberErrorMessage}</ValidationMessage>
									</ErrorMessage>) : ''}
								</TextFieldContainer>
								<TextFieldContainer>
									<TyresTextField
										placeholder="Wpisz 5 ostatnich cyfr VIN"
										value={this.state.vin}
										onChange={this.setVin}
										onBlur={this.validateVin}
										errorMessage={this.state.vinErrorMesssage}
									/>
									{this.state.vinErrorMessage ? (<ErrorMessage>
										<ValidationMessage>{this.state.vinErrorMessage}</ValidationMessage>
									</ErrorMessage>) : ''}
								</TextFieldContainer>
							</> : <StandaloneSearchBox
								onLoad={this.loadStandaloneSearchBox}
								onPlacesChanged={this.onPlacesChanged}
							>
								<ShortSearchTextField
									placeholder="wyszukaj oddział"
									leadingDecoration={<Icon iconComponent={MagnifyingGlass} />}
									onChange={this.onChange}
								/>
							</StandaloneSearchBox>}
							{this.state.activeTab === 'serwis ogumienia' ? <RadioContainer>
								<RadioGroup
									value={this.state.searchType}
									onChange={this.changeSearchType}
								>
									<RadioGroupInner>
										<Radio
											type="radio"
											value="service"
											name="searchType"
											checked={this.state.searchType === 'service' ? true : false}
											defaultChecked
											onChange={this.onChange}
										>
											znajdź serwis
										</Radio>
										<Radio
											type="radio"
											value="tyres"
											name="searchType"
											checked={this.state.searchType === 'service' ? false : true}
											onChange={this.onChange}
										>
											znajdź swoje opony
										</Radio>
									</RadioGroupInner>
								</RadioGroup>
							</RadioContainer> : <Button
								buttonType="tertiary"
								color={ButtonColor.BLUE}
								iconComponent={MapMarker}
								onClick={this.setLocation}
							>
								ustal moją lokalizację
							</Button>}
							{this.state.searchType === 'tyres' ? <Button
								buttonType="primary"
								onClick={this.searchCar}
							>
								Szukaj
							</Button> : <FormSelectWrapper>
								<FormSelect
									label="Marka samochodu"
									value={this.state.vehicleBrand.value}
									onChange={value => this.changeVehicleBrand(value)}
									items={this.state.vehicleBrands}
								/>
							</FormSelectWrapper>}
						</ShortSearchBox>
					</ShortFlexContainer> : ''}
					<FlexContainer>
						<LeftColumn>
							<Box marginBottom={Spacing.SPACE_16}>
								<Text as="h3">Wszystkie oddziały ({this.state.mapData.length})</Text>
							</Box>
							<InfiniteScroll
								height={720}
							>
								{this.state.mapData.map((item, i) => {
									return(<ItemBox key={'data-' + i}>
										<Box>
											<Text as="h4">{item.nazwa}</Text>
										</Box>
										<Box marginBottom={Spacing.SPACE_16}>
											<Text as="h4">{item.adres}</Text>
										</Box>
										{item['otwarte od'] || item["otwarte do"] ? <Box>
											<Text as="small" color="doveGrayUI" marginBottom={Spacing.SPACE_16}>pon.-pt. {item['otwarte od'] || ''}-{item["otwarte do"] || ''}</Text>
										</Box> : ''}
										<Box marginBottom={Spacing.SPACE_16}>
											<Stack direction="row">
												<Button
													buttonType="tertiary"
													color={ButtonColor.BLUE}
													iconComponent={Car}
													marginRight={Spacing.SPACE_16}
													onClick={() => this.setDestination(item)}
												>
													wyznacz trasę
												</Button>
												<Button
													buttonType="tertiary"
													color={ButtonColor.BLUE}
													iconComponent={MapMarker}
													onClick={() => this.setPointLocation(item)}
												>
													pokaż na mapie
												</Button>
											</Stack>
										</Box>
										{item.email ? <Box>
											<a href={"mailto" + item.email}>
												<Button buttonType="primary">umów spotkanie</Button>
											</a>
										</Box> : ''}
									</ItemBox>);
								})}
							</InfiniteScroll>
						</LeftColumn>
						<RightColumn>
							<MapContainer
								data={this.state.mapData}
								getBounds={this.getBounds}
								position={this.state.position}
								origin={this.state.origin}
								destination={this.state.destination}
							/>
						</RightColumn>
					</FlexContainer>
					<MapPopup
						info={this.state.tyreInfo}
						vin={this.state.vin}
						registrationNumber={this.state.registrationNumber}
						show={this.state.showPopup}
						callback={this.closePopup}
					/>
				</MapBoxContainer>
			</LoadScript>
		);
	}
	*/

}

export default MapBox;