/*!
 * mLeasing, calculator :: 01/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */
import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import Container from '../../components/container.js';
import {Box} from '@mbank-design/design-system/components';
import {Stack} from '@mbank-design/design-system/components';
import {Text} from '@mbank-design/design-system/components';
import {TextField} from '@mbank-design/design-system/components';
import {Checkbox} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {ButtonColor} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import palette from '@mbank-design/design-system/palette';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {Icon} from '@mbank-design/design-system/components';
import {MagnifyingGlass} from '@mbank-design/design-system/icons';
import {ArrowLeft} from '@mbank-design/design-system/icons';
import {Illustration} from '@mbank-design/design-system/components';
import {RetailCarHighway} from '@mbank-design/design-system/illustrations';
import {RetailHandThumbUp} from '@mbank-design/design-system/illustrations';
import {Tick} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import {ValidationMessage} from '@mbank-design/design-system/components';
import Slider from '../../components/slider.js';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const version = 'v: 1.0.3 :: r. 05/07/2022 @ pp';
const reCaptchaKey = environment.reCaptchaKey;
const SearchTextField = styled(TextField)`
	padding-right: ${px(Spacing.SPACE_44)};
`;
const NetNumberField = styled(TextField)`
	height: ${px(Spacing.SPACE_80)};
`;
const InputContainer = styled.div`
	margin-bottom: ${px(Spacing.SPACE_16)};
`;
const StepContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding-bottom: ${props => props.paddingBottom ? props.paddingBottom[0] : Spacing.SPACE_0};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-bottom: ${props => props.paddingBottom ? props.paddingBottom[1] : Spacing.SPACE_0};
	{
`;
const FlexContainer = styled.div`
	display: flex;
	padding-right: ${props => props.paddingRight || px(Spacing.SPACE_100)};
	padding-left: ${props => props.paddingLeft || px(Spacing.SPACE_100)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const FlexContainerInner = styled(FlexContainer)`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_0)};
		padding-left: ${px(Spacing.SPACE_0)};
	}
`;
const FlexBox = styled(Box)`
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	padding-right: ${props => props.paddingRight || px(Spacing.SPACE_68)};
	padding-left: ${props => props.paddingLeft || px(Spacing.SPACE_68)};
	display: ${props => props.validated ? 'flex' : 'block'};
	flex-direction: column;
	justify-content: space-between;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_0)};
		padding-left: ${px(Spacing.SPACE_0)};

		&:first-child {
			padding-bottom: ${px(Spacing.SPACE_24)};
		}
	}
`;
const ButtonRow = styled.div`
	display: flex;
	flex-wrap: nowrap;
	border: 1px solid ${palette.altoGray};
	border-radius: ${px(Spacing.SPACE_16)};
	margin-bottom: ${px(Spacing.SPACE_16)};
`;
const MonthText = styled.p`
	margin: 0;
	font-size: ${px(Spacing.SPACE_16)};
	color: ${props => props.fontColor};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		font-size: ${px(Spacing.SPACE_12)};
	}
`;
const CustomButton = styled.div`
	flex-basis: 25%;
	border-radius: ${px(Spacing.SPACE_16)};
	padding-top: ${px(Spacing.SPACE_4)};
	padding-bottom: ${px(Spacing.SPACE_4)};
	text-align: center;
	background-color: ${props => props.backgroundColor};
	cursor: pointer;
`;
const SearchInputWrapper = styled.div`
	position: relative;
`;
const SearchTips = styled.div`
	position: absolute;
	z-index: 2;
	width: 100%;
	max-height: ${px(288)};
	overflow: auto;
	border: 1px solid ${palette.altoGray};
`;
const SearchTipsItem = styled.div`
	border-bottom: 1px solid ${palette.altoGray};
	padding: ${px(Spacing.SPACE_8)};
	background-color: ${palette.solidWhite};
	cursor: pointer;

	&:last-child {
		border-bottom: 0;
	}
`;
const Tile = styled.div`
	padding: ${px(Spacing.SPACE_24)};
	margin: ${px(Spacing.SPACE_32)} ${Spacing.SPACE_0};
	background-color: ${palette.wildSandGray};
	box-shadow: ${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08);
`;
const NoteContainer = styled.div`
	display: flex;
`;
const ButtonNoteContainer = styled.div`
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		align-items: center;
	}
`;
const FlexContainerCentered = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;
const LabelContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
const FileInputBox = styled(Box)`
	position: relative;
`;
const FileInput = styled.input`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
`;
const BottomBar = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${px(Spacing.SPACE_24)} ${px(220)};
	margin-top: ${px(Spacing.SPACE_24)};
	background-color: ${palette.endeavourBlueUI};
	text-align: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding: ${px(Spacing.SPACE_24)} ${px(Spacing.SPACE_64)};
	}
`;
const BackButton = styled.div`
	position: absolute;
	top: ${px(Spacing.SPACE_0)};
	left: ${px(Spacing.SPACE_52)};
	display: flex;
	cursor: pointer;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		top: -${px(Spacing.SPACE_32)};
		left: ${px(Spacing.SPACE_16)};
	}
`;
const ApprovalContainer = styled.div`
	position: relative;
	margin-right: ${px(Spacing.SPACE_16)};
	padding-bottom: ${props => props.paddingBottom || 0};
`;
const ErrorMessage = styled.div`
	position: absolute;
	bottom: -${px(16)};
	white-space: nowrap;
`;
const IllustrationContainer = styled.div`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const ButtonBox = styled.div`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: flex;
		justify-content: center;
	}
`;
const buildState = (props, state) => {

	let title = '';
	let items = [];

	if (props.offline) {
		title = props.data.title;
		items = props.data.items ? props.data.items : [];
	}
	else {
		try {
			let obj = props.data['CalculatorData']['Calculator'];

			title = obj.Title;
			items = obj.TextItem;
		}
		catch (err) {
			console.log(err);
		}
	}

	return {
		months: state ? state.months : 24,
		monthRanges: state ? state.monthRanges : [24, 36, 48, 60, 72],
		pageType: props.pageType,
		title: title,
		items: items,
		step: state ? state.step : 1,
		numberOfSteps: state ? state.numberofSteps : 5,
		repurchase: state ? state.repurchase : 0, // repurchase percentage
		own: state ? state.own : 0, // self deposit percentage
		repurchaseAmount: state ? state.repurchaseAmount : 0, // repurchase amount
		ownAmount: state ? state.ownAmount : 0, // self deposit amount
		maxRepurchase: state ? state.maxRepurchase : 45, // max repurchase percentage
		minRepurchase: state ? state.minRepurchase : 0, // min repurchase percentage
		maxOwn: state ? state.maxOwn : 45, // max sef deposit percentage
		minOwn: state ? state.minOwn : 0, // min sef deposit percentage
		ownStep: state ? state.ownStep : 1,
		repurchaseStep: state ? state.repurchaseStep : 1,
		netAmount: state ? state.netAmount : 0, // leased item net amount
		netAmountErrorMessage: state ? state.netAmountErrorMessage : '',
		installment: state ? state.installment : 0, // installment amount
		modelName: state ? state.modelName : '',
		mileage: state ? state.mileage : '',
		mileageErrorMessage: state ? state.mileage3ErrorMessage : '',
		year: state ? state.year : '',
		yearErrorMessage: state ? state.yearErrorMessage : '',
		brand: state ? state.brand : '',
		type: state ? state.type : '',
		file: state ? state.file : '',
		fileName: state ? state.fileName : '',
		tips: state ? state.tips : [],
		models: state ? state.models : [],
		model: state ? state.model : {
			name: '',
			type: ''
		},
		modelErrorMessage: state ? state.modelErrorMessage : '',
		name: state ? state.name : '',
		nameErrorMessage: state ? state.nameErrorMessage :  '',
		phone: state ? state.phone : '',
		phoneErrorMessage: state ? state.phoneErrorMessage : '',
		nip: state ? state.nip : '',
		niperrorMessage: state ? state.nipErrorMessage : '',
		email: state ? state.email : '',
		emailErrorMessage: state ? state.emailErrorMessage : '',
		approval: state ? state.approval : false,
		approvalErrorMessage: state ? state.approvalErrorMessage : '',
		valid: state ? state.valid : false,
		gReCaptchaResponse: state ? state.gReCaptchaResponse : ''
	};
};

class Calculator extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.updateMonths = this.updateMonths.bind(this);
		this.updateState = this.updateState.bind(this);
		this.changeModel = this.changeModel.bind(this);
		this.searchModel = this.searchModel.bind(this);
		this.renderSearchTips = this.renderSearchTips.bind(this);
		this.changeStep = this.changeStep.bind(this);
		this.changeOwn = this.changeOwn.bind(this);
		this.changeRepurchase = this.changeRepurchase.bind(this);
		this.changeNetAmount = this.changeNetAmount.bind(this);
		this.calculateInstallment = this.calculateInstallment.bind(this);
		this.formatNumber = this.formatNumber.bind(this);
		this.calculatePmt = this.calculatePmt.bind(this);
		this.changeMilage = this.changeMilage.bind(this);
		this.changeYear = this.changeYear.bind(this);
		this.changeBrand = this.changeBrand.bind(this);
		this.changeType = this.changeType.bind(this);
		this.changeFile = this.changeFile.bind(this);
		this.changeName = this.changeName.bind(this);
		this.changeNip = this.changeNip.bind(this);
		this.changePhone = this.changePhone.bind(this);
		this.changeEmail = this.changeEmail.bind(this);
		this.changeApproval = this.changeApproval.bind(this);
		this.validate = this.validate.bind(this);
		this.validateNetAmount = this.validateNetAmount.bind(this);
		this.validateModel = this.validateModel.bind(this);
		this.validateNip = this.validateNip.bind(this);
		this.validatePhone = this.validatePhone.bind(this);
		this.validateEmail = this.validateEmail.bind(this);
		this.validateName = this.validateName.bind(this);
		this.send = this.send.bind(this);
		this.changeSliderSettings = this.changeSliderSettings.bind(this);
		this.renderMonths = this.renderMonths.bind(this);
		this.validateWithReCaptcha = this.validateWithReCaptcha.bind(this);
		this.clearData = this.clearData.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('calculator');
		}

		// scroll into view
		if (window && window.location.hash === '#kalkulator') {
			const calcElement = document.querySelector('#kalkulator');

			if (calcElement) {
				calcElement.scrollIntoView(true);
			}
		}

		fetch(environment.calculatorDataFile)
			.then(res => res.text())
			.then(csv => {

				let models = csv.split('\n');

				models = models.map(line => ({
					
					name: line.split(';')[0],
					type: line.split(';')[1] ? line.split(';')[1].replace('\r', '') : ''
				}));
				this.updateState('models', models);
			});
	}

	static getDerivedStateFromProps(props, state) {
		
		return buildState(props, state);
	}

	updateMonths(months) {
		
		this.updateState('months', months);

		let timeout = setTimeout(() => {
			
			this.changeSliderSettings();
			clearTimeout(timeout);
		}, 0);

	}

	searchModel(e) {

		let searchStr = e.target.value.toLowerCase();
		let regex = new RegExp(`${searchStr}`);
		let results = [];

		if (searchStr.length >=3) {
			results = this.state.models
				.filter(model => model.name.toLowerCase().match(regex))
				.filter((model, i) => i < 20);
		}

		this.updateState('modelName', searchStr);

		let timeout = setTimeout(() => {
			
			this.updateState('tips', results);
			clearTimeout(timeout);
		}, 0);
	}

	changeModel(model) {
		
		this.updateState('modelName', model.name);

		let timeout1 = setTimeout(() => {
			
			this.updateState('model', model);
			clearTimeout(timeout1);
		}, 0);
		
		let timeout2 = setTimeout(() => {
			
			this.updateState('tips', []);
			clearTimeout(timeout2);
		}, 0);
		
		let timeout3 = setTimeout(() => {
			
			this.changeBrand(model);
			clearTimeout(timeout3);
		}, 0);
		
		let timeout4 = setTimeout(() => {
			
			this.changeType(model);
			clearTimeout(timeout4);
		}, 0);
	}

	validateModel(e) {
	
		let value = e.target.value;
		let message = '';

		if (value === '') {
			message = 'wybierz model';
		}
		this.updateState('modelErrorMessage', message);
	}

	validateNetAmount(e) {
	
		let value = e.target.value;
		let message = '';
		const matchDigits = value.match(/^\d*$/);

		if (!matchDigits) {
			message = 'tylko cyfry';
		} 
		else {
			if (value === '0') {
				message = 'wartość przemiotu musi być większa od 0';
			}
		}
		this.updateState('netAmountErrorMessage', message);
	}

	validateName(e) {

		let message = '';
		let value = e.target.value;
		const match = value.match(/^[a-zA-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]*$/);

		if (value.length === 0) {
			message = 'pole nie może być puste';
		} 
		else if (!match) {
			message = 'wpisz same litery i spacje';
		}
		this.updateState('nameErrorMessage', message);
	}

	validateNip(e) {

		let value = e.target.value;
		let message = '';
		const matchDigits = value.match(/^\d*$/);
		const matchLength = value.length === 10;

		if (value.length === 0) {
			message = 'pole nie może być puste';
		} 
		else {
			if (!matchLength) {
				message = 'wpisz prawidłowy nip';
			}
			if (!matchDigits) {
				message = 'tylko cyfry';
			}
		}
		this.updateState('nipErrorMessage', message);
	}

	validatePhone(e) {

		let value = e.target.value;
		let message = '';
		const matchDigits = value.match(/^\d*$/);
		const matchLength = value.length <= 9;

		if (value.length !== 0) {
			if (!matchLength) {
				message = 'wpisz maksymalnie 9 cyfr';
			}
			if (!matchDigits) {
				message = 'tylko cyfry';
			}
		} 
		else {
			message = 'pole nie może być puste';
		}
		this.updateState('phoneErrorMessage', message);
	}

	validateEmail(e) {

		let value = e.target.value;
		let message = '';
        const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		const isEmail = emailRegex.test(value) ? true : false;

		if (value.length === 0) {
			message = 'pole nie może być puste';
		} 
		else {
			if (!isEmail) {
				message = 'wpisz prawidłowy email';
			}
		}
		this.updateState('emailErrorMessage', message);
	}

	validate(step) {

		let valid = false;
		this.updateState('valid', valid);

		if (this.state.step === 1 && step === 2) {
			if (!this.modelErrorMessage
				&& !this.netAmounterrorMessage
				&& this.state.model.name
				&& this.state.netAmount) {
				valid = true;
			} 
			else {
				valid = false;

				if (!this.state.model.name) {
					let timeout = setTimeout(() => {
						
						this.updateState('modelErrorMessage', 'wybierz model');
						clearTimeout(timeout);
					}, 0);
				}
				if (!this.state.netAmount) {
					let timeout = setTimeout(() => {
						
						this.updateState('netAmountErrorMessage', 'pole nie może być puste');
						clearTimeout(timeout);
					}, 0);
				}
			}
		}
		if (this.state.step === 2 && step === 3) {
			valid = true;
		}
		if (this.state.step === 2 && step === 4) {
			valid = true;
		}
		if (this.state.step === 3 && step === 4) {
			valid = true;
		}
		if (this.state.step === 4 && step === 5) {
			if (!this.state.nameErrorMessage
				&& !this.state.nipErrorMessage
				&& !this.state.phoneErrorMessage
				&& !this.state.emailErrorMessage
				&& this.state.name
				&& this.state.nip
				&& this.state.phone
				&& this.state.email
				&& this.state.approval) {
					valid = true;
			} 
			else {
				if (!this.state.name) {
					let timeout = setTimeout(() => {
						
						this.updateState('nameErrorMessage', 'pole nie może być puste');
						clearTimeout(timeout);
					}, 0);
				}
				if (!this.state.nip) {
					let timeout = setTimeout(() => {
						
						this.updateState('nipErrorMessage', 'pole nie może być puste');
						clearTimeout(timeout);
					}, 0);
				}
				if (!this.state.phone) {
					let timeout = setTimeout(() => {
						
						this.updateState('phoneErrorMessage', 'pole nie może być puste');
						clearTimeout(timeout);
					}, 0);
				}
				if (!this.state.email) {
					let timeout = setTimeout(() => {
						
						this.updateState('emailErrorMessage', 'pole nie może być puste');
						clearTimeout(timeout);
					}, 0);
				}
				if (!this.state.approval) {
					let timeout = setTimeout(() => {
						
						this.updateState('approvalErrorMessage', 'zgoda wymagana');
						clearTimeout(timeout);
					}, 0);
				}
			}
		}
		if (valid) {
			this.updateState('valid', true);
		}
	}

	validateWithReCaptcha() {
		
		window.grecaptcha.execute(reCaptchaKey, {action: 'submit'})
			.then(result => {

				if (!result.errors && this.state.valid) {
					this.updateState('valid', true, '', '');

					let timeout = setTimeout(() => {
						
						this.updateState('gReCaptchaResponse', result)
						this.send(5);
						clearTimeout(timeout);
					}, 0);
				}
			})
			.catch (err => {
				
				this.updateState('valid', false, '', '');
			});
	}

	changeStep(step) {
		
		this.validate(step);
		let timeout = setTimeout(() => {

			if (step === 5) {
				this.validateWithReCaptcha();
			} 
			else {
				if (this.state.step === 5 && step === 1) {
					this.clearData();
				} else {
					this.send(step);
				}
			}
			clearTimeout(timeout);
		}, 0);
	}

	send(step) {
	
		const url = environment.calculatorSendUrl;
		const uploadUrl = environment.calculatorUploadUrl;
		let body = '';
		let months = this.state.months;
		let own = parseInt(this.state.own);
		let repurchase = parseInt(this.state.repurchase);
		let netto = this.state.netAmount;
		let vehicleBrand = this.state.brand;
		let vehicleName = this.state.model.name || vehicleBrand;
		let vehicleModel = this.state.type;
		let type = encodeURI(this.state.model.type);
		let mileage = this.state.mileage;
		let year = this.state.year;
		let name = this.state.name;
		let phone = this.state.phone;
		let nip = this.state.nip;
		let email = this.state.email;
		let approval = this.state.approval;
		let attachmentBody = '';

		if (this.state.valid) {
			// send data on certain steps only
			if (this.state.step === 1 && step === 2) { // backend step 1
				if (type) {
					body = `step=1&months=${months}&own=${own}&repurchase=${repurchase}&netto=${netto}&vehicle_name=${vehicleName}&vehicle_brand=${vehicleBrand}&vehicle_model=${vehicleModel}&section=${type}`;
				} 
				else {
					body = `step=1&months=${months}&own=${own}&repurchase=${repurchase}&netto=${netto}&vehicle_name=${vehicleName}&vehicle_brand=${vehicleBrand}&vehicle_model=${vehicleModel}`;
				}
			} 
			else if (this.state.step === 3 && step === 4) { // backend step 2
				body = `step=2&year=${year}&mileage=${mileage}`;

				if (this.state.file) {
					attachmentBody = new FormData();
					attachmentBody.append('attachment', this.state.file);
				}
			} 
			else if (this.state.step === 4 && step === 5) { // backend step 3
				body = `step=3&name=${name}&phone=${phone}&nip=${nip}&email=${email}&agree1=${approval}&agree2=true&g-recaptcha-response=${this.state.gReCaptchaResponse}`;
			} 
			else {
				this.updateState('step', step);
			}
			if (body) {
				fetch(url, {
					body: body,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded"
					},
					method: "POST"
				})
					.then(res => res.json())
					.then(json => {

						if (json.status === 'success') {
							if (this.state.step === 1 && step === 2) { // backend step 1
								this.updateState('installment', parseFloat(json.data.payment) ? parseFloat(json.data.payment) : this.state.installment); // backend installment response
							}
							if (this.state.step === 4 && step === 5) { // backend step 3
							}
							if (attachmentBody) {
								fetch(uploadUrl, {
									method: 'POST',
									body: attachmentBody
								})
								.then(res => {

									if (res.ok) {
										this.updateState('step', step);
									} 
									else {
										console.log(res);
									}
								})
								.catch(err => {
									
									console.log(err);
								})
							} 
							else {
								this.updateState('step', step);
							}
						} 
						else {
							console.log('data error: ', json);
							
							if (this.state.step === 1 && step === 2) { // backend step 1
								if (json.errors && json.errors.netto) {
									this.updateState('', '', 'netAmountErrorMessage', json.errors.netto);
								}
								if (json.errors && json.errors.vehicle_name) {
									this.updateState('', '', 'modelErrorMessage', json.errors.vehicle_name);
								}
								// error message for non user errors
								/*
								if (json.errors.months) {
									this.updateState('', '', 'monthsErrorMessage', json.errors.months);
								}
								if (json.errors.own) {
									this.updateState('', '', 'ownErrorMessage', json.errors.own);
								}

								if (json.errors.repurchase) {
									this.updateState('', '', 'repurchaseErrorMessage', json.errors.repurchase);
								}
								*/
							}
						}
						if (this.state.step === 3 && step === 4) { // backend step 2
							if (json.errors && json.errors.year) {
								this.updateState('', '', 'yearErrorMessage', json.errors.year);
							}
							if (json.errors && json.errors.mileage) {
								this.updateState('', '', 'mileageErrorMessage', json.errors.mileage);
							}
						}
						if (this.state.step === 4 && step === 5) { // backend step 3
							if (json.errors && json.errors.name) {
								this.updateState('', '', 'nameErrorMessage', json.errors.name);
							} 
							if (json.errors && json.errors.phone) {
								this.updateState('', '', 'phoneErrorMessage', json.errors.phone);
							}
							if (json.errors && json.errors.nip) {
								this.updateState('', '', 'nipErrorMessage', json.errors.nip);
							}
							if (json.errors && json.errors.email) {
								this.updateState('', '', 'emailErrorMessage', json.errors.email);
							}
						}
					})
					.catch(err => {
						
						console.log(err);
					}); // error message for non user errors
			}
		} 
		else {
			if (this.state.step === 4 && step === 3) { // on back button
				this.updateState('step', step);
			}
			if (this.state.step === 4 && step === 2) {
				this.updateState('step', step);
			}
			if (this.state.step === 3 && step === 2) {
				this.updateState('step', step);
			}
			if (this.state.step === 2 && step === 1) {
				this.updateState('step', step);
			}
		}
	}

	formatNumber(number) {
		
		return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\u00a0');
	}

	calculatePmt(rate, nper, pmt, fv, type) {
		
		return (rate * (pmt * Math.pow((rate + 1), nper) + fv)) / ((rate * type + 1) * (Math.pow((rate + 1), nper) - 1));
	}
	
	/* ponizsza funkcja do obliczania raty ('calculateInstallment') nie jest uzywana, 
	   wszystkie obliczenia sa wykonywane w .phpie, z ktorym komunikujemy sie AJAXem */
	calculateInstallment() {

		let rate = 4.6;
		let period = this.state.months;
		let amount = this.state.netAmount * 1.23;	//brutto
		let netAmount = this.state.netAmount;
		let own = this.state.own * amount / 100;
		let netOwn = this.state.ownAmount;
		let repurchase = this.state.repurchase * amount;
		let netRepurchase = this.state.repurchaseAmount;
		let investment = amount - own;
		let netInvestment = netAmount - netOwn;
		let installment = this.calculatePmt(rate / 100 / 12, period, investment, repurchase * (-1), 0);
		let netInstallment = this.calculatePmt(rate / 100 / 12, period, netInvestment, netRepurchase * (-1), 0);
		
		this.updateState('installment', netInstallment);
	}

	changeOwn(value) {
		
		this.updateState('own', value);

		const ownAmount = (this.state.netAmount !== '' ? this.state.netAmount : 0) * value / 100;
		
		let timeout = setTimeout(() => {
			
			this.updateState('ownAmount', ownAmount);
			clearTimeout(timeout);
		}, 0);
	}

	changeRepurchase(value) {
		
		this.updateState('repurchase', value);

		const repurchaseAmount = (this.state.netAmount !== '' ? this.state.netAmount : 0) * value / 100;
		
		let timeout = setTimeout(() => {
			
			this.updateState('repurchaseAmount', repurchaseAmount);
			clearTimeout(timeout);
		}, 0);
	}

	changeNetAmount(e) {

		const value = e.target.value;
		let netAmount;

		if (value.match(/^\d*$/) && value !== '') {
			netAmount = parseInt(value);
		} 
		else {
			if (value === '') {
				netAmount = value;
			} 
			else {
				netAmount = this.state.netAmount;
			}
		}
		this.updateState('netAmount', netAmount);

		const ownAmount = (netAmount !== '' ? netAmount : 0) * this.state.own / 100;
		const repurchaseAmount = (netAmount !== '' ? netAmount : 0) * this.state.repurchase / 100;
		
		let timeout1 = setTimeout(() => {
			
			this.updateState('ownAmount', ownAmount);
			clearTimeout(timeout1);
		}, 0);
		
		let timeout2 = setTimeout(() => {
			
			this.updateState('repurchaseAmount', repurchaseAmount);
			clearTimeout(timeout2);
		}, 0);
	}

	changeMilage(e) {

		let value = e.target.value;

		if (value.match(/^\d*$/) && value !== '') {
			value = parseInt(value);
		} 
		else {
			if (value === '') {
				value = value;
			} 
			else {
				value = this.state.mileage;
			}
		}
		this.updateState('mileage', value);
	}

	changeYear(e) {

		let value = e.target.value;

		if (value.match(/^\d*$/) && value !== '' && value.length <= 4) {
			value = parseInt(value);
		} 
		else {
			if (value === '' && value.length <= 4) {
				value = value;
			} 
			else {
				value = this.state.year;
			}
		}
		this.updateState('year', value);
	}

	changeBrand(obj) {

		if (obj.name) {
			let brand = obj.name.split(' ')[0];
			this.updateState('brand', brand);
		} 
		else {
			obj.preventDefault();
		}
	}

	changeSliderSettings() {
		
		let minOwn = this.state.minOwn;
		let maxOwn = this.state.maxOwn;
		let minRepurchase = this.state.minRepurchase;
		let maxRepurchase = this.state.maxRepurchase;
		let own = this.state.own;
		let repurchase = this.state.repurchase;
		let monthRanges = this.state.monthRanges;

		if (this.state.model.type === 'samochód osobowy') {
			monthRanges = [24, 36, 48, 60, 72];
			minOwn = 0;
			maxOwn = 45;

			if (this.state.months === 24) {
				minRepurchase = 19;
				maxRepurchase = 50;
			}
			if (this.state.months === 36) {
				minRepurchase = 1;
				maxRepurchase = 40;
			}
			if (this.state.months === 48) {
				minRepurchase = 1;
				maxRepurchase = 30;
			}
			if (this.state.months === 60) {
				minRepurchase = 1;
				maxRepurchase = 20;
			}
			if (this.state.months === 72) {
				minRepurchase = 1;
				maxRepurchase = 10;
			}
		}
		if (this.state.model.type === 'motocykl') {
			minOwn = 20;
			maxOwn = 45;
		}
		if (this.state.model.type === 'quad') {
			minOwn = 30;
			maxOwn = 45;
		}
		if (this.state.model.type === 'motocykl' || this.state.model.type === 'quad') {
			monthRanges = [24, 36, 48, 60];

			if (this.state.months === 24) {
				minRepurchase = 19;
				maxRepurchase = 19;
			}
			if (this.state.months === 36) {
				minRepurchase = 1;
				maxRepurchase = 1;
			}
			if (this.state.months === 48) {
				minRepurchase = 1;
				maxRepurchase = 1;
			}
			if (this.state.months === 60) {
				minRepurchase = 1;
				maxRepurchase = 1;
			}
			if (this.state.months === 72) {
				minRepurchase = 1;
				maxRepurchase = 1;
			}
		}
		if (own < minOwn && own < maxOwn) {
			own = minOwn;
		}
		if (own > maxOwn && own > minOwn) {
			own = maxOwn;
		}
		if (repurchase < minRepurchase && repurchase < maxRepurchase) {
			repurchase = minRepurchase;
		}
		if (repurchase > maxRepurchase && repurchase > minRepurchase) {
			repurchase = maxRepurchase;
		}
		const ownAmount = (this.state.netAmount !== '' ? this.state.netAmount : 0) * own / 100;
		const repurchaseAmount = (this.state.netAmount !== '' ? this.state.netAmount : 0) * repurchase / 100;

		Promise.resolve()
			.then(() => {
				this.updateState('monthRanges', monthRanges, '', '');
			})
			.then(() => {
				this.updateState('minOwn', minOwn, '', '');
			})
			.then(() => {
				this.updateState('maxOwn', maxOwn, '', '');
			})
			.then(() => {
				this.updateState('ownAmount', ownAmount, '', '');
			})
			.then(() => {
				this.updateState('minRepurchase', minRepurchase, '', '');
			})
			.then(() => {
				this.updateState('maxRepurchase', maxRepurchase, '', '');
			})
			.then(() => {
				this.updateState('repurchase', repurchase, '', '');
			})
			.then(() => {
				this.updateState('repurchaseAmount', repurchaseAmount, '', '');
			})
			.then(() => {
				this.updateState('own', own, '', '');
			});
	}

	changeType(obj) {

		if (obj.name) {
			let arr = obj.name.split(' ');
			arr.shift();
			let type;

			if (arr.length) {
				type = arr.join(' ');
			} 
			else {
				type = obj.type;
			}
			this.updateState('type', type);

			let timeout = setTimeout(() => {
				
				this.changeSliderSettings();
				clearTimeout(timeout);
			}, 0);
		} 
		else {
			obj.preventDefault();
		}
	}

	changeFile(e) {

		const arr = e.target.value.split('\\');
		const fileName = arr[arr.length - 1]
		const file = e.target.files[0];

		this.updateState('fileName', fileName);

		let timeout = setTimeout(() => {
			
			this.updateState('file', file);
			clearTimeout(timeout);
		}, 0);
	}

	changeName(e) {

		let value = e.target.value;
		this.updateState('name', value);
	}

	changeNip(e) {

		let value = e.target.value;
		this.updateState('nip', value);
	}

	changePhone(e) {

		let value = e.target.value;
		this.updateState('phone', value);
	}

	changeEmail(e) {

		let value = e.target.value;
		this.updateState('email', value);
	}

	changeApproval(e) {
	
		let checked = e.target.checked;
		let message = '';

		if (!checked) {
			message = 'zgoda wymagana';
		}
		this.updateState('approval', checked);

		let timeout = setTimeout(() => {
			
			this.updateState('approvalErrorMessage', message);
			clearTimeout(timeout);
		}, 0);
	}

	updateState(key, value) {
		
		this.setState({
			months: key === 'months' ? value : this.state.months,
			monthRanges: key === 'monthRanges' ? value : this.state.monthRanges,
			title: key === 'title' ? value : this.state.title,
			items: key === 'items' ? value : this.state.items,
			models: key === 'models' ? value : this.state.models,
			modelName: key === 'modelName' ? value : this.state.modelName,
			model: key === 'model' ? value : this.state.model,
			modelErrorMessage: key === 'modelErrorMessage' ? value : this.state.modelErrorMessage,
			tips: key === 'tips' ? value : this.state.tips,
			numberOfSteps: this.state.numberOfSteps,
			step: key === 'step' ? value : this.state.step,
			netAmount: key === 'netAmount' ? value : this.state.netAmount,
			netAmountErrorMessage: key === 'netAmountErrorMessage' ? value : this.state.netAmountErrorMessage,
			repurchase: key === 'repurchase' ? value : this.state.repurchase,
			own: key === 'own' ? value : this.state.own,
			minRepurchase: key === 'minRepurchase' ? value : this.state.minRepurchase,
			maxRepurchase: key === 'maxRepurchase' ? value : this.state.maxRepurchase,
			minOwn: key === 'minOwn' ? value : this.state.minOwn,
			maxOwn: key === 'maxOwn' ? value : this.state.maxOwn,
			ownStep: key === 'ownStep' ? value : this.state.ownStep,
			repurchaseStep: key === 'repurchaseStep' ? value : this.state.repurchaseStep,
			repurchaseAmount: key === 'repurchaseAmount' ? value : this.state.repurchaseAmount,
			ownAmount: key === 'ownAmount' ? value : this.state.ownAmount,
			installment: key === 'installment' ? value : this.state.installment,
			mileage: key === 'mileage' ? value : this.state.mileage,
			mileageErrorMessage: key === 'mileageErrorMessage' ? value : this.state.mileageErrorMessage,
			year: key === 'year' ? value : this.state.year,
			yearErrorMessage: key === 'yearErrorMessage' ? value : this.state.yearErrorMessage,
			brand: key === 'brand' ? value : this.state.brand,
			type: key === 'type' ? value : this.state.type,
			file: key === 'file' ? value : this.state.file,
			fileName: key === 'fileName' ? value : this.state.fileName,
			name: key === 'name' ? value : this.state.name,
			nameErrorMessage: key === 'nameErrorMessage' ? value : this.state.nameErrorMessage,
			nip: key === 'nip' ? value : this.state.nip,
			nipErrorMessage: key === 'nipErrorMessage' ? value : this.state.nipErrorMessage,
			phone: key === 'phone' ? value : this.state.phone,
			phoneErrorMessage: key === 'phoneErrorMessage' ? value : this.state.phoneErrorMessage,
			email: key === 'email' ? value : this.state.email,
			emailErrorMessage: key === 'emailErrorMessage' ? value : this.state.emailErrorMessage,
			approval: key === 'approval' ? value : this.state.approval,
			approvalErrorMessage: key === 'approvalErrorMessage' ? value : this.state.approvalErrorMessage,
			valid: key === 'valid' ? value : this.state.valid,
			gReCaptchaResponse: key === 'gReCaptchaResponse' ? value : this.state.gReCaptchaResponse
		});
	}

	clearData() {
		Promise.resolve()
			.then(() => {
				this.updateState('months', 24, '', '');
			})
			.then(() => {
				this.updateState('monthRanges', [24, 36, 48, 60, 72], '', '');
			})
			.then(() => {
				this.updateState('repurchase', 0, '', '');
			})
			.then(() => {
				this.updateState('own', 0, '', '');
			})
			.then(() => {
				this.updateState('repurchaseAmount', 0, '', '');
			})
			.then(() => {
				this.updateState('ownAmount', 0, '', '');
			})
			.then(() => {
				this.updateState('netAmount', 0, 'netAmountErrorMessage', '');
			})
			.then(() => {
				this.updateState('installment', 0, '', '');
			})
			.then(() => {
				this.updateState('modelName', '', '', '');
			})
			.then(() => {
				this.updateState('mileage', '', 'mileageErrorMessage', '');
			})
			.then(() => {
				this.updateState('year', '', 'yearErrorMessage', '');
			})
			.then(() => {
				this.updateState('brand', '', '', '');
			})
			.then(() => {
				this.updateState('type', '', '', '');
			})
			.then(() => {
				this.updateState('file', '', '', '');
			})
			.then(() => {
				this.updateState('fileName', '', '', '');
			})
			.then(() => {
				this.updateState('model', {
					name: '',
					type: ''
				}, 'modelErrorMessage', '');
			})
			.then(() => {
				this.updateState('name', '', 'nameErrorMessage', '');
			})
			.then(() => {
				this.updateState('phone', '', 'phoneErrorMessage', '');
			})
			.then(() => {
				this.updateState('nip', '', 'nipErrorMessage', '');
			})
			.then(() => {
				this.updateState('email', '', 'emailErrorMessage', '');
			})
			.then(() => {
				this.updateState('approval', false, 'approvalErrorMessage', '');
			})
			.then(() => {
				this.updateState('valid', false, '', '');
			})
			.then(() => {
				this.updateState('gReCaptchaResponse', '', '', '');
			})
			.then(() => {
				this.updateState('step', 1, '', '');
			});
	}

	renderSearchTips() {
		
		return this.state.tips.length ? (
			<SearchTips>
				{this.state.tips.map((tip, i) => {
					return (
						<SearchTipsItem key={'search-tip-' + i} onClick={() => this.changeModel(tip)}>{tip.name}</SearchTipsItem>
					);
				})}
			</SearchTips>
		) : '';
	}

	renderMonths() {
		
		return (<ButtonRow>
			{this.state.monthRanges.map((item, i) => {
				return (<CustomButton
					key={'months-' + i}
					backgroundColor={this.state.months === item ? '#0065b1' : '#fff'}
					onClick={() => this.updateMonths(item)}
				>
					<MonthText
						fontColor={this.state.months === item ? palette.solidWhite : palette.zeusBlackUI}
					>
						{item} mies.
					</MonthText>
				</CustomButton>);
			})}
		</ButtonRow>);
	}

	render() {
		
		return (
			<GoogleReCaptchaProvider
				reCaptchaKey={reCaptchaKey}
				language="pl"
			>
				<Container>
					{this.state.step === 1 ? <StepContainer paddingBottom={[px(Spacing.SPACE_48), px(Spacing.SPACE_32)]}>
						<FlexContainer id="kalkulator">
							<FlexBox>
								<Box marginBottom={Spacing.SPACE_24}>
									<Text as="h3">chcę wyleasingować samochód</Text>
								</Box>
								<InputContainer>
									<SearchInputWrapper>
										<SearchTextField
											value={this.state.modelName}
											placeholder="marka i model samochodu"
											height={24}
											leadingDecoration={<Icon iconComponent={MagnifyingGlass} primaryColor={IconColor.BLUE} title="ikona" />}
											onChange={this.searchModel}
											errorMessage={this.state.modelErrorMessage}
											onBlur={this.validateModel}
										/>
										{this.renderSearchTips()}
									</SearchInputWrapper>
								</InputContainer>
								<InputContainer>
									<NetNumberField
										value={this.state.netAmount}
										label="wartość przedmiotu netto"
										trailingDecoration={<Text as="p">PLN</Text>}
										onChange={this.changeNetAmount}
										errorMessage={this.state.netAmountErrorMessage}
										onBlur={this.validateNetAmount}
									/>
								</InputContainer>
								<Box as="div">
									<Text as="small">okres leasingu</Text>
									{this.renderMonths()}
								</Box>
								<LabelContainer>
									<Text as="small">wpłata własna</Text>
									<Text as="small">{parseInt(this.state.own)}% | {parseInt(this.state.ownAmount)} zł</Text>
								</LabelContainer>
								<Box as="div">
									<Slider
										min={this.state.minOwn}
										max={this.state.maxOwn}
										step={this.state.ownStep}
										value={this.state.own}
										blocked={this.state.minOwn < this.state.maxOwn ? false : true}
										slideCallback={this.changeOwn}
										labels={[this.state.minOwn, this.state.maxOwn]}
									/>
								</Box>
								<LabelContainer>
									<Text as="small">kwota wykupu</Text>
									<Text as="small">{parseInt(this.state.repurchase)}% | {parseInt(this.state.repurchaseAmount)} zł</Text>
								</LabelContainer>
								<Box as="div">
									<Slider
										min={this.state.minRepurchase}
										max={this.state.maxRepurchase}
										step={this.state.repurchaseStep}
										value={this.state.repurchase}
										blocked={this.state.minRepurchase < this.state.maxRepurchase ? false : true}
										slideCallback={this.changeRepurchase}
										labels={[this.state.minRepurchase, this.state.maxRepurchase]}
									/>
								</Box>
								<Stack direction={["column", "row"]} alignY="center">
									<Button buttonType="primary" onClick={() => this.changeStep(2)}>oblicz</Button>
								</Stack>
							</FlexBox>
							<FlexBox>
								<Text as="h3">{this.state.title}</Text>
								<Box marginTop={px(Spacing.SPACE_24)}>
									{this.state.items.map((item, i) => {
										return (<Box as="div" key={'item-' + i} marginBottom={px(Spacing.SPACE_12)}>
											<Stack direction="row" isWrapped={false} marginBottom={px(Spacing.SPACE_12)}>
												<Icon iconComponent={Tick} primaryColor={IconColor.BLUE} title="ikona" />
												<Text as="p">{item}</Text>
											</Stack>
										</Box>);
									})}
								</Box>
							</FlexBox>
						</FlexContainer>
					</StepContainer> : ''}
					{this.state.step === 2 ? (<StepContainer>
						<BackButton onClick={() => this.changeStep(1)}>
							<Box marginTop={Spacing.SPACE_2}>
								<Icon
									iconComponent={ArrowLeft}
									primaryColor={IconColor.BLACK}
									title="ikona"
								/>
							</Box>
							<Box>
								<Text as="small" marginLeft={Spacing.SPACE_16} >powrót</Text>
							</Box>
						</BackButton>
						<FlexContainer id="kalkulator">
							<FlexBox>
								<Box marginBottom={Spacing.SPACE_24}>
									<Text as="h3">Twoja rata leasingu</Text>
								</Box>
								<Box marginBottom={Spacing.SPACE_8}>
									<Text as="h4">Wstępna kalkulacja raty leasingowej (obniżymy ją w kolejnym kroku)</Text>
								</Box>
								<Box marginBottom={Spacing.SPACE_24}>
									<Text as="h3">{this.formatNumber(parseInt(this.state.installment))} PLN netto</Text>
								</Box>
								<FlexContainerInner paddingRight={px(Spacing.SPACE_0)} paddingLeft={px(Spacing.SPACE_0)}>
									<FlexBox paddingRight={px(Spacing.SPACE_0)} paddingLeft={px(Spacing.SPACE_0)}>
										<Box marginBottom={Spacing.SPACE_8}>
											<Text as="p">chcę wyleasingować</Text>
										</Box>
										<Text as="h3">{this.state.model.name}</Text>
									</FlexBox>
									<FlexBox paddingRight={px(Spacing.SPACE_0)} paddingLeft={px(Spacing.SPACE_0)}>
										<Box marginBottom={Spacing.SPACE_8}>
											<Text as="p">liczba rat</Text>
										</Box>
										<Text as="h3">{this.state.months}</Text>
									</FlexBox>
								</FlexContainerInner>
								<Tile>
									<Box marginBottom={Spacing.SPACE_16}>
										<Text as="h3">Możemy obniżyć tę ratę!</Text>
									</Box>
									<Box marginBottom={Spacing.SPACE_24}>
										<Text as="h4">podaj dodatkowe informacje, abyśmy mogli przedstawić Ci korzystniejszą ofertę</Text>
									</Box>
									<Box>
										<Button
											buttonType="tertiary"
											color={ButtonColor.BLUE}
											onClick={() => this.changeStep(3)}
										>
											Podaj więcej danych
										</Button>
									</Box>
								</Tile>
								<ButtonBox>
									<Button
										buttonType="primary"
										onClick={() => this.changeStep(4)}
									>
										Dalej (dane kontaktowe)
									</Button>
								</ButtonBox>
							</FlexBox>
							<FlexBox>
								<IllustrationContainer>
									<Illustration illustrationComponent={RetailCarHighway} size={309} />
								</IllustrationContainer>
							</FlexBox>
						</FlexContainer>
						<FlexContainer>
							<FlexBox>
								<Text as="p" marginTop={Spacing.SPACE_16}>przygotujemy dla Ciebie dedykowaną ofertę z kilkoma opcjami finansowania</Text>
							</FlexBox>
						</FlexContainer>
						<BottomBar>
							<Text as="p" color="solidWhite">Samochód o wartości {this.formatNumber(this.state.netAmount)} PLN z wpłatą własną {this.formatNumber(this.state.ownAmount)} PLN i kwotą wykupu {this.formatNumber(this.state.repurchaseAmount)} PLN sfinansujesz w {this.state.months} ratach - płacisz {this.formatNumber((this.state.installment / 30).toFixed(2))} PLN dziennie.</Text>
						</BottomBar>
					</StepContainer>) : ''}
					{this.state.step === 3 ? (<StepContainer>
						<BackButton onClick={() => this.changeStep(2)}>
							<Box marginTop={Spacing.SPACE_2}>
								<Icon
									iconComponent={ArrowLeft}
									primaryColor={IconColor.BLACK}
									title="ikona"
								/>
							</Box>
							<Box>
								<Text as="small" marginLeft={Spacing.SPACE_16} >powrót</Text>
							</Box>
						</BackButton>
						<FlexContainer id="kalkulator">
							<FlexBox>
								<Box marginBottom={Spacing.SPACE_24}>
									<Text as="h3">Uzupełnij dodatkowe informacje o przedmiocie leasingu</Text>
								</Box>
								<FlexContainerInner paddingRight={px(Spacing.SPACE_0)} paddingLeft={px(Spacing.SPACE_0)}>
									<FlexBox paddingRight={px(Spacing.SPACE_0)} paddingLeft={px(Spacing.SPACE_0)}>
										<Box marginBottom={Spacing.SPACE_16} marginRight={[Spacing.SPACE_0, Spacing.SPACE_24]}>
											<TextField
												placeholder="Rok produkcji"
												value={this.state.year}
												onChange={this.changeYear}
											/>
										</Box>
										<Box marginBottom={[Spacing.SPACE_16, Spacing.SPACE_24]} marginRight={[Spacing.SPACE_0, Spacing.SPACE_24]}>
											<TextField
												placeholder="Deklarowany przebieg roczny"
												value={this.state.mileage}
												onChange={this.changeMilage}
											/>
										</Box>
										<Box marginBottom={Spacing.SPACE_16} marginRight={[Spacing.SPACE_0, Spacing.SPACE_24]}>
											<Text as="small">dodaj specyfikację przedmiotu lub fakturę</Text>
										</Box>
										<FileInputBox marginBottom={[Spacing.SPACE_0, Spacing.SPACE_24]} marginRight={[Spacing.SPACE_0, Spacing.SPACE_24]}>
											<TextField
												placeholder="dodaj załącznik"
												value={this.state.fileName}
											/>
											<FileInput
												type="file"
												onChange={this.changeFile}
											/>
										</FileInputBox>
									</FlexBox>
									<FlexBox paddingRight={px(Spacing.SPACE_0)} paddingLeft={px(Spacing.SPACE_0)}>
										<Box marginBottom={Spacing.SPACE_16}>
											<TextField
												label="marka"
												value={this.state.brand}
												onChange={this.changeBrand}
											/>
										</Box>
										<Box marginBottom={Spacing.SPACE_24}>
											<TextField
												label="model/typ"
												value={this.state.type}
												onChange={this.changeType}
											/>
										</Box>
									</FlexBox>
								</FlexContainerInner>
								<ButtonBox>
									<Button
										buttonType="primary"
										onClick={() => this.changeStep(4)}
									>
										Dalej (dane kontaktowe)
									</Button>
								</ButtonBox>
								<Box>
									<Text as="p" marginTop={Spacing.SPACE_16}>przygotujemy dla Ciebie dedykowaną ofertę z kilkoma opcjami finansowania</Text>
								</Box>
							</FlexBox>
						</FlexContainer>
						<BottomBar>
							<Text as="p" color="solidWhite">Samochód o wartości {this.formatNumber(this.state.netAmount)} PLN z wpłatą własną {this.formatNumber(this.state.ownAmount)} PLN i kwotą wykupu {this.formatNumber(this.state.repurchaseAmount)} PLN sfinansujesz w {this.state.months} ratach - płacisz {this.formatNumber((this.state.installment / 30).toFixed(2))} PLN dziennie.</Text>
						</BottomBar>
					</StepContainer>) : ''}
					{this.state.step === 4 ? (<StepContainer>
						<BackButton onClick={() => this.changeStep(2)}>
							<Box marginTop={Spacing.SPACE_2}>
								<Icon
									iconComponent={ArrowLeft}
									primaryColor={IconColor.BLACK}
									title="ikona"
								/>
							</Box>
							<Box>
								<Text as="small" marginLeft={Spacing.SPACE_16} >powrót</Text>
							</Box>
						</BackButton>
						<FlexContainer id="kalkulator">
							<FlexBox>
								<Box marginBottom={Spacing.SPACE_24}>
									<Text as="h3">Oferta tylko dla Ciebie</Text>
								</Box>
								<Box marginBottom={Spacing.SPACE_24}>
									<Text as="p">Wypełnij poniższy formularz abyśmy mogli przygotować dla Ciebie dedykowaną ofertę ze zniżką / w promocji</Text>
								</Box>
								<FlexContainerInner paddingRight={px(Spacing.SPACE_0)} paddingLeft={px(Spacing.SPACE_0)}>
									<FlexBox paddingRight={px(Spacing.SPACE_0)} paddingLeft={px(Spacing.SPACE_0)} validated={true}>
										<Box marginBottom={Spacing.SPACE_16} marginRight={[Spacing.SPACE_0, Spacing.SPACE_24]}>
											<TextField
												placeholder="Imię i nazwisko"
												value={this.state.name}
												onChange={this.changeName}
												errorMessage={this.state.nameErrorMessage}
												onBlur={this.validateName}
											/>
										</Box>
										<Box marginBottom={[Spacing.SPACE_0, Spacing.SPACE_16]} marginRight={[Spacing.SPACE_0, Spacing.SPACE_24]}>
											<TextField
												placeholder="NIP"
												value={this.state.nip}
												onChange={this.changeNip}
												errorMessage={this.state.nipErrorMessage}
												onBlur={this.validateNip}
											/>
										</Box>
									</FlexBox>
									<FlexBox paddingRight={px(Spacing.SPACE_0)} paddingLeft={px(Spacing.SPACE_0)} validated={true}>
										<Box marginBottom={Spacing.SPACE_16}>
											<TextField
												placeholder="Numer telefonu"
												value={this.state.phone}
												onChange={this.changePhone}
												errorMessage={this.state.phoneErrorMessage}
												onBlur={this.validatePhone}
											/>
										</Box>
										<Box marginBottom={Spacing.SPACE_16}>
											<TextField
												placeholder="Adres e-mail"
												value={this.state.email}
												onChange={this.changeEmail}
												errorMessage={this.state.emailErrorMessage}
												onBlur={this.validateEmail}
											/>
										</Box>
									</FlexBox>
								</FlexContainerInner>
								<Box marginTop={Spacing.SPACE_16} marginBottom={Spacing.SPACE_16}>
									<Text as="small" color="doveGrayUI">Oświadczenia i zgody</Text>
								</Box>
								<NoteContainer>
									<ApprovalContainer paddingBottom={this.state.approvalErrorMessage ? px(Spacing.SPACE_24) : 0}>
										<Checkbox
											value={this.state.approval}
											onChange={this.changeApproval}
										/>
										{this.state.approvalErrorMessage ? (<ErrorMessage>
											<ValidationMessage>{this.state.approvalErrorMessage}</ValidationMessage>
										</ErrorMessage>) : ''}
									</ApprovalContainer>
									<Box>
										<Text as="small">Podając w formularzu numer telefonu, akceptuję <a href="/pdf/formularze_regulamin-sude.pdf" download>regulamin</a> oraz potwierdzam zgodę na kontakt telefoniczny ze mną przez mLeasing Sp. z o.o. w celu otrzymania interesujących mnie informacji handlowych. Wskazując w formularzu dodatkowo adres e-mail, potwierdzam zgodę na otrzymanie interesujących mnie informacji handlowych drogą elektroniczną</Text>
									</Box>
								</NoteContainer>
								<ButtonNoteContainer>
									<Box marginRight={Spacing.SPACE_16} marginTop={Spacing.SPACE_16}>
										<Button
											buttonType="primary"
											onClick={() => this.changeStep(5)}
										>
											Wyślij
										</Button>
									</Box>
									<Box marginTop={Spacing.SPACE_16}>
										<Text as="p">przygotujemy dla Ciebie dedykowaną z obniżoną ratą oraz atrakcyjnymi opcjami jak: serwis w racie, opony zimowe, preferecyjne ubezpieczenie</Text>
									</Box>
								</ButtonNoteContainer>
							</FlexBox>
						</FlexContainer>
						<BottomBar>
							<Text as="p" color="solidWhite">Samochód o wartości {this.formatNumber(this.state.netAmount)} PLN z wpłatą własną {this.formatNumber(this.state.ownAmount)} PLN i kwotą wykupu {this.formatNumber(this.state.repurchaseAmount)} PLN sfinansujesz w {this.state.months} ratach - płacisz {this.formatNumber((this.state.installment / 30).toFixed(2))} PLN dziennie.</Text>
						</BottomBar>
					</StepContainer>) : ''}
					{this.state.step === 5 ? (<StepContainer>
						<FlexContainer id="kalkulator">
							<FlexContainerCentered>
								<Box marginBottom={Spacing.SPACE_32}>
									<Text as="h1">Gotowe!</Text>
								</Box>
								<Box marginBottom={Spacing.SPACE_32}>
									<Illustration illustrationComponent={RetailHandThumbUp} size={309} />
								</Box>
								<Box>
									<Button
										buttonType="primary"
										onClick={() => {this.changeStep(1)}}
									>
										Policz od nowa
									</Button>
								</Box>
							</FlexContainerCentered>
						</FlexContainer>
						<BottomBar>
							<Text as="p" color="solidWhite">Twój formularz został wysłany.</Text>
							<Text as="p" color="solidWhite">Przygotujemy dla Ciebie dedykowaną ofertę z obniżoną ratą oraz atrakcyjnymi opcjami jak: serwis w racie, opony zimowe, preferecyjne ubezpieczenie.</Text>
							<Text as="p" color="solidWhite">Odpowiemy tak szybko jak będzie to możliwe.</Text>
						</BottomBar>
					</StepContainer>) : ''}
				</Container>
			</GoogleReCaptchaProvider>
		);
	}
}

export default Calculator;
