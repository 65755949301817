/*!
 * mLeasing, generate link utility :: 10/03/2021
 * uses <Link> for internal and <a> for external links
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import {Link} from '@reach/router';
import styled from 'styled-components';

const version = 'v: 1.0.0 :: r. 15/04/2021 @ fs';

const clickHandler = () => {
	
	if (window) {

		const loader = document.querySelector('#loader');
		const body = document.querySelector('body');

		if (loader) {
			loader.classList.remove('hidden');
		}

		if (body) {
			body.classList.add('hidden');
		}

	}

};
export const generateLink = (href, linkName, styleStr, children, offline, openInNewTab) => {
	
	const StyledAnchor = styled.a`${styleStr}`;
	const StyledLink = styled(Link)`${styleStr}`;
	let link = '';

	if (href.match(/http/) || href.match(/\.(pdf|zip|doc|docx|xls|xlsx)/g) || href.match(/^mailto/) || href.match(/^tel/) || href.match(/#.*$/)) {

		if (href.match(/\.(pdf|zip|doc|docx|xls|xlsx)/g)) {
			link =  <StyledAnchor href={href} download>{children || linkName}</StyledAnchor>;
		} else if (href.match(/http/) || href.match(/\.pdf/g)) {
			link =  <StyledAnchor href={href} target={openInNewTab ? '_blank' : ''}>{children || linkName}</StyledAnchor>;
		} else if (href.match(/^mailto/)) {
			link =  <StyledAnchor href={href}>{children || linkName}</StyledAnchor>;
		} else if (href.match(/^tel/)) {
			link =  <StyledAnchor href={href}>{children || linkName}</StyledAnchor>;
		} else if (href.match(/#.*$/)) {
			link =  <StyledAnchor href={href}>{children || linkName}</StyledAnchor>;
		}

	} else {

		if (offline) {
			link =  <StyledAnchor href={href}>{children || linkName}</StyledAnchor>;
		} else {
			link =  <StyledLink to={href} onClick={clickHandler}>{children || linkName}</StyledLink>;
		}

	}

	return link;
};

