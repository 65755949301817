/*!
 * mLeasing, search-box :: 01/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import {navigate} from 'gatsby-link';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import Container from '../../components/container.js';
import {Box} from '@mbank-design/design-system/components';
import {Stack} from '@mbank-design/design-system/components';
import {Text} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {ButtonColor} from '@mbank-design/design-system/enums';
import {MbankLeasing as Logo} from '@mbank-design/design-system/logos';
import palette from '@mbank-design/design-system/palette';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {Icon} from '@mbank-design/design-system/components';
import { IconSize } from '@mbank-design/design-system/enums';
import {HumanHeadset} from '@mbank-design/design-system/icons';
import {MagnifyingGlass} from '@mbank-design/design-system/icons';
import {ChevronRight} from '@mbank-design/design-system/icons';
import {ChevronLeft} from '@mbank-design/design-system/icons';
import {Tick} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import {mapLinks} from '../../utils/map-links.js';
import {generateLink} from '../../utils/generate-link.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';

const version = 'v: 1.0.4 :: r. 01/06/2021 @ fs';
const FixedHeader = styled.div`
	position: fixed;
	width: 100%;
	z-index: 3;
	left: 0;
	top: 4px;
	background: ${palette.solidWhite};
	padding-top: 10px;
	padding-bottom: 10px;
`;
const FixedHeaderInner = styled.div`
	display: flex;
	align-items: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: 16px;
		padding-left: 16px;
		flex-wrap: wrap;
		jaustify-content: center;
	}
`;
const LogoContainer = styled.div`
	margin-right: 24px;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const ButtonContainer = styled.div`
	margin-left: 24px;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
	}
`;
const ButtonArea = styled.div`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-top: ${px(Spacing.SPACE_24)};
		margin-bottom: ${px(Spacing.SPACE_24)};
	}
`;
const SearchTextFieldOuter = styled.div`
	width: 100%;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: 100%;
	}
`;
const SearchTextFieldWrapper = styled.div`
	position: relative;
	width: 100%;
	background-color: #ffffff;
	border: 2px solid #d8d8d8;
    border-top-color: rgb(216, 216, 216);
    border-right-color: rgb(216, 216, 216);
    border-bottom-color: rgb(216, 216, 216);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-left-color: rgb(216, 216, 216);
	border-radius: 0;
	border-bottom: 2px solid #d8d8d8;
    border-bottom-color: rgb(216, 216, 216);
	display: inline-flex;
	height: 62px;
	padding-right: 44px;
	${props => props.focus ? 'border-color: #6e6e6e;' : ''}
`;
const SearchTextFieldIcon = styled.div`
	display: flex;
	flex-shrink: 0;
	align-items: center;
	background-color: inherit;
	margin-left: 16px;
`;
const SearchTextFieldInner = styled.div`
    position: relative;
    width: inherit;
    height: 100%;
    overflow-x: hidden;
}
`;
const SearchTextField = styled.input`
	padding-right: ${px(Spacing.SPACE_44)};
	width: 100%;
	height: 100%;
	padding: 8px 16px;
	color: #26221e;
	font-weight: 500;
	font-size: 1rem;
	font-family: inherit;
	line-height: 24px;
	background: #ffffff;
	border: 0;
	outline: none;
	caret-color: #0065b1;
`;
const InputContainer = styled.div`
	position: relative;
	margin-right: ${px(Spacing.SPACE_24)};
	flex-grow: 1;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: ${px(Spacing.SPACE_0)};
		width: 100%;
	}
`;
const FlexContainer = styled.div`
	display: flex;
	justify-content: ${props => props.standalone ? 'center' : 'flex-start'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: ${props => props.standalone ? 'row' : 'column'};
	}
`;
const ExtendedFlexContainer = styled(FlexContainer)`
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: row;
	}
`;
const FlexContainerInner = styled(FlexContainer)`
	flex-wrap: nowrap;
	align-items: center;
	padding: ${px(Spacing.SPACE_24)} 0;
	max-width: ${props => props.standalone ? px(644) : 'unset'};
	margin-right: auto;
	margin-left: auto;
`;
const FlexBox = styled(Box)`
	flex-grow: 1;
	flex-shrink: 0;
	padding: ${px(Spacing.SPACE_32)};
	background-color: ${palette.solidWhite};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding: ${px(Spacing.SPACE_40)} ${px(Spacing.SPACE_16)};
	}
`;
const LongFlexBox = styled(FlexBox)`
	flex-basis: calc(66.66% - ${px(Spacing.SPACE_12)});
	margin-right: ${px(Spacing.SPACE_24)};
	max-width: ${props => props.standalone ? px(868) : 'unset'};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: ${px(Spacing.SPACE_0)};
		margin-bottom: ${px(Spacing.SPACE_16)};
	}
`;
const ShortFlexBox = styled(FlexBox)`
	flex-basis: calc(33.33% - ${px(Spacing.SPACE_12)});
`;
const HeaderBox = styled(Box)`
	max-width: ${px(525)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		text-align: center;
	}
`;
const Subtitle = styled.div`
	margin-left: ${px(Spacing.SPACE_16)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-left: ${px(Spacing.SPACE_0)};
	}
`;
const SubtitleContainer = styled.div`
	display: flex;
	max-width: ${props => props.standalone ? px(644) : 'unset'};
	text-align: center;
	margin-right: auto;
	margin-left: auto;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		align-items: center;
		text-align: left;
	}
`;
const TextContainer = styled.div`
	margin-top: ${px(Spacing.SPACE_16)};
	text-align: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		text-align: ${props => props.alignLeft ? 'left' : 'center'};
	}
`;
const SearchTipsContainer = styled.div`
	position: absolute;
	top: ${Spacing.SPACE_62};
	right: ${Spacing.SPACE_0};
	left: ${Spacing.SPACE_0};
	z-index: 2;
	max-height: ${px(288)};
	overflow: auto;
	border: ${props => props.border}px solid ${palette.altoGray};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-height: ${px(456)};
	}
`;
const SearchTipsItem = styled.div`
	position: relative;
	border-bottom: 1px solid ${palette.altoGray};
	padding: ${px(Spacing.SPACE_8)};
	background-color: ${palette.solidWhite};
	cursor: pointer;

	&:last-child {
		border-bottom: 0;
	}
`;
const ResultsContainer = styled.div`
	padding-top: 16px;
	padding-bottom: 16px;
`;
const ResultItem = styled.div`
	position: relative;
	margin: 8px 0;
	padding: 8px 100px 8px 0;
	border-bottom: 1px solid ${palette.altoGray};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding: 8px 40px 8px 16px;
	}
`;
const IconContainer = styled.div`
	position: absolute;
	right: 0;
	bottom: 28px;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		top: 32px;
		right: 8px;
		bottom: auto;
	}
`;
const ResultTextContainer = styled.div`
	margin-top: 12px;
	margin-bottom: 10px;
`;
const NumberOfResultsContainer = styled.div`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: 16px;
		padding-left: 16px;
	}
`;
const resultLinkStyles = `
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;
const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
`;
const PaginationItem = styled.div`
	margin: 0 8px;
	cursor: pointer;
	padding: 12px;
	width: 40px;
	text-align: center;
	color: ${props => props.active ? palette.solidWhite : palette.endeavourBlueUI};
	background-color: ${props => props.active ? palette.endeavourBlueUI : props.background};
`;
const PaginationIconContainer = styled.div`
	display: ${props => props.elementDisplay ? 'flex' : 'none'};
	align-items: center;
	margin-right: ${props => props.marginRight || 0};
	margin-left: ${props => props.marginLeft || 0};
	cursor: pointer;
`;
const linkStyles = `
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	width: 100%
`;
const buildState = (props, state) => {

	let title = '';
	let searchTitle = '';
	let link = '';
	let linkName = '';
	let items = [];
	let standalone = false;
	let text = '';
	let subtitle = '';
	let icon = '';
	let placeholder = '';
	let altText = '';
	let searchTips = 0;
	let searchResults = false;

	if (props.offline) {
		title = props.data.title;
		searchTitle = props.data.searchTitle;
		subtitle = props.data.subtitle;
		text = props.data.text;
		icon = props.data.icon;
		altText = props.data.altText || '';
		link = mapLinks([{
			url: props.data.link,
			navText: props.data.linkName,
		}], props.offline)[0].url;
		linkName = props.data.linkName;
		standalone = props.data.standalone === 'true' ? true : false;
		searchResults = props.data.results === 'true' ? true : false;
		searchTips = props.data.searchTips ? props.data.searchTips : 0;;
		placeholder = props.data.placeholder ? props.data.placeholder : '';
		items = props.data.items ? props.data.items : [];
	} else {

		try {

			let obj = props.data['Search-boxData']['Search-box'];
			let textLinks = [];
			let subtitleLinks = [];

			title = obj.Title;
			searchTitle = obj.SearchTitle;

			if (obj.Text) {

				if (Array.isArray(obj.Text.links.link)) {

					textLinks = obj.Text.links.link.map(link => {
						return {
							name: link.name,
							target: link.target || link.anchor
						};
					});

				} else if (obj.Text.links !== '') {

					textLinks = [{
						name: obj.Text.links.link.name,
						target: obj.Text.links.link.target || obj.Text.links.link.anchor
					}];

				}

			}

			if (obj.Subtitle) {

				if (Array.isArray(obj.Subtitle.links.link)) {

					subtitleLinks = obj.Subtitle.links.link.map(link => {
						return {
							name: link.name,
							target: link.target || link.anchor
						};
					});

				} else if (obj.Subtitle.links !== '') {

					subtitleLinks = [{
						name: obj.Subtitle.links.link.name,
						target: obj.Subtitle.links.link.target || obj.Subtitle.links.link.anchor
					}];

				}

			}

			text = replaceCmsContentLinks(obj.Text.content, textLinks);
			subtitle = replaceCmsContentLinks(obj.Subtitle.content, subtitleLinks);
			icon = obj.Icon ? obj.Icon.link.target.replace(environment.cmsExportPath, '') : '';
			altText = obj.AltText || '';
			placeholder = obj.Placeholder ? obj.Placeholder : '';
			link = obj.Link ? mapLinks([{
				url: obj.Link.link.target,
				navText: obj.LinkName,
			}], props.offline)[0].url : [];
			linkName = obj.LinkName;
			standalone = props.data.Standalone === 'true' ? true : false;
			searchTips = props.data['Search-tips'] ? props.data['Search-tips'] : 0;
			searchResults = props.data.Results === 'true' ? true : false;
			items = obj.TextItem;
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		searchTitle: searchTitle,
		subtitle: subtitle,
		text: text,
		icon: icon,
		altText: altText,
		link: link,
		linkName: linkName,
		placeholder: placeholder,
		standalone: standalone,
		searchTips: searchTips,
		items: items,
		searchTipsData: state ? state.searchTipsData : [],
		searchPhrase: state ? state.searchPhrase : '',
		searchTipsResults: state ? state.searchTipsResults : [],
		searchResults: searchResults,
		results: state ? state.results : [],
		numberOfResults: state ? state.numberOfResults : 0,
		showNumberOfResults: state ? state.showNumberOfResults : false,
		showTipsResults: state ? state.showTipsResults : false,
		pageNumber: state ? state.pageNumber : 1,
		focus: state ? state.focus : false
	};

};

class SearchBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.searchTipsCallback = this.searchTipsCallback.bind(this);
		this.searchCallback = this.searchCallback.bind(this);
		this.search = this.search.bind(this);
		this.clear = this.clear.bind(this);
		this.focusCallback = this.focusCallback.bind(this);
		this.blurCallback = this.blurCallback.bind(this);
		this.renderSearchTips = this.renderSearchTips.bind(this);
		this.renderTipsResults = this.renderTipsResults.bind(this);
		this.renderResults = this.renderResults.bind(this);
		this.renderResultsLabel = this.renderResultsLabel.bind(this);
		this.renderPagination = this.renderPagination.bind(this);
		this.updateState = this.updateState.bind(this);
		this.redirect = this.redirect.bind(this);
		this.searchTipsClickCallback = this.searchTipsClickCallback.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	redirect(e, url) {

		if (!this.state.searchResults) {

			let redirectUrl = '';

			if (this.state.searchPhrase && this.state.searchPhrase.length >= 3) {

				if (url) {
					redirectUrl = url;
				} else if (this.state.searchTips === '1') {
					redirectUrl = '/wyniki-wyszukiwania/?search=' + this.state.searchPhrase;
				} else {
					redirectUrl = '/wyniki-wyszukiwania-obsluga/?search=' + this.state.searchPhrase;
				}

				if (e.nativeEvent.type === 'click') {

					if (redirectUrl.match(/http/)) {
						window.location.href = redirectUrl;
					} else {

						if (redirectUrl.match(/#/g)) {

							let path = window.location.pathname;
							let href = redirectUrl.split('#')[0];

							if (path[path.length - 1] === '/') {
								path = path.slice(0, path.length - 1);
							}

							if (href[href.length - 1] === '/') {
								href = href.slice(0, href.length - 1);
							}

							if (path === href) {
								window.location.hash = redirectUrl.split('#')[1];

								let elem = document.querySelector('.exp-content [aria-expanded=true]');
								let header = document.querySelector('#header-content');

								window.scrollTo(0, elem.getBoundingClientRect().y - header.clientHeight)
							} else {
								window.location.href = redirectUrl;
							}

						} else {
							navigate(redirectUrl);
						}

					}

				}

				if (e.nativeEvent.type === 'keypress' && e.code === 'Enter') {

					if (redirectUrl.match(/http/)) {
						window.location.href = redirectUrl;
					} else {

						if (redirectUrl.match(/#/g)) {

							let path = window.location.pathname;
							let href = redirectUrl.split('#')[0];

							if (path[path.length - 1] === '/') {
								path = path.slice(0, path.length - 1);
							}

							if (href[href.length - 1] === '/') {
								href = href.slice(0, href.length - 1);
							}

							if (path === href) {
								window.location.hash = redirectUrl.split('#')[1];

								let elem = document.querySelector('.exp-content [aria-expanded=true]');
								let header = document.querySelector('#header-content');

								window.scrollTo(0, elem.getBoundingClientRect().y - header.clientHeight)
							} else {
								window.location.href = redirectUrl;
							}

						} else {
							navigate(redirectUrl);
						}

					}

				}

			}

		}

	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('search box');
		}

		let url = '';

		if (parseInt(this.state.searchTips) === 1) {
			url = environment.apiSearchPath + environment.apiSearchMainFileName;
		}

		if (parseInt(this.state.searchTips) === 2) {
			url = environment.apiSearchPath + environment.apiSearchServiceFileName;
		}

		// hide header
		if (window && this.state.searchResults) {

			const main = document.querySelector('#main');
			const headerContent = document.querySelector('#header-content > div');

			if (main) {
				main.style.paddingTop = window.innerWidth > breakpoints.mobileMax ? '86px' : '146px';
			}

			if (headerContent) {
				headerContent.style.display = 'none';
			}

			if (window.location.search) {

				let searchStr = window.location.search.replace('?search=', '');
					searchStr = decodeURIComponent(searchStr);

				this.updateState('searchPhrase', searchStr);

				let timeout = setTimeout(() => {
					this.search(1);
					clearTimeout(timeout);
				}, 0);
			}

		}

		if (url) {
			fetch(url)
				.then(res => res.text())
				.then(csv => {

					let data = csv.split('\n');

					data = data.map(line => ({
						phrase: line.split(';')[0],
						description: line.split(';')[1] ? line.split(';')[1].replace('\r', '') : '',
						url: line.split(';')[2] ? line.split(';')[2].replace('\r', '') : ''
					}));
					this.updateState('searchTipsData', data);

					if (window.location.hash) {

						let timeout = setTimeout(() => {

							let elem = document.querySelector('.exp-content [aria-expanded=true]');
							let header = document.querySelector('#header-content');

							window.scrollTo(0, elem.getBoundingClientRect().y - header.clientHeight)
							clearTimeout(timeout);
						}, 500);

					}
				})
				.catch(err => {
					console.log(err);
				})
		}

	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	updateState(key, value) {
		this.setState({
			offline: this.state.offline,
			pageType: this.state.pageType,
			title: this.state.title,
			searchTitle: this.state.searchTitle,
			subtitle: this.state.subtitle,
			text: this.state.text,
			icon: this.state.icon,
			altText: this.state.altText,
			link: this.state.link,
			linkName: this.state.linkName,
			placeholder: this.state.placeholder,
			standalone: this.state.standalone,
			searchTips: this.state.searchTips,
			items: this.state.items,
			searchTipsData: key === 'searchTipsData' ? value : this.state.searchTipsData,
			searchPhrase: key === 'searchPhrase' ? value : this.state.searchPhrase,
			searchTipsResults: key === 'searchTipsResults' ? value : this.state.searchTipsResults,
			focus: key === 'focus' ? value : this.state.focus,
			results: key === 'results' ? value : this.state.results,
			numberOfResults: key === 'numberOfResults' ? value : this.state.numberOfResults,
			showNumberOfResults: key === 'showNumberOfResults' ? value : this.state.showNumberOfResults,
			showTipsResults: key === 'showTipsResults' ? value : this.state.showTipsResults,
			pageNumber: key === 'pageNumber' ? value : this.state.pageNumber,
		});
	}

	searchTipsCallback(e) {

		let phrase = e.target.value;
		let tipsResults = [];
		let filteredTipsResults = [];

		if (phrase.length >= 3) {
			tipsResults = this.state.searchTipsData.filter(result => result.phrase.toLowerCase().match(new RegExp(phrase.toLowerCase())));
		}

		tipsResults.forEach((result) => {

			let arr = filteredTipsResults.filter(el => el.url === result.url && el.description === result.description);

			if (!arr.length) {
				filteredTipsResults.push(result);
			}

		});

		this.updateState('searchPhrase', phrase);

		let timeout = setTimeout(() => {
			this.updateState('searchTipsResults', filteredTipsResults);
			clearTimeout(timeout);
		}, 0);
	}

	focusCallback(e) {
		this.updateState('focus', true);
	}

	blurCallback(e) {
		this.updateState('focus', false);
	}

	searchCallback(e) {

		if (e.code === 'Enter') {
			this.search(1);
		}

	}

	search(pageNumber) {

		if (this.state.searchPhrase && this.state.searchPhrase.length >= 3) {

			// const searchStr = `${this.state.searchPhrase}/${pageNumber}?query=${this.state.searchPhrase}&use=90FE73&name=default&page=${pageNumber}`;
			const searchStr = `${this.state.searchPhrase}/${pageNumber}/`;
			const url = environment.apiSearchResultsPath + searchStr;

			fetch(url)
				.then(res => res.text())
				.then(text => {

					const fragment = new DocumentFragment();
					let results = [];

					fragment.appendChild(document.createElement('div'));
					fragment.querySelector('div').innerHTML = text;

					const titleElements = fragment.querySelectorAll('.osscmnrdr.ossfieldrdr1');
					const textElements = fragment.querySelectorAll('.osscmnrdr.ossfieldrdr2');
					const linkElements = fragment.querySelectorAll('.osscmnrdr.ossfieldrdr3');
					const numberOfResultsElement = fragment.querySelector('.osscmnrdr.ossnumfound');
					const numberOfResults = numberOfResultsElement && parseInt(numberOfResultsElement.textContent) ?
						parseInt(numberOfResultsElement.textContent) : 0;

					textElements.forEach((element, i) => {

						let title = '';
						let text = '';
						let url = '';

						if (element.textContent) {
							text = element.textContent.replace(/\n/g, '').replace(/\t/g, '');
						}

						if (linkElements[i] && linkElements[i].textContent) {
							url = linkElements[i].textContent.replace(/\n/g, '').replace(/\t/g, '');
						}

						if (titleElements[i] && titleElements[i].textContent) {
							title = titleElements[i].textContent.replace(/\n/g, '').replace(/\t/g, '');
						}

						results.push({
							title: title,
							text: text,
							url: url
						});
					});
					Promise.resolve()
						.then(() => {
							this.updateState('results', results);
						})
						.then(() => {
							this.updateState('numberOfResults', numberOfResults);
						})
						.then(() => {
							this.updateState('showNumberOfResults', true);
						})
						.then(() => {
							this.updateState('showTipsResults', true);
						})
						.then(() => {
							this.updateState('pageNumber', pageNumber);
						});

				});
		} else if (!this.state.searchPhrase || this.state.searchPhrase.length < 3) {
			this.updateState('results', []);

			let timeout1 = setTimeout(() => {
				this.updateState('showNumberOfResults', false);
				clearTimeout(timeout1);
			}, 0);
			let timeout2 = setTimeout(() => {
				this.updateState('numberOfResults', 0);
				clearTimeout(timeout2);
			}, 0);
		}

	}

	clear() {
		Promise.resolve()
			.then(() => {
				this.updateState('results', []);
			})
			.then(() => {
				this.updateState('numberOfResults', 0);
			})
			.then(() => {
				this.updateState('showNumberOfResults', false);
			})
			.then(() => {
				this.updateState('showTipsResults', false);
			})
			.then(() => {
				this.updateState('pageNumber', 1);
			});
	}

	renderResults() {
		return this.state.results.map((result, i) => {
			return (<ResultItem key={'result-' + i}>
				{result.url ? generateLink(result.url, '', resultLinkStyles, '', this.state.offline) : ''}
				{result.title ? <Text as="h2">{result.title}</Text> : ''}
				{result.text ? <ResultTextContainer><Text as="p">{result.text}</Text></ResultTextContainer> : ''}
				<IconContainer>
					<Icon iconComponent={ChevronRight} primaryColor={IconColor.BLUE} title="ikona" />
				</IconContainer>
			</ResultItem>);
		});
	}

	renderTipsResults() {

		let tipsResults = [];
		let filteredTipsResults = [];

		if (this.state.searchPhrase.length >= 3) {
			tipsResults = this.state.searchTipsData.filter(result => result.phrase.toLowerCase().match(new RegExp(this.state.searchPhrase.toLowerCase())));
		}

		tipsResults.forEach((result) => {

			let arr = filteredTipsResults.filter(el => el.url === result.url && el.description === result.description);

			if (!arr.length) {
				filteredTipsResults.push(result);
			}

		});

		filteredTipsResults = filteredTipsResults.reduce((acc, current, index, arr) => {

			if (index <= 3) {
				return [...acc, current];
			} else {
				return acc;
			}

		}, []);

		return filteredTipsResults.map((result, i) => {
			return (<ResultItem key={'result-' + i}>
				{result.url ? generateLink(result.url, '', resultLinkStyles, '', this.state.offline) : ''}
				{result.phrase ? <Text as="h2">{result.phrase}</Text> : ''}
				{result.description ? <ResultTextContainer><Text as="p">{result.description}</Text></ResultTextContainer> : ''}
			</ResultItem>);
		});
	}

	renderResultsLabel() {

		let label = '';
		let textLabel = '';
		let decodeSearchPhrase = decodeURIComponent(this.state.searchPhrase);

		if (this.state.numberOfResults === 0) {
			label = 'wyników'
		} else if (this.state.numberOfResults === 1) {
			label = 'wynik'
		} else {

			const str = this.state.numberOfResults.toString()[this.state.numberOfResults.toString().length - 1];

			if (this.state.numberOfResults > 10 && this.state.numberOfResults < 20) {
				label = 'wyników';
			} else {
				label = parseInt(str) >= 5 || parseInt(str) <= 1 ? 'wyników' : 'wyniki';
			}
		}

		if (this.state.numberOfResults === 0) {
			textLabel = <Text as="h4">Nie znalazłeś interesujących Cię informacji? Wybierz wygodny dla Ciebie sposób {generateLink('/kontakt-i-placowki', 'kontaktu', '', '', this.state.offline)}, a my odpowiemy na pytania lub przygotujemy ofertę.</Text>
		} else {
			textLabel = <Text as="h4">{this.state.numberOfResults} {label} wyszukiwania "{decodeSearchPhrase}"</Text>
		}

		return textLabel;
	}

	renderPagination() {

		const pageNumber = this.state.pageNumber;
		const numberOfPages = Math.ceil(this.state.numberOfResults / 10);
		let pages = [];
		let arr = [];

		if (numberOfPages) {

			for (let i = 1; i <= numberOfPages; i++) {
				pages.push(i);
			}

		}

		if (pages.length > 4) {

			for (let i = 2; i < pages.length; i++) {

				if (pageNumber <= 3 && i <= 4) {
					arr.push(i);
				} else if (pageNumber >= pages.length - 1 && i >= pages.length - 3) {
					arr.push(i);
				} else if (i >= pageNumber - 1 && i <= pageNumber + 1) {
					arr.push(i);
				} else {

					if (pageNumber === i - 2 || pageNumber === i + 2) {
						arr.push('...')
					} else {

						if (i === 5 && i > pageNumber) {
							arr.push('...')
						}

						if (i === pages.length - 4 && i < pageNumber) {
							arr.push('...')
						}

					}

				}
			}

			pages = [pages[0], ...arr, pages[pages.length - 1]];
		} else {
			arr = [pages[1], pages[2]];
		}

		return (
			<PaginationContainer>
				<PaginationIconContainer
					marginRight={px(124)}
					elementDisplay={this.state.pageNumber !== 1}
					onClick={() => pageNumber > 0 ? this.search(this.state.pageNumber - 1) : undefined}
				>
					<Icon
						iconComponent={ChevronLeft}
						primaryColor={IconColor.BLUE}
						title="ikona"
					/>
				</PaginationIconContainer>
				{pages.map((pageNumber, i) => {
					return (
						<PaginationItem
							key={'item-' + i}
							background={pageNumber === '...' ? 'transparent' : palette.solidWhite}
							onClick={pageNumber === '...' ? () => undefined : () => this.search(pageNumber)}
							active={this.state.pageNumber === pageNumber}
						>
							{pageNumber}
						</PaginationItem>
					);
				})}
				<PaginationIconContainer
					marginLeft={px(124)}
					elementDisplay={this.state.pageNumber !== numberOfPages}
					onClick={() => pageNumber < numberOfPages ? this.search(this.state.pageNumber + 1) : undefined}
				>
					<Icon
						iconComponent={ChevronRight}
						primaryColor={IconColor.BLUE}
						title="ikona"
					/>
				</PaginationIconContainer>
			</PaginationContainer>
		);
	}

	searchTipsClickCallback(e, url) {
		this.updateState('searchTipsResults', []);
		this.redirect(e, url);
	}

	renderSearchTips() {

		const arr = this.state.searchTipsResults.filter((result, i) => i < 20);

		return (
			<SearchTipsContainer border={this.state.searchTipsResults.length ? 1 : 0}>
				{arr.map((tip, i) => {
					return (
						<React.Fragment key={'search-tip-' + i}>
							<SearchTipsItem onClick={e => this.searchTipsClickCallback(e, tip.url)}>
								<Text as="p">{tip.description}</Text>
							</SearchTipsItem>
						</React.Fragment>
					);
				})}
			</SearchTipsContainer>
		);
	}

	render() {
		return (
			<Container>
				{this.state.searchResults ? <FixedHeader>
					<Container>
						<FixedHeaderInner>
							<LogoContainer>
								{generateLink('/', '', '', (
									<Logo width="155" height="50" title="logo mLeasing" />
								))}
							</LogoContainer>
							<SearchTextFieldOuter>
								<SearchTextFieldWrapper focus={this.state.focus}>
									<SearchTextFieldIcon>
										<Icon iconComponent={MagnifyingGlass} primaryColor={IconColor.BLUE} title="ikona" />
									</SearchTextFieldIcon>
									<SearchTextFieldInner>
										<SearchTextField
											value={this.state.searchPhrase}
											placeholder={this.state.placeholder}
											onChange={this.searchTipsCallback}
											onKeyPress={this.searchCallback}
											onFocus={this.focusCallback}
											onBlur={this.blurCallback}
										/>
									</SearchTextFieldInner>
								</SearchTextFieldWrapper>
							</SearchTextFieldOuter>
							<ButtonContainer>
								<Button buttonType="primary" onClick={() => this.search(1)}>szukaj</Button>
							</ButtonContainer>
							<ButtonContainer>
								<Button buttonType="tertiary" color={ButtonColor.BLUE} onClick={this.clear}>anuluj</Button>
							</ButtonContainer>
						</FixedHeaderInner>
					</Container>
				</FixedHeader> : <FlexContainer standalone={this.state.standalone}>
					<LongFlexBox standalone={this.state.standalone}>
						{!this.state.standalone ? <HeaderBox>
							<Text as="h2">{this.state.searchTitle}</Text>
						</HeaderBox> : ''}
						{this.state.standalone && this.state.subtitle ? <SubtitleContainer standalone={this.state.standalone}>
							{this.state.icon ? <Image
								src={this.state.icon}
								alt={this.state.altText}
								title={this.state.title}
								width={Spacing.SPACE_48}
							/> : ''}
							<Subtitle>{componentsFromHtml(this.state.subtitle, this.state.offline)}</Subtitle>
						</SubtitleContainer> : ''}
						{this.state.standalone && this.state.text ? <TextContainer alignLeft={true}>{componentsFromHtml(this.state.text, this.state.offline)}</TextContainer> : ''}
						<FlexContainerInner standalone={this.state.standalone}>
							<InputContainer standalone={this.state.standalone}>
								<SearchTextFieldOuter>
									<SearchTextFieldWrapper focus={this.state.focus}>
										<SearchTextFieldIcon>
											<Icon iconComponent={MagnifyingGlass} primaryColor={IconColor.BLUE} title="ikona" />
										</SearchTextFieldIcon>
										<SearchTextFieldInner>
											<SearchTextField
												value={this.state.searchPhrase}
												placeholder={this.state.placeholder}
												onChange={this.searchTipsCallback}
												onKeyPress={this.redirect}
												onFocus={this.focusCallback}
												onBlur={this.blurCallback}
											/>
										</SearchTextFieldInner>
									</SearchTextFieldWrapper>
								</SearchTextFieldOuter>
								{this.state.searchTips && parseInt(this.state.searchTips) !== 0 ? this.renderSearchTips() : ''}
							</InputContainer>
							<ButtonArea>
								<Button buttonType="primary" onClick={this.redirect}>wyszukaj</Button>
							</ButtonArea>
						</FlexContainerInner>
						{!this.state.standalone ? <Box as="div" maxWidth={px(500)}>
							<TextContainer>{componentsFromHtml(this.state.text, this.state.offline)}</TextContainer>
						</Box> : ''}
					</LongFlexBox>
					{!this.state.standalone ? <ShortFlexBox>
						<Text as="h2">{this.state.title}</Text>
						<Box marginTop={px(Spacing.SPACE_24)}>
							{this.state.items.map((item, i) => {
								return (<Box as="div" key={'item-' + i} marginBottom={px(Spacing.SPACE_12)}>
									<Stack direction="row" isWrapped={false} marginBottom={px(Spacing.SPACE_12)}>
										<Icon iconComponent={Tick} primaryColor={IconColor.BLUE} title="ikona" />
										<Text as="p">{item}</Text>
									</Stack>
								</Box>);
							})}
						</Box>
						<ExtendedFlexContainer>
							{generateLink(this.state.link, '', linkStyles, (
								<Box as="div" key={'item-' + this.state.items.lenght+1} marginBottom={px(Spacing.SPACE_12)}>
									<Stack direction="row" isWrapped={false} marginBottom={px(Spacing.SPACE_12)}>
										<Icon iconComponent={HumanHeadset} primaryColor={IconColor.BLUE} size={IconSize.NORMAL} marginLeft={px(Spacing.SPACE_8)} title="Obsługa klienta" />
										<Button marginRight={px(Spacing.SPACE_8)} buttonType="tertiary" color={ButtonColor.BLUE}>{this.state.linkName}</Button>
									</Stack>
								</Box>
							), this.state.offline)}
						</ExtendedFlexContainer>
					</ShortFlexBox> : ''}
				</FlexContainer>}
				{this.state.standalone && this.state.searchResults ? <>
					{this.state.showTipsResults ? <ResultsContainer>
						{this.renderTipsResults()}
					</ResultsContainer> : ''}
					{this.state.showNumberOfResults ? <ResultsContainer>
						<NumberOfResultsContainer>{this.renderResultsLabel()}</NumberOfResultsContainer>
					</ResultsContainer> : ''}
					{this.state.results.length ? <ResultsContainer>
						{this.renderResults()}
					</ResultsContainer> : ''}
					{this.state.results.length ? <ResultsContainer>
						{this.renderPagination()}
					</ResultsContainer> : ''}
				</> : ''}
			</Container>
		);
	}

}

export default SearchBox;
