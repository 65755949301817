/*!
 * mLeasing, newsletter-box :: 01/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import Container from '../../components/container.js';
import {Box} from '@mbank-design/design-system/components';
import {Stack} from '@mbank-design/design-system/components';
import {Text} from '@mbank-design/design-system/components';
import {TextField} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import {Icon} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Envelope} from '@mbank-design/design-system/icons';
import {Tick} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import {Checkbox} from '@mbank-design/design-system/components';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {mapLinks} from '../../utils/map-links.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const NewsletterTextField = styled(TextField)`
	padding-right: ${px(Spacing.SPACE_44)};
	margin-top: ${px(Spacing.SPACE_12)};
	margin-bottom: ${px(Spacing.SPACE_12)};
`;
const FlexContainer = styled.div `
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
	}
`;
const FlexBox = styled(Box)`
	flex-grow: 1;
	flex-shrink: 0;
	padding: ${px(props => props.padding)};
	background-color: ${palette.solidWhite}
`;
const LongFlexBox = styled(FlexBox)`
	flex-basis: calc(66.66% - ${px(Spacing.SPACE_12)});
	margin-left: ${px(props => props.marginLeft)};
	margin-right: ${px(props => props.marginRight)};
	order: ${props => props.order || 0};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-left: ${px(Spacing.SPACE_0)};
		margin-top: ${px(Spacing.SPACE_16)};
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
		order: 1;
	}
`;
const InnerLongFlexBox = styled(LongFlexBox)`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_0)};
		padding-left: ${px(Spacing.SPACE_0)};
	}
`;
const ShortFlexBox = styled(FlexBox)`
	position: relative;
	flex-basis: calc(33.33% - ${px(Spacing.SPACE_12)});
	order: ${props => props.order || 0};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
		order: 0;
	}
`;
const InnerShortFlexBox = styled(ShortFlexBox)`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
const FlexRow = styled.div`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
`;
const ImageWrapper = styled(Box)`
	width: auto;
	max-width: ${px(240)};
	margin: 0 auto;
`;
const blockLinkStyles = `
	width: auto;
	display: inline-block;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: 100%;
		display: block;
	}
`;
const absoluteLinkStyles = `
	position: absolute;
	bottom: ${px(Spacing.SPACE_24)};
`;
const buildState = (props) => {

	let title = '';
	let bottomText = '';
	let items = [];
	let image = '';
	let icon = '';
	let boxPosition = 'left';
	let longBoxTitle = '';
	let link = '';
	let linkName = '';
	let altText = '';
	let iconAltText = '';

	if (props.offline) {
		title = props.data.title || '';
		icon = props.data.icon;
		iconAltText = props.data.iconAltText || '';
		image = props.data.image;
		altText = props.data.altText || '';
		bottomText = props.data.bottomText;
		items = props.data.items ? props.data.items : [];
		boxPosition = props.data.boxPosition || boxPosition;
		longBoxTitle = props.data.longBoxTitle || '';
		linkName = props.data.linkName || '';
		link = props.data.link ? mapLinks([{
			url: props.data.link,
			navText: props.data.link
			}], props.offline)[0].url : '';
	} else {

		try {

			let obj = props.data['Newsletter-boxData']['Newsletter-box'];
			let bottomTextLinks = [];

			if (Array.isArray(obj.BottomText.links.link)) {

				bottomTextLinks = obj.BottomText.links.link.map(link => {
					return {
						name: link.name,
						target: link.target || link.anchor
					};
				});

			} else if (obj.BottomText.links !== '') {

				bottomTextLinks = [{
					name: obj.BottomText.links.link.name,
					target: obj.BottomText.links.link.target || obj.BottomText.links.link.anchor
				}];

			}

			if (obj.TextItem) {
			
				if (Array.isArray(obj.TextItem)) {
					items = obj.TextItem;
				} else {
					items = [obj.TextItem];
				}
			}

			title = obj.Title || '';
			longBoxTitle = obj.LongBoxTitle || '';
			image = obj.Image ? obj.Image.link.target.replace(environment.cmsExportPath, '') : '';
			altText = obj.AltText || '';
			icon = obj.Icon ? obj.Icon.link.target.replace(environment.cmsExportPath, '') : '';
			iconAltText = obj.IconAltText || '';
			boxPosition = props.data.BoxPosition || boxPosition;
			linkName = obj.LinkName ? obj.LinkName : '';
			link = obj.Link ? mapLinks([{
				url: obj.Link.link.target,
				navText: obj.LinkName
				}], props.offline)[0].url : '';
			// replace link href attribute representations with actual paths
			bottomText = replaceCmsContentLinks(obj.BottomText.content, bottomTextLinks);
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		image: image,
		altText: altText,
		icon: icon,
		iconAltText: iconAltText,
		link: link,
		linkName: linkName,
		longBoxTitle: longBoxTitle,
		bottomText: bottomText,
		boxPosition: boxPosition,
		items: items
	};

};

class NewsletterBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.sendCallback = this.sendCallback.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('newsletter-box');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	sendCallback() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('search performed');
		}
	}

	render() {
		return (
			<Container>
				<FlexContainer>
					<ShortFlexBox
						paddingTop={px(Spacing.SPACE_24)}
						paddingRight={px(Spacing.SPACE_32)}
						paddingBottom={px(Spacing.SPACE_24)}
						paddingLeft={px(Spacing.SPACE_32)}
						order={this.state.boxPosition === 'right' ? 1 : 0}
					>
						<FlexRow>
							{this.state.icon ? <Image
								src={this.state.icon}
								alt={this.state.iconAltText}
								title={this.state.title}
								width={48}
							/> : ''}
							<Text as="h2" marginLeft={Spacing.SPACE_16}>{this.state.title}</Text>
						</FlexRow>
						{this.state.items.length ? <Box marginTop={px(Spacing.SPACE_24)}>
							{this.state.items.map((item, i) => {
								return (<Box as="div" key={'item-' + i} marginBottom={px(Spacing.SPACE_12)}>
									<Stack direction="row" isWrapped={false} marginBottom={px(Spacing.SPACE_12)}>
										<Icon iconComponent={Tick} primaryColor={IconColor.BLUE} title="ikona" />
										<Text as="p">{item}</Text>
									</Stack>
								</Box>);
							})}
						</Box> : ''}
						<Box as="div" marginTop={this.state.icon || this.state.title ? Spacing.SPACE_16 : Spacing.SPACE_0}>{componentsFromHtml(this.state.bottomText, this.state.offline)}</Box>
						{this.state.link && <Box as="div">
							{generateLink(this.state.link, '', absoluteLinkStyles, (
								<Button buttonType="primary">{this.state.linkName}</Button>
							), this.state.offline)}
						</Box>}
					</ShortFlexBox>
					<LongFlexBox
						paddingTop={px(Spacing.SPACE_24)}
						paddingRight={px(Spacing.SPACE_32)}
						paddingBottom={px(Spacing.SPACE_24)}
						paddingLeft={px(Spacing.SPACE_32)}
						marginLeft={this.state.boxPosition === 'right' ? Spacing.SPACE_0 : px(Spacing.SPACE_24)}
						marginRight={this.state.boxPosition === 'right' ? Spacing.SPACE_24 : px(Spacing.SPACE_0)}
						order={this.state.boxPosition === 'right' ? 0 : 1}
					>
						<FlexContainer>
							<InnerLongFlexBox>
								{this.state.longBoxTitle && <Box as="div">
									<Text as="h2">{this.state.longBoxTitle}</Text>
								</Box>}
								<NewsletterTextField
									value=""
									placeholder="adres email"
									height={Spacing.SPACE_24}
									leadingDecoration={<Icon iconComponent={Envelope} primaryColor={IconColor.BLUE} title="ikona" />}
									onChange={this.sendCallback}
								/>
								<Box as="div" marginBottom={px(Spacing.SPACE_16)}>
									<Stack direction="row" isWrapped={false} marginBottom={px(Spacing.SPACE_12)}>
										<Checkbox />
										<Text as="small">Udzielam zgody na wykorzystane przez mLeasing Sp. z o.o. z siedzibą w Warszawie przy ul.Ks.I Skorupki 5 (administratora danych) adresu e-mail w celu przekazania oferty handlowej zgodnie z ustawą z dnia 29 sierpnia 1997r. o ochronie danych osobowych (Dz.U. Nr. 133 poz 883 z późniejszymi zmianami).</Text>
									</Stack>
								</Box>
								{generateLink('/', '', blockLinkStyles, (
									<Button buttonType="primary" style={{width: '100%'}}>zapisz się do newslettera</Button>
								), this.state.offline)}
							</InnerLongFlexBox>
							<InnerShortFlexBox>
								<ImageWrapper>
									<Image
										src={this.state.image}
										alt={this.state.altText}
										title={this.state.title}
										width="100%"
									/>
								</ImageWrapper>
							</InnerShortFlexBox>
						</FlexContainer>
					</LongFlexBox>
				</FlexContainer>
			</Container>
		);
	}

}

export default NewsletterBox;
