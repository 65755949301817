/*!
 * mLeasing, managemens-box-set portlet :: 04/02/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Disclosure} from '@mbank-design/design-system/components'; 
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import Container from '../../components/container.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const BoxWrapper = styled.div`
	display: flex;
	flex-direction: ${props => props.direction ? props.direction : 'row'};
	flex-wrap: nowrap;
	position: relative;
	align-items: ${props => {
	
		if (props.direction === 'column') {
			
			if (props.align === 'left') {
				return 'flex-start';
			}

			if (props.align === 'center') {
				return 'center';
			}

			if (props.align === 'right') {
				return 'flex-end';
			}

		} else {
			return 'stretch';
		}

	}};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		align-items: center;
	}
`;
const CustomBox = styled.div`
	position: relative;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: calc(${props => !(props.count%3) ? 33.33 : 25}% - 24px);
	margin-bottom: ${props => props.count > 3 && props.count !== 4 && props.index < props.count - 3 ? px(Spacing.SPACE_24) : px(Spacing.SPACE_0)};
	padding: ${px(Spacing.SPACE_12)};
	background-color: ${props => props.isProcess ? palette.wildSandGray : palette.solidWhite};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: ${px(Spacing.SPACE_0)};
		margin-bottom: ${px(Spacing.SPACE_0)};
	}
`;
const Inner = styled.div`
	max-width: 868px;
	margin: 0 auto;
	text-align: ${props => props.align ? props.align : 'left'};
	margin-top: ${props => props.marginTop || 0};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${props => props.mobilePadding || 0};
		padding-left: ${props => props.mobilePadding || 0};
		text-align: center;
	}
`;
const InnerMobile = styled(BoxWrapper)`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
`;
const InnerDesktop = styled(BoxWrapper)`
	flex-wrap: ${props => props.count > 3 && !(props.count%3) || props.count > 4 ? 'wrap' : 'nowrap'};
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const TitleFragment = styled.span`
	font-size: ${props => props.isNumber ? px(Spacing.SPACE_40) : px(Spacing.SPACE_20)};
	margin-right: ${px(Spacing.SPACE_8)};
`;
const buildState = (props) => {

	let title = '';
	let items = [];

	if (props.offline) {
		title = props.data.title;
		items = props.data.items ? props.data.items.map(item => ({
			image: item.image,
			altText: item.altText || '',
			text: item.text,
			underText: item.underText,
			disclosure: item.disclosure,
			disclosureText: item.disclosureText,
		})) : []; 
	} else {

		try {

			let obj = props.data['Managemens-boxData']['Managemens-box'];

			title = obj.Title;

			if (Array.isArray(obj.Item)) {
				items = obj.Item.map(item => {
					return {
						image: item.Image.link.target.replace(environment.cmsExportPath, ''),
						altText: item.AltText || '',
						text: item.Text,
						underText: item.underText,
						disclosure: item.disclosure,
						disclosureText: item.disclosureText.content,
					};
				})
			} else {
				items = [{
					image: obj.Item.Image.link.target.replace(environment.cmsExportPath, ''),
					altText: obj.Item.AltText || '',
					text: obj.Item.Text,
					underText: obj.Item.underText,
					disclosure: obj.Item.disclosure,
					disclosureText: obj.Item.disclosureText.content,
				}]
			}

		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		items: items  			
	};

};

class ManagemensBox extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderBoxes = this.renderBoxes.bind(this);
		this.renderBoxHeader = this.renderBoxHeader.bind(this);
		this.highlightNumbers = this.highlightNumbers.bind(this);
        this.handleChange = this.handleChange.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('managemens-box-set');
		}
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	highlightNumbers(str) {

		const arr = str.split(' ');

		return (
			<>
				{arr.map((item, i) => <TitleFragment
					isNumber={parseInt(item)}
					key={'title-fragment-' + i}
				>{item}</TitleFragment>)}
			</>
		);
	}

	renderBoxHeader(item) {
		return (
			<BoxWrapper direction={item.iconTitleRow ? 'row' : 'column'}>
				<Image
					src={item.image}
					title={item.text}
					alt={item.altText}
					maxWidth={px(420)}
					maxHeight={px(360)}
				/>
				<Box paddingTop={px(24)}>
					<Text as="h3" style={{marginBottom: px(Spacing.SPACE_8)}}>{item.text}</Text>
                    <Text as="h4" style={{marginBottom: px(Spacing.SPACE_16)}}>{item.underText}</Text>
				</Box>
			</BoxWrapper>
		);
	}

	renderBoxes(items) {
		return items.map((item, i) => {
			// Tile component should be used, but it is impossible because it uses react-router-dom link
			return (
				<CustomBox key={'managemensbox-' + i} count={items.length} index={i}>
                    <Inner>{this.renderBoxHeader(item)}</Inner>
					<Inner>
                        <Disclosure 
                            isDefaultExpanded={false}
                            onChange={() => this.handleChange(i)}
                        >
                            <Disclosure.Content>
								<Box marginBottom={Spacing.SPACE_16}>{componentsFromHtml(item.disclosureText, this.state.offline)}</Box>
							</Disclosure.Content>
                            <Disclosure.Button>{item.disclosure ? 'pokaż opis' : 'ukryj opis'}</Disclosure.Button>      
                        </Disclosure>
					</Inner>
				</CustomBox>
			);
		});
	}

    handleChange(i) {

		const { items } = this.state;
		
		if (items[i].disclosure) {
		 	items[i].disclosure = false;
		} else {
			items[i].disclosure = true;
		}

		// update state
		this.setState({
			items
		});
    }

	render() {

		const titleColor = this.state.background === 'black' ? 'solidWhite' : 'zeusBlackUI';
		const title = this.state.title ?  <Text as="h2" align="center" marginBottom={Spacing.SPACE_24} color={titleColor}>{this.state.title}</Text> : '';

		return (
			<Container>
				<Inner mobilePadding={px(Spacing.SPACE_16)}>
					{title}
				</Inner>
				<InnerDesktop as="div" className="inner desktop" count={this.state.items.length}>
					{this.renderBoxes(this.state.items)}
				</InnerDesktop>
				<InnerMobile as="div" className="inner mobile" count={this.state.items.length}>
                    {this.renderBoxes(this.state.items)}
				</InnerMobile>
			</Container>
		);
	}

}

export default ManagemensBox;
